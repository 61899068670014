import videojs from "video.js";
import {detectHLS} from "@utilities/DetectHLS";

const Plugin = videojs.getPlugin("plugin");

// Default options for the plugin.
const defaults = {};

/**
 * An advanced Video.js plugin. For more information on the API
 *
 * See: https://blog.videojs.com/feature-spotlight-advanced-plugins/
 */
class InvalidTSDuration extends Plugin
{

	/**
   * Create a InvalidTSDuration plugin instance.
   *
   * @param  {Player} player
   *         A Video.js Player instance.
   *
   * @param  {Object} [options]
   *         An optional options object.
   *
   *         While not a core part of the Video.js plugin architecture, a
   *         second argument of options is a convenient way to accept inputs
   *         from your plugin's caller.
   */
	constructor(player, options)
	{
		// The parent class will add player under this.player
		super(player);
		this.options = videojs.mergeOptions(defaults, options);

		this.hlsjs = null;
		if (videojs.Html5Hlsjs)
		{
			videojs.Html5Hlsjs.addHook("beforeinitialize", (x, hlsjs) =>
			{
				this.hlsjs = hlsjs;
			});
		}

		player.ready(() =>
		{
			if (player.detectErrors)
			{
				player.detectErrors().register(this);
			}
		});
	}

	test()
	{
		return new Promise(resolve =>
		{
			// We only support detecting bad HLS videos
			if (!detectHLS(this.player))
			{
				resolve([]);
				return;
			}

			// For the time being, we only support error detection with HLS.js
			// TODO: Add support for VideoJS HTTP Streaming
			if (!this.hlsjs)
			{
				resolve([]);
				return;
			}

			const errors = [];

			// We want to check out the M3U8 for the most recently played level
			const level = this.hlsjs.abrController.lastLoadedFragLevel || this.hlsjs.currentLevel;

			if (level < 0 || level >= this.hlsjs.levels.length)
			{
				resolve([]);
				return;
			}

			const details = this.hlsjs.levels[level].details;
			const fragments = details.fragments;
			for (var i = 0; i < fragments.length; i++)
			{
				const fragment = fragments[i];
				const start = fragment.maxStartPTS;
				const end = fragment.endPTS;
				const ptsDiff = end - start;
				if (ptsDiff < 1 && i < fragments.length - 1)
				{
					// HLS.js is pretty good at playing these files
					errors.push({
						msg: `Fragment was less than 1 second (duration: ${ptsDiff.toFixed(3)}): ${fragment._url}`,
						recoverable: true,
						corruptRange: null,
						corruptRendition: null
					});
				}
				else if (ptsDiff > details.averagetargetduration * 2)
				{
					// I don't know if this is ACTUALLY recoverable,
					// but until proven otherwise I'm going to try to
					// appease Darren
					errors.push({
						msg: `Fragment was more than ${(details.averagetargetduration * 2).toFixed(3)}s ` +
							 `(duration: ${ptsDiff.toFixed(3)}s): ${fragments[i]._url}`,
						recoverable: true,
						corruptRange: videojs.createTimeRanges(
							fragment.start,
							fragment.start + fragment.duration
						),
						corruptRendition: null
					});
				}
			}
			resolve(errors);
		});
	}
}

// Register the plugin with video.js.
videojs.registerPlugin("errorDetectionInvalidTSDuration", InvalidTSDuration);

export default InvalidTSDuration;
