import videojs from "video.js";

import QualitySelectorMenuItem from "./QualitySelectorMenuItem";

const Component = videojs.getComponent("Component");
const MenuButton = videojs.getComponent("MenuButton");

/**
 * The base class for buttons
 *
 * @param {Player|Object} player
 * @param {Object=} options
 * @extends MenuButton
 * @class QualitySelectorButton
 */
class QualitySelectorButton extends MenuButton
{
	/**
	 * Creates an instance of this class.
	 *
	 * @param {Player} player
	 *		The `Player` that this class should be attached to.
	 *
	 * @param {Object} [options={}]
	 *		The key/value store of player options.
	 */
	constructor(player, options = {})
	{
		super(player, options);
		this.player = player;

		this.player.qualityLevels().on(["addqualitylevel", "removequalitylevel"], this.update.bind(this));
	}

	/**
	 * Builds the default DOM `className`.
	 *
	 * @return {string}
	 *		The DOM `className` for this object.
	 */
	buildCSSClass()
	{
		// Utilizing the VideoJS cog icon
		return `vjs-quality-selector-button ${super.buildCSSClass()} vjs-icon-cog`;
	}


	createItems()
	{

		const addFPS = (qualities) =>
		{
			// unfortunately videojs-contrib-quality-levels does not return fps so if we need to have a workaround if
			// we want to display "720p60" instead of two options of just "720p".
			for (let index = 0; index < qualities.length; index++)
			{
				const previousQuality = qualities[index - 1];
				const currentQuality = qualities[index];
				// As of right now the list is sorted low to high.
				if (previousQuality && previousQuality.height === currentQuality.height)
				{
					currentQuality.fps = 60;
				}
			}
		};

		const menuItems = [];
		// createItems gets called by the MenuButton constructor, before
		// we have a chance to set this.player. Therefore we must access the
		// protected member variable `player_` instead of `player`
		const qualityLevels = this.player_.qualityLevels();
		addFPS(qualityLevels);

		// If there's only one quality level, it does no good to have a button
		if (qualityLevels.length === 1)
		{
			this.hide();
			return [];
		}
		else
		{
			// Just in case we previous hid it
			this.show();
		}

		let qualitySelected = false;

		for (let i = 0; i < qualityLevels.length; i++)
		{
			const index = qualityLevels.length - (i + 1);

			let label = "" + index;

			// Display height if height metadata is provided with the stream, else use bitrate
			if (qualityLevels[index].height)
			{
				label = qualityLevels[index].height + "p";
				label += qualityLevels[index].fps ? qualityLevels[index].fps : "";
			}
			else if (qualityLevels[index].bitrate)
			{
				label = Math.floor(qualityLevels[index].bitrate / 1000) + " Kbps";
			}

			// Due to the defaultQuality logic in Plugin.js, when creating a new
			// menu (when this method is called) we can safely say that only one
			// quality will be enabled
			menuItems.push(new QualitySelectorMenuItem(
				this.player,
				{
					label: label,
					index: index,
					selectable: true,
					selected: qualityLevels[index].enabled,
					// Pass a handle to the menu so that when one option is selected
					// we can automatically de-select others
					// TODO: See if VideoJS has a more elegant way to do this
					menuButton: this
				})
			);

			qualitySelected = qualitySelected || qualityLevels[index].enabled;
		}

		// If there are more than one quality levels, offer an 'auto' option
		if (qualityLevels.length > 1)
		{
			menuItems.push(new QualitySelectorMenuItem(
				this.player,
				{
					label: "Auto",
					index: -1,
					selectable: true,
					selected: !qualitySelected,
					menuButton: this
				})
			);
		}
		return menuItems;
	}
}

/**
 * The text that should display over the `QualitySelectorButton`s controls. Added for localization.
 *
 * @type {string}
 * @private
 */
QualitySelectorButton.prototype.controlText_ = "Quality";

Component.registerComponent("qualitySelectorButton", QualitySelectorButton);

export default QualitySelectorButton;
