import videojs from "video.js";

const Component = videojs.getComponent("Component");

// Default options for the plugin.
const defaults = {
	labelText: "Label"
};

const KEYCODE_ENTER = 13;

/**
 * @param {Player|Object} player
 * @param {Object=} options
 * @extends Component
 * @class ClipTimeInput
 */
class ClipTimeInput extends Component
{
	constructor(player, options = {})
	{
		super(player, options);
		this.player = player;
		this.options = videojs.mergeOptions(defaults, options);

		this.rangeStart = 0;
		this.rangeEnd = this.player.duration();

		this.el_.className = this.buildCSSClass();

		this.label = videojs.dom.createEl("label", {}, {}, this.options.labelText);
		this.el_.appendChild(this.label);
		this.input = videojs.dom.createEl("input");
		this.el_.appendChild(this.input);

		this.input.addEventListener("blur", this.handleChange.bind(this));
		this.input.addEventListener("keyup", this.checkKey.bind(this));

		this.time = 0;
	}

	/**
	 * Builds the default DOM `className`.
	 *
	 * @return {string}
	 *		The DOM `className` for this object.
	 */
	buildCSSClass()
	{
		return `vjs-clip-time-input ${super.buildCSSClass()}`;
	}

	handleChange()
	{
		// If there's a colon, assume it's H:M:S format
		let time = this.input.value;
		if (this.input.value.indexOf(":") >= 0)
		{
			time = ClipTimeInput.hmsToTime(time);
		}

		// Clip within the valid range
		time = Math.max(Math.min(time, this.rangeEnd), this.rangeStart);

		// Update the display
		this.input.value = ClipTimeInput.timeToHMS(time);

		// Update the state
		this.time = time;

		// Emit the new time
		this.trigger("change", time);
	}

	checkKey(evt)
	{
		if (evt.keyCode === KEYCODE_ENTER)
		{
			this.handleChange();

			// Seek
			this.player.currentTime(this.time);
		}
	}

	getTime()
	{
		return this.time;
	}

	setTime(newTime)
	{
		// Update our state
		this.time = newTime;

		// Update the input
		this.input.value = ClipTimeInput.timeToHMS(newTime);
	}

	static timeToHMS(time)
	{
		let seconds = time;
		const hours = Math.floor(seconds / 60 / 60);
		seconds -= hours * 60 * 60;
		const minutes = Math.floor(seconds / 60);
		seconds -= minutes * 60;
		const milliseconds = Math.round((seconds - Math.floor(seconds)) * 1000);
		seconds = Math.floor(seconds);

		const lastTwoDigits = -2;
		let timeStr = ("00" + hours).substr(lastTwoDigits) + ":" +
			("00" + minutes).substr(lastTwoDigits) + ":" +
			("00" + seconds).substr(lastTwoDigits);

		if (milliseconds > 0)
		{
			const lastThreeDigits = -2;
			timeStr += "." + ("000" + milliseconds).substr(lastThreeDigits);
		}
		return timeStr;
	}

	static hmsToTime(time)
	{
		const timeParts = time.split(":");
		// +timeParts[0] === Number(timeParts[0])
		// It's just shorthand
		return (+timeParts[0] * 60 * 60) + (+timeParts[1] * 60) + +timeParts[2];
	}

	setRange(start, end)
	{
		this.rangeStart = start;
		this.rangeEnd = end;
	}
}

ClipTimeInput.prototype.controlText_ = "Clip Time Input";

Component.registerComponent("clipTimeInput", ClipTimeInput);

export default ClipTimeInput;
