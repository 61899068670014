import videojs from "video.js";

const Component = videojs.getComponent("Component");

// Default options for the plugin.
const defaults = {};

/**
 * @param {Player|Object} player
 * @param {Object=} options
 * @extends Component
 * @class ClipScrubber
 */
class ClipScrubber extends Component
{
	constructor(player, options = {})
	{
		super(player, options);
		this.player = player;
		this.options = videojs.mergeOptions(defaults, options);

		this.addClass(this.options.className);

		this.doc = this.el_.ownerDocument;
		this.boundHandleMouseMove = this.handleMouseMove.bind(this);

		this.time = 0;
	}

	scrubStart(evt)
	{
		this.on(this.doc, "mousemove", this.boundHandleMouseMove);

		// Also update the current position
		if (evt)
		{
			this.handleMouseMove(evt);
		}
	}

	scrubEnd()
	{
		this.off(this.doc, "mousemove", this.boundHandleMouseMove);
	}

	handleMouseMove(evt)
	{
		const bounds = this.parentComponent_.el_.getBoundingClientRect();
		const offset = Math.min(Math.max(0, evt.pageX - bounds.left), bounds.width);

		// Update the visual position of the scrubber
		this.el_.style.width = offset + "px";

		// Emit the new time (after calculating it)
		this.time = this.percToTime(offset / bounds.width);
		this.trigger("scrub", this.time);
	}

	getTime()
	{
		return this.time;
	}

	setTime(newTime)
	{
		// Update our state
		this.time = newTime;

		// And update our position
		this.el_.style.width = this.timeToPerc(newTime) + "%";
	}

	setRange(start, end)
	{
		this.barStart = start;
		this.barEnd = end;
	}

	percToTime(perc)
	{
		return ((this.barEnd - this.barStart) * perc) + this.barStart;
	}

	timeToPerc(time)
	{
		return 100 * (time - this.barStart) / (this.barEnd - this.barStart);
	}
}

ClipScrubber.prototype.controlText_ = "Clip Scrubber";

Component.registerComponent("clipScrubber", ClipScrubber);

export default ClipScrubber;
