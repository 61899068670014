import videojs from "video.js";
import "./Plugin.scss";
import AutoplayMuteOverlay from "./components/AutoplayMuteOverlay";

const logger = videojs.createLogger("BehaviorChangeAutoplayFix");

// Default options for the plugin.
const defaults = {
	enabled: true,
	muted: true,
	inline: false
};

// UPDATE: As of 3/19/2019 the latest version of Firefox (version 66) now blocks
// autoplay of unmuted content. With that we're now forced to mute Android, iOS,
// Safari, Firefox, and (sometimes) Chrome. That's basically every browser
// except Internet Explorer - and Edge is now powered by Chrome. At this point I
// think it's safe to say that for a consistent user experience we should ALWAYS
// mute the content
if (videojs.browser.IS_IOS)
{
	defaults.inline = true;
}

// If the HTML5 <video> element is set to autoplay and has a valid source, it
// may start playing before VideoJS is even initialized (and before this plugin
// has had a chance to intervene). This can cause issues with playing prerolls,
// can cause the play button to get stuck on the screen, and can lead to weird
// UI bugs. To fix this, we'll remove the autoplay tag from any HTML5 <video>
// elements, and remember them so we can add them back in later.
const autoplayVideos = {};
document.addEventListener("domcontentloaded", () =>
{
	const videoElements = document.getElementsByTagName("video");
	for (let i = 0; i < videoElements.length; i++)
	{
		const videoElement = videoElements[i];
		if (videoElement.autoplay && videoElement.id)
		{
			autoplayVideos[videoElement.id] = true;
			videoElement.autoplay = false;
			// In Internet Explorer attempting to set currentTime before an
			// element starts playing crashes the main JavaScript thread
			try { videoElement.currentTime = 0; }
			catch (e) { /* do nothing */ }
			videoElement.pause();
		}
	}
});

/**
 * A basic Video.js plugin
 */
function AutoplayFix(options)
{
	const player = this;
	const opts = videojs.mergeOptions(defaults, options);

	if (!opts.enabled) return;

	let ready;
	let loadedmetadata;
	let haveReceivedUserInteraction;

	const autoplay = player.autoplay() || Object.prototype.hasOwnProperty.call(autoplayVideos, player.id());
	const muted = player.muted() || false;
	const inline = player.playsinline() || false;

	player.autoplay = function()
	{
		return autoplay;
	};

	if (!opts.muted && autoplay)
		logger.warn("Attempting to autoplay a video without mute will PROBABLY fail");

	const autoplayMuteOverlay = new AutoplayMuteOverlay(player, {});

	logger.debug(
		`autoplay: ${autoplay}, muted: ${muted}, playsinline: ${inline}`
	);

	if (autoplay)
	{
		// They want the player to autoplay... Let's try to help them

		// The "autoplay" attribute can trip up some browser extensions and
		// is treated as a "red flag". Technically this should be removed
		// above, but in case this player doesn't have an ID, we can go
		// ahead and try to remove it now.
		logger.debug("Setting autoplay to `false`");
		player.autoplay(false);

		if (opts.muted)
		{
			logger.debug("Setting muted to `true`");
			player.muted(true);

			// Player to muted before the cache is loaded so volume is cached at 100%
			// waiting to make this change. didnt consider mobile devices that will not be able to change the volume.
			// TODO: I want to load the cached volume if there is one but its not that easy bec of the statement above.
			// player.volume(0.1);
		}

		// iOS can only autoplay videos that play inline (non-fullscreen)
		if (opts.inline)
		{
			logger.debug("Setting playsinline to `true`");
			player.playsinline(true);
		}

		// On a small handful of older devices, setting autoplay to "false"
		// after it was "true" can prevent the `play()` method from being
		// accepted (it thinks it's playing, even though it isn't). To fix
		// this, ensure the player *knows* it's paused.
		logger.debug("Sending `pause` command to player");
		player.pause();

		// VideoJS will intercept (and ignore) calls to `player.play()`
		// before the player is "ready"
		ready = false;
		logger.debug("Binding `ready` event handler");
		player.ready(handleReady);

		// You can't play a video that isn't loaded!
		loadedmetadata = false;
		logger.debug("Binding `loadedmetadata` event handler");
		player.on("loadedmetadata", handleLoadedmetadata);

		// Once the user interacts with the page, we can reset these values
		player.on("keydown", handleUserInteraction);
		player.on("touchend", handleUserInteraction);
		player.on("click", handleUserInteraction);

		haveReceivedUserInteraction = false;
	}

	// For SOME reason (I haven't read through the HLS.js source yet to
	// find out why), HLS.js isn't obeying the `autoStartLoad` option in
	// Chrome. This means no loadedmetadata event, which means no video.
	// Easily fixed, though:
	if (videojs.Html5Hlsjs)
	{
		videojs.Html5Hlsjs.addHook("beforeinitialize", (x, hlsjs) =>
		{
			player.ready(() =>
			{
				logger.debug("Forcing HLS.js startLoad");
				hlsjs.startLoad();
			});
		});
	}

	/**
	 * This gets called when the player finishes loading
	 *
	 * @listens ready
	 */
	function handleReady()
	{
		logger.debug("Player `ready` event received. Attempting play");
		ready = true;

		if (opts.muted && !muted)
		{
			// The video should not be muted, but we had to mute it for autoplay
			// to function. Let's give a large and obvious UI element to hint
			// that clicking the player will unmute the video
			logger.debug("Binding method to display AutoplayMuteOverlay");
			player.one("playing", displayMuteOverlay);
		}

		tryPlay();
	}

	function displayMuteOverlay()
	{
		if (haveReceivedUserInteraction)
		{
			logger.debug("NOT displaying AutoplayMuteOverlay (previous user interaction)");
		}
		else
		{
			logger.debug("Displaying AutoplayMuteOverlay");

			player.off("playing", displayMuteOverlay);

			player.addChild(
				autoplayMuteOverlay,
				opts,
				player.children().indexOf(
					player.getChild("BigPlayButton")
				)
			);
		}
	}

	/**
	 * This gets called when the media is ready to play
	 *
	 * @listens loadedmetadata
	 */
	function handleLoadedmetadata()
	{
		logger.debug("Player `loadedmetadata` event received. Attempting play");
		loadedmetadata = true;
		logger.debug("Unbinding `loadedmetadata` event handler");
		player.off("loadedmetadata", handleLoadedmetadata);
		tryPlay();
	}

	/**
	 * This gets called when the user interacts with the page for the first time
	 *
	 * @listens keydown
	 * @listens touchend
	 * @listens click
	 */
	function handleUserInteraction()
	{
		logger.debug("Handling user interaction");

		haveReceivedUserInteraction = true;

		player.off("keydown", handleUserInteraction);
		player.off("touchend", handleUserInteraction);
		player.off("click", handleUserInteraction);

		if (opts.muted)
		{
			player.muted(muted);
			autoplayMuteOverlay.hide();
		}
		if (opts.inline)
		{
			player.playsinline(inline);
		}

		if (opts.inline && !inline)
		{
			// The video was not marked for inline playback, but we had to set it inline for autoplay to work
			// Now that the user has interacted with the page, we can go fullscreen
			player.requestFullscreen();
		}
	}

	/**
	 * This method will check if we can play the video and if so, will try to play it
	 */
	function tryPlay()
	{
		logger.debug(
			`Attempting play. Ready: ${ready}, Loaded Metadata: ${loadedmetadata}`
		);
		if (loadedmetadata && ready)
		{
			// VideoJS fires the "ready" event BEFORE releasing its lock on the
			// "play()" method. We need to wait until the next "tick" for this
			// to work.
			logger.debug("Binding `play()` method to 1 millisecond timeout");
			setTimeout(() =>
			{
				player.play();
			}, 1);
		}
	}
}

// Register the plugin with video.js.
videojs.registerPlugin("behaviorChangeAutoplayFix", AutoplayFix);

export default AutoplayFix;
