/*
* Took several iteration but I have found a fairly accurate/reliable solution to tracking timeViewed
*
* What this tracker does:
* 	Every second we compare the .currentTime() of the player to where it was 1 sec ago and increment timeViewed based on that change.
*	Assuming the normal senario  where the just plays for 1 second then the timer goes up by 1 second, obviously but
*	this allows me to handle "abnormal" senarios such as seeking/skipping or playback stopping for any reason.
*	If playback stops we dont have to track why or when, just that .currentTime() didnt progress and thats all we need to know.
*	If the user skips/seeks then it will at most change timeviewed by 1 second and not potentially hundreds of seconds.
*
*
* Tried a few different solutions that didnt pan out.
* - increment timeviewed based on real time.
*		- theres no reliable way of knowing when the player stops and starts. you'd think "playing" and "paused"
*		triggers would work but unfortunaly they do not in all situations/platforms. apple devices will sometimes trigger "playing"
*		when a broadcast is stopped and also dont trigger "playing" after seeking so its hard to determind whether the broadcast
		playing without explicitly checking .paused(), and even thats not reliable
*		- player.on(["pause", "waiting", "ended"], () => { this.timeViewedTracker.pause(); });
* 		  player.on("playing", () => { this.timeViewedTracker.start(); });
* - Use player.currentTime() but ignore skips
*		- This didnt work because I didnt have a way be saving what the currentTime was just before
*		any skips. If the user clicked on the progress bar then current time would instantly update
*		before any events were triggered so I couldnt update the time viewed based on the pre skipped time.
*/



class TimeViewedTracker
{
	constructor(player)
	{
		this.player = player;
		this.timeViewed = 0;
		this.startTime = 0;
	}

	start()
	{
		if (this.ticker) return this;

		this.mark();
		this.ticker = setInterval(() =>
		{
			this.update();
		}, 1000);

		return this;
	}

	reset()
	{
		this.timeViewed = 0;
		clearInterval(this.ticker);
		this.ticker = null;
		return this;
	}

	getTimeViewed()
	{
		this.update();
		return this.timeViewed;
	}

	update()
	{
		if (!this.ticker) return;
		let timePassed = this.player.currentTime() - this.startTime;

		// normalize timepassed so we always get a number between 0 and 1.
		timePassed = timePassed / this.player.playbackRate();

		// If the user seeks then we can get values outside of 0-1.
		// We dont want to increment by by 100+ secs if they skip forward so we just set any unusual times to 1.
		// Note: For ios, timepassed will calculate from the ad time during ad breaks.
		// It seems to not work any different but i just wanted to note it.
		if (timePassed > 1 || timePassed < 0) timePassed = 1;

		this.timeViewed += timePassed;
		this.mark();
	}

	mark()
	{
		this.startTime = this.player.currentTime();
	}

}

export default TimeViewedTracker;
