/* global __PLAYER_VERSION__ */
import videojs from "video.js";
// import {version as playerVersion} from "@package.json";

const Component = videojs.getComponent("Component");

/**
 * The base class for buttons
 *
 * @param {Player|Object} player
 * @param {Object=} options
 * @extends Button
 * @class InfoButtonPluginMenu
 */
class InfoMenuHeader extends Component
{
	/**
	 * Creates an instance of this class.
	 *
	 * @param {Player} player
	 *		The `Player` that this class should be attached to.
	 *
	 * @param {Object} [options={}]
	 *		The key/value store of player options.
	 */
	constructor(player, options = {})
	{
		super(player, options);

		// VideoJS mistakenly checks the COMPONENT's className instead of the
		// ELEMENT's className to see if a menu item is a "title". If it's a
		// "title" then it's not keyboard-selectable.
		this.className = "vjs-menu-title";

		this.el_ = this.createEl("li", {
			className: this.buildCSSClass() + " vjs-menu-title",
			title: this.localize(this.controlText_),
			textContent: "BluePlayer " + __PLAYER_VERSION__
		});
	}

	/**
	 * Builds the default DOM `className`.
	 *
	 * @return {string}
	 *		The DOM `className` for this object.
	 */
	buildCSSClass()
	{
		// ! For some stupid reason, VideoJS doesn't seem to call this method?
		return `vjs-info-menu-header ${super.buildCSSClass()}`;
	}
}

/**
 * The text that should display over the `InfoMenuHeader`s controls. Added for localization.
 *
 * @type {string}
 * @private
 */
InfoMenuHeader.prototype.controlText_ = "BluePlayer";

Component.registerComponent("infoMenuHeader", InfoMenuHeader);

export default InfoMenuHeader;
