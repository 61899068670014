import videojs from "video.js";
import {detectHLS} from "@utilities/DetectHLS";

const Plugin = videojs.getPlugin("plugin");

// Default options for the plugin.
const defaults = {};

const extensionRE = /^EXT-X-/;

/**
 * An advanced Video.js plugin. For more information on the API
 *
 * See: https://blog.videojs.com/feature-spotlight-advanced-plugins/
 */
class InvalidM3U8Tag extends Plugin
{

	/**
   * Create a InvalidM3U8Tag plugin instance.
   *
   * @param  {Player} player
   *         A Video.js Player instance.
   *
   * @param  {Object} [options]
   *         An optional options object.
   *
   *         While not a core part of the Video.js plugin architecture, a
   *         second argument of options is a convenient way to accept inputs
   *         from your plugin's caller.
   */
	constructor(player, options)
	{
		// The parent class will add player under this.player
		super(player);
		this.options = videojs.mergeOptions(defaults, options);

		this.hlsjs = null;
		if (videojs.Html5Hlsjs)
		{
			videojs.Html5Hlsjs.addHook("beforeinitialize", (x, hlsjs) =>
			{
				this.hlsjs = hlsjs;
			});
		}

		player.ready(() =>
		{
			if (player.detectErrors)
			{
				player.detectErrors().register(this);
			}
		});
	}

	test()
	{
		return new Promise(resolve =>
		{
			// We only support detecting bad HLS videos
			if (!detectHLS(this.player))
			{
				resolve([]);
				return;
			}

			// For the time being, we only support error detection with HLS.js
			// TODO: Add support for VideoJS HTTP Streaming
			if (!this.hlsjs)
			{
				resolve([]);
				return;
			}

			const errors = [];

			// We want to check out the M3U8 for the most recently played level
			const level = this.hlsjs.abrController.lastLoadedFragLevel || this.hlsjs.currentLevel;

			if (level < 0 || level >= this.hlsjs.levels.length)
			{
				resolve([]);
				return;
			}

			const details = this.hlsjs.levels[level].details;
			const fragments = details.fragments;
			for (var i = 0; i < fragments.length; i++)
			{
				const fragment = fragments[i];
				const tagList = fragment.tagList;
				for (var j = 0; j < tagList.length; j++)
				{
					const tag = tagList[j][0];
					let value = tagList[j][1];
					if (extensionRE.test(tag))
					{
						// This is an M3U8 extension. We can't make any guess
						// about the validity of its values
						continue;
					}

					// Because we're scanning the whole M3U8 and not just
					// loaded fragments, we might find an issue with a fragment
					// that we never played. In this case, HLS.js will not have
					// calculated the _url, and we'll need to calculate it
					// ourselves:
					const url = fragment.baseurl.substr(0, fragment.baseurl.lastIndexOf("/") + 1) + fragment.relurl;

					switch (tag)
					{
					case "INF":
						value = parseFloat(value);
						if (value < 1 && i < fragments.length - 1)
						{
							// HLS.js is pretty good at playing these files
							errors.push({
								msg: `#EXTINF less than 1 second (#EXTINF: ${value.toFixed(3)}): ${url}`,
								recoverable: true,
								corruptRange: null,
								corruptRendition: null
							});
						}
						else if (value > details.averagetargetduration * 2)
						{
							// I don't know if this is ACTUALLY recoverable,
							// but until proven otherwise I'm going to try to
							// appease Darren
							errors.push({
								msg: `#EXTINF was more than ${(details.averagetargetduration * 2).toFixed(3)}s ` +
									 `(#EXTINF: ${value.toFixed(3)}s): ${url}`,
								recoverable: true,
								corruptRange: videojs.createTimeRanges(
									fragment.start,
									fragment.start + fragment.duration
								),
								corruptRendition: null
							});
						}
						break;
					default:
						// I think HLS.js ignores there
						errors.push({
							msg: `Invalid M3U tag (${tag}): ${url}`,
							recoverable: true,
							corruptRange: null,
							corruptRendition: null
						});
					}
				}
			}
			resolve(errors);
		});
	}
}

// Register the plugin with video.js.
videojs.registerPlugin("errorDetectionInvalidM3U8Tag", InvalidM3U8Tag);

export default InvalidM3U8Tag;
