import videojs from "video.js";
import InfoMenu from "./InfoMenu";

const Component = videojs.getComponent("Component");
const MenuButton = videojs.getComponent("MenuButton");

// Default options for the plugin.
const defaults = {
	name: "InfoButton"
};

/**
 * The base class for buttons
 *
 * @param {Player|Object} player
 * @param {Object=} options
 * @extends Button
 * @class InfoButton
 */
class InfoButton extends MenuButton
{
	/**
	 * Creates an instance of this class.
	 *
	 * @param {Player} player
	 *		The `Player` that this class should be attached to.
	 *
	 * @param {Object} [options={}]
	 *		The key/value store of player options.
	 */
	constructor(player, options = {})
	{
		const mergedOptions = videojs.mergeOptions(defaults, options);
		super(player, mergedOptions);
		this.options = mergedOptions;
		this.player = player;
	}

	/**
	 * Builds the default DOM `className`.
	 *
	 * @return {string}
	 *		The DOM `className` for this object.
	 */
	buildCSSClass()
	{
		return `vjs-info-button ${super.buildCSSClass()}`;
	}

	createMenu()
	{
		if (!this.menuOptions)
			this.menuOptions = videojs.mergeOptions(this.options, {name: "InfoMenu", menuButton: this});

		this.menuComponent = new InfoMenu(this.player, this.menuOptions);
		return this.menuComponent;
	}

	handleClick()
	{
		super.handleClick();

		// If, for any reason, the Menu component has been removed from this
		// button's children (see: RightClickInfoPlugin), then the button won't
		// noticably do anything. Let's fix that.
		if (!this.getChild("InfoMenu"))
		{
			this.addChild(this.menuComponent);

			// Normally clicking this button TOGGLES the display
			// But because the menu didn't previously exist, we know we want to
			// SHOW the display.
			this.pressButton();
		}
	}
}

/**
 * The text that should display over the `InfoButton`s controls. Added for localization.
 *
 * @type {string}
 * @private
 */
InfoButton.prototype.controlText_ = "Open Info";

Component.registerComponent("infoButton", InfoButton);

export default InfoButton;
