import videojs from "video.js";
import NewWindowMenuItem from "./components/NewWindowMenuItem";

const Plugin = videojs.getPlugin("plugin");

// Default options for the plugin.
const defaults = {};

/**
 * An advanced Video.js plugin. For more information on the API
 *
 * See: https://blog.videojs.com/feature-spotlight-advanced-plugins/
 */
class NewWindow extends Plugin
{

	/**
	 * Create a NewWindow plugin instance.
	 *
	 * TODO: If we plan to make this more generic in the future, we'll need a way
	 * TODO: to pass the "windowed player" URL into the options
	 *
	 * @param  {Player} player
	 *         A Video.js Player instance.
	 *
	 * @param  {Object} [options]
	 *         An optional options object.
	 *
	 *         While not a core part of the Video.js plugin architecture, a
	 *         second argument of options is a convenient way to accept inputs
	 *         from your plugin's caller.
	 */
	constructor(player, options)
	{
		// The parent class will add player under this.player
		super(player);

		this.options = videojs.mergeOptions(defaults, options);

		this.menuItem = new NewWindowMenuItem(player, options);

		player.on("loadstart", this.handleSourceChanged.bind(this));

		player.ready(() =>
		{
			const menu = player.controlBar.getChild("InfoButton").getChild("InfoMenu");
			menu.addItem(this.menuItem);
		});
	}

	/**
	 * When the video source changes, we may require a new "windowed player" URL
	 *
	 * TODO: Only display the MenuItem if the URL if the source is a
	 * TODO: video/x.volar-vmap type
	 */
	handleSourceChanged()
	{
		// const srcUrl = this.player.src();
		this.menuItem.show();
	}
}

// Register the plugin with video.js.
videojs.registerPlugin("infoMenuOptionNewWindow", NewWindow);

export default NewWindow;
