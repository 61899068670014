import videojs from "video.js";

const Plugin = videojs.getPlugin("plugin");

// Default options for the plugin.
const defaults = {};

/**
 * An advanced Video.js plugin. For more information on the API
 *
 * See: https://blog.videojs.com/feature-spotlight-advanced-plugins/
 */
class RightClick extends Plugin
{

	/**
	 * Create a RightClick plugin instance.
	 *
	 * @param  {Player} player
	 *         A Video.js Player instance.
	 *
	 * @param  {Object} [options]
	 *         An optional options object.
	 *
	 *         While not a core part of the Video.js plugin architecture, a
	 *         second argument of options is a convenient way to accept inputs
	 *         from your plugin's caller.
	 */
	constructor(player, options)
	{
		// The parent class will add player under this.player
		super(player);

		this.options = videojs.mergeOptions(defaults, options);

		// null = menu is bound to controlBar
		// non-null = reference to menu, only when hovering
		this.menu = null;

		this.boundHide = this.hideContextMenu.bind(this);

		// TODO: Make sure the InfoButtonPlugin is enabled before proceeding
		// TODO: Otherwise we risk breaking the player on right-click
		player.ready(() =>
		{
			player.on("contextmenu", this.showContextMenu.bind(this));
		});
	}

	showContextMenu(e)
	{
		if (this.menu === null)
		{
			const infoButton = this.player.controlBar.getChild("InfoButton");
			this.menu = infoButton.getChild("InfoMenu");

			// Move the InfoMenu from the InfoButton to the player so it can
			// hover anywhere on the screen
			// ! VideoJS bug: player.addChild() is not calling button.removeChild() automatically like it should
			infoButton.removeChild(this.menu);
			this.player.addChild(this.menu);
			infoButton.unpressButton();

			// Disable the mouseup handler so we can click off without pausing
			this.player.tech_.off("mouseup");

			// Bind listener to revert the menu to its old state
			this.player.on("click", this.boundHide);
			this.player.contentEl().addEventListener("blur", (event) =>
			{
				// When a menu item gets auto-focused (for accessibiliity) we
				// don't want to close the menu
				if (!event.currentTarget.contains(event.relatedTarget))
				{
					// ! Windows Chrome bug: If we don't wait a significant amount of
					// ! time before hiding, the "click" event is never thrown
					setTimeout(this.boundHide, 100);
				}
			}, true);
			this.menu.on("click", this.boundHide);
		}

		this.menu.show();

		// Calculate the appropriate absolute positioning for the menu
		const playerRect = this.player.el().getBoundingClientRect();
		const menuRect = this.menu.contentEl_.getBoundingClientRect();

		// Place the top-left corner of the menu at the mouse location
		// Unless that would push the menu outside of the player
		// Then offset it so the menu is placed on the edge of the player
		this.menu.el_.style.top = e.y - Math.max(e.y + menuRect.height - playerRect.height, 0) + "px";
		this.menu.el_.style.left = e.x - Math.max(e.x + menuRect.width - playerRect.width, 0) + "px";
		this.menu.el_.style.bottom = null;
		this.menu.el_.style.right = null;

		this.menu.focus();

		// Prevent the actual context menu from opening
		e.preventDefault();
		e.stopPropagation();
		return false;
	}

	hideContextMenu()
	{
		// Sometimes this method gets called twice (somehow). Let's fix that
		if (this.menu)
		{
			// Remove listeners
			this.player.off("click", this.boundHide);
			this.player.contentEl().removeEventListener("blur", this.boundHide);
			this.menu.off("click", this.boundHide);

			// Put the mouseup handler back
			this.player.on(this.player.tech_, "mouseup", this.player.__proto__.handleTechClick_);

			// Reset the styling
			const infoButton = this.player.controlBar.getChild("InfoButton");
			this.menu.el_.style.top = null;
			this.menu.el_.style.bottom = 0;
			this.menu.el_.style.left = null;
			this.menu.el_.style.right = 0;
			this.menu.hide();
			infoButton.addChild(this.menu);

			this.menu = null;
		}
	}
}

// Register the plugin with video.js.
videojs.registerPlugin("rightClickInfoMenu", RightClick);

export default RightClick;
