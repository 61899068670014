import videojs from "video.js";

import "./Plugin.scss";

const logger = videojs.createLogger("BehaviorChangeIOSTapFullscreen");

/**
 * A basic Video.js plugin.
 */
function IOSTapFullscreen()
{
	const player = this;

	// This is only an issue on iOS
	player.ready(() =>
	{
		if (videojs.browser.IS_IPHONE)
		{
			logger.debug(
				"iPhone detected, binding `touchend` handler"
			);
			player.addClass("is-iphone");
			player.on("touchend", handleTouch);
		}
	});

	function handleTouch()
	{
		// Controls are turned off. dont do anything.
		// If ad exist then IMA SDK will handle touch
		if (!player.controls() || player.ads.ad) return;

		// This is only an issue when playsinline() is false
		if (!player.playsinline() && !player.isFullscreen())
		{
			player.play();
		}
	}
}

// Register the plugin with video.js.
videojs.registerPlugin("behaviorChangeIOSTapFullscreen", IOSTapFullscreen);

export default IOSTapFullscreen;
