import {getMimetype} from "./MimeTypes";

const hlsType = "application/vnd.apple.mpegurl";
const vmapRe = /api\/broadcast\/vmap\/[0-9]+/;

export function detectHLS(player)
{
	if (getMimetype(player.src()) !== hlsType)
		return true;

	if (vmapRe.test(player.src()))
		if (player.tech_.sourceHandler_.sourceList[player.tech_.sourceHandler_.currentSource].type === hlsType)
			return true;

	return false;
}
