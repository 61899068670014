import videojs from "video.js";
import "./Plugin.scss";

// The real meat of this plugin lies in here
import "./VolarVMAPSourceHandler";

// This handles the parsing of the <Content> tag in our VMAP, and also serves
// as a template for making new VMAP tag parsing plugins in the future
import "./ContentTagParser";

const Plugin = videojs.getPlugin("plugin");

// Default options for the plugin.
const defaults = {
	contentOverride: null
};

/**
 * A dummy plugin which just adds a class to the player so that we know the
 * VolarVMAPSourceHandler was registered successfully, and initializes the
 * ContentTagParser plugin so that we do not need to explicitly declare it in
 * our index.js
 */
class VolarVMAP extends Plugin
{
	/**
	 * Create a VolarVMAP plugin instance.
	 *
	 * @param  {Player} player
	 *         A Video.js Player instance.
	 *
	 * @param  {Object} [options]
	 *         An optional options object.
	 *
	 *         While not a core part of the Video.js plugin architecture, a
	 *         second argument of options is a convenient way to accept inputs
	 *         from your plugin's caller.
	 */
	constructor(player, options)
	{
		// The parent class will add player under this.player
		super(player);

		this.options = videojs.mergeOptions(defaults, options);

		if (!VolarVMAP.detectMediaSource() && !VolarVMAP.detectNativeHLS())
		{
			// This browser cannot play HLS videos
			// We therefore cannot play BlueFrame content
			player.ready(() =>
			{
				player.error("Your browser does not support HLS. Please update to a modern browser.");
			});
		}

		// Initialize the ContentTagParser plugin
		player.vmapTagParserContent();
	}

	static detectMediaSource()
	{
		return (
			window.MediaSource &&
			typeof window.MediaSource.isTypeSupported === "function" &&
			window.MediaSource.isTypeSupported("video/mp4; codecs=\"avc1.42E01E,mp4a.40.2\"")
		);
	}

	static detectNativeHLS()
	{
		return document.createElement("video").canPlayType("application/x-mpegURL");
	}
}

videojs.registerPlugin("volarVMAP", VolarVMAP);

export default VolarVMAP;
