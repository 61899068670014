import videojs from "video.js";

const logger = videojs.createLogger("BehaviorChangeSkipBadNetworkID");

/**
 * A basic Video.js plugin.
 */
function SkipBadNetworkID()
{
	const player = this;

	player.on("error", () =>
	{
		// Check if this is the network ID
		if (
			player.tech_ &&
			player.tech_.sourceHandler_ &&
			player.tech_.sourceHandler_.sourceList &&
			player.tech_.sourceHandler_.currentSource < player.tech_.sourceHandler_.sourceList.length - 1
		)
		{
			player.trigger("ended");
			logger.warn("Skipping broken Network ID");
			player.error(null);
		}
	});
}

// Register the plugin with video.js.
videojs.registerPlugin("behaviorChangeSkipBadNetworkID", SkipBadNetworkID);

export default SkipBadNetworkID;
