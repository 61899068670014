import videojs from "video.js";

const MenuItem = videojs.getComponent("MenuItem");

/**
 * The base class for buttons
 *
 * @param {Player|Object} player
 * @param {Object=} options
 * @extends MenuItem
 * @class CopyLogsMenuItem
 */
const CopyLogsMenuItem = videojs.extend(MenuItem, {
	handleClick()
	{
		const text = videojs.log.history().map(log => log.join(" ")).join("\r\n");

		// IE
		if (window.clipboardData)
		{
			window.clipboardData.setData("Text", text);
		}
		// Other browsers
		else
		{
			// Microsoft Edge does not support window.clipboardData, but when
			// you append a new element to the page it scrolls, then leaves the
			// new scroll location - even if it puts you beyond the page
			const scroll = window.scrollY;
			const copyElement = document.createElement("textarea");
			document.body.appendChild(copyElement);
			copyElement.value = text;
			copyElement.select();
			copyElement.focus();
			copyElement.setSelectionRange(0, text.length);
			document.execCommand("copy");
			copyElement.blur();
			document.body.removeChild(copyElement);
			if (scroll && typeof window.scrollTo === "function")
			{
				// Some browsers (Opera Mini, IE 11, older Edge browsers, older
				// Android browsers, and IE Mobile on Windows Phone) do not
				// support scrollY and scrollTo, and will crash if we try to
				// call the scrollTo function
				window.scrollTo(scroll);
			}
		}
	},

	/**
	 * Builds the default DOM `className`.
	 *
	 * @return {string}
	 *		The DOM `className` for this object.
	*/
	buildCSSClass()
	{
		// ! For some stupid reason, VideoJS doesn't seem to call this method?
		return `vjs-copy-logs-menu-item ${MenuItem.prototype.buildCSSClass.call(this)}`;
	}
});

/**
 * The text for this MenuItem Added for localization.
 *
 * @type {string}
 * @private
 */
CopyLogsMenuItem.prototype.options_ = { label: "Copy Logs" };

videojs.registerComponent("copyLogsMenuItem", CopyLogsMenuItem);

export default CopyLogsMenuItem;
