import videojs from "video.js";
import xPath from "@utilities/XPath";
import "./Plugin.scss";
import BlueFrameClippingButton from "./components/BlueFrameClippingButton";
import BlueFrameClippingUI from "./components/BlueFrameClippingUI";
import ClipProgressBar from "./components/ClipProgressBar.js";
import ClipMessaging from "./components/ClipMessaging.js";

const Plugin = videojs.getPlugin("plugin");

// Default options for the plugin.
const defaults = {
	enabled: false
};

/**
 * An advanced Video.js plugin. For more information on the API
 *
 * See: https://blog.videojs.com/feature-spotlight-advanced-plugins/
 */
class BlueFrameClipping extends Plugin
{

	/**
   * Create a BlueFrameClipping plugin instance.
   *
   * @param  {Player} player
   *         A Video.js Player instance.
   *
   * @param  {Object} [options]
   *         An optional options object.
   *
   *         While not a core part of the Video.js plugin architecture, a
   *         second argument of options is a convenient way to accept inputs
   *         from your plugin's caller.
   */
	constructor(player, options)
	{
		// The parent class will add player under this.player
		super(player);

		this.options = videojs.mergeOptions(defaults, options);

		if (!this.options.enabled)
		{
			return;
		}

		player.ready(() =>
		{
			// Append our custom progress bar with two srubbers
			player.controlBar.progressControl.seekBar.addChild(
				new ClipProgressBar(
					player,
					videojs.mergeOptions(options, {name: "BlueFrameClipProgressBar"})
				)
			);

			// Append our clip messaging overlay
			player.addChild(new ClipMessaging(
				player,
				videojs.mergeOptions(options, {name: "ClipMessaging"})
			));

			// Append our overlay
			this.clippingUI = new BlueFrameClippingUI(
				player,
				videojs.mergeOptions(options, {name: "BlueFrameClippingUI"})
			);

			player.one("vmap-ready", () =>
			{
				const clippingTag = xPath(
					player.vmap, "//Extensions/Extension[@type='VolarVMAPExtension']/Clipping"
				).iterateNext();
				if (!clippingTag) return;

				this.clippingUI.clippingUrl = clippingTag.getAttribute("url");
				this.clippingUI.secret = clippingTag.getAttribute("secret");

				// Wait to add the UI when we know clipping is possible
				player.addChild(this.clippingUI);
				player.controlBar.addChild(
					new BlueFrameClippingButton(player, videojs.mergeOptions(options, {name: "BlueFrameClippingButton"})),
					options,
					// Place this just before the full screen button
					// Because we add this plugin before the quality selector, we
					// ultimately end up just before the quality selector
					this.player.controlBar.children().indexOf(
						this.player.controlBar.getChild("FullscreenToggle")
					)
				);
			});
		});
	}
}

// Register the plugin with video.js.
videojs.registerPlugin("controlBarButtonBlueFrameClipping", BlueFrameClipping);

export default BlueFrameClipping;
