// Pulled from:
// https://github.com/videojs/video.js/blob/6c696164673d461cae671382df2aaa0d53aef076/src/js/utils/url.js
// I wish I could just use the one BUILT IN to VideoJS, but it's not publicly
// exposed. For consistent behavior I copied theirs rather than rolling my own
// or finding one on NPMJS.
//
// NOTE: I've added a few things. Look below for comments with "Steven Barnett"
// in them.

/**
 * @file url.js
 * @module url
 */

/**
 * @typedef {Object} url:URLObject
 *
 * @property {string} protocol
 *           The protocol of the url that was parsed.
 *
 * @property {string} hostname
 *           The hostname of the url that was parsed.
 *
 * @property {string} port
 *           The port of the url that was parsed.
 *
 * @property {string} pathname
 *           The pathname of the url that was parsed.
 *
 * @property {string} search
 *           The search query of the url that was parsed.
 *
 * @property {string} hash
 *           The hash of the url that was parsed.
 *
 * @property {string} host
 *           The host of the url that was parsed.
 */

/**
 * Resolve and parse the elements of a URL.
 *
 * @function
 * @param    {String} url
 *           The url to parse
 *
 * @return   {url:URLObject}
 *           An object of url details
 */
export const parseUrl = function (url)
{
	const props = ["protocol", "hostname", "port", "pathname", "search", "hash", "host"];

	// add the url to an anchor and let the browser parse the URL
	let link = document.createElement("a");

	link.href = url;

	// IE8 (and 9?) Fix
	// ie8 doesn't parse the URL correctly until the anchor is actually
	// added to the body, and an innerHTML is needed to trigger the parsing
	const addToBody = link.host === "" && link.protocol !== "file:";
	let div;

	if (addToBody)
	{
		div = document.createElement("div");
		div.innerHTML = `<a href="${url}"></a>`;
		link = div.firstChild;
		// prevent the div from affecting layout
		div.setAttribute("style", "display:none; position:absolute;");
		document.body.appendChild(div);
	}

	// Copy the specific URL properties to a new object
	// This is also needed for IE8 because the anchor loses its
	// properties when it's removed from the dom
	const details = {};

	for (let i = 0; i < props.length; i++)
	{
		details[props[i]] = link[props[i]];
	}

	// IE9 adds the port to the host property unlike everyone else. If
	// a port identifier is added for standard ports, strip it.
	if (details.protocol === "http:")
	{
		details.host = details.host.replace(/:80$/, "");
	}

	if (details.protocol === "https:")
	{
		details.host = details.host.replace(/:443$/, "");
	}

	if (!details.protocol)
	{
		details.protocol = window.location.protocol;
	}

	/*
	 * Added by Steven Barnett:
	 *
	 * This will convert the search string into an object of parameters. This is
	 * is a more useful interface for reading and modifying query parameters.
	 * e.g. "?one=value&two=anotherValue" => {one: "value", two: "anotherValue"}
	 *
	 * TODO: Submit a PR to VideoJS to include this in the standard URL utility
	 */
	if (details.search)
	{
		details.parameters = details.search.substring(1).split("&").reduce((acc, param) =>
		{
			const paramParts = param.split("=");
			const key = paramParts[0];
			const value = paramParts.slice(1).join("=");
			acc[key] = value;
			return acc;
		}, {});
	}
	/*
	 * End addition
	 */

	if (addToBody)
	{
		document.body.removeChild(div);
	}

	return details;
};

/**
 * Steven Barnett created this method as an inverse to the above `parseUrl`
 * method. Given the output of `parseUrl`, this will convert back to a URL.
 *
 * NOTE: If a parameters property exists, it will be used to generate the search
 * string. The search property will be ignored in this case.
 *
 * TODO: Submit this as a PR to VideoJS
 *
 * @function
 *
 * @param    {url:URLObject}
 *           An object of url details
 *
 * @return   {String} url
 *           A URL based on the details object
 */
export const createUrl = function(details)
{
	if (details.parameters)
	{
		details.search = "?" + Object.keys(details.parameters).map(
			key => `${key}=${details.parameters[key]}`
		).join("&");
	}

	return (
		details.protocol + "//" +
		details.hostname +
		(details.port ? ":" + details.port : "") +
		details.pathname +
		details.search
	);
};

/**
 * Steven Barnett created this helper method for testing a string to see if it's
 * a valid URL. Handy to check before passing a URL to the player as a source or
 * before attempting to parse the URL with `parseUrl`.
 *
 * @function
 * @param    {String} test
 *           A string to test
 *
 * @return   {Boolean}
 *           Whether or not the string provided is a URL
 */
// TODO: For 100% accurate and proper host validation, we should technically use
// TODO: the algorithm described here:
// TODO:     https://url.spec.whatwg.org/#host-parsing
const urlRegex = /^(\w+:)?\/\/(\w+(:\w+)?@)?(\S+)(:[0-9]{1,5})?(\/|$)/;
export const isUrl = function (test)
{
	return urlRegex.test(test);
};

/**
 * Get absolute version of relative URL. Used to tell Flash the correct URL.
 *
 * @function
 * @param    {string} url
 *           URL to make absolute
 *
 * @return   {string}
 *           Absolute URL
 *
 * @see      http://stackoverflow.com/questions/470832/getting-an-absolute-url-from-a-relative-one-ie6-issue
 */
export const getAbsoluteURL = function (url)
{
	let absoluteUrl = url;

	// Check if absolute URL
	if (!url.match(/^https?:\/\//))
	{
		// Convert to absolute URL. Flash hosted off-site needs an absolute URL.
		const div = document.createElement("div");

		div.innerHTML = `<a href="${url}">x</a>`;
		absoluteUrl = div.firstChild.href;
	}

	return absoluteUrl;
};

/**
 * Returns the extension of the passed file name. It will return an empty string
 * if passed an invalid path.
 *
 * @function
 * @param    {string} path
 *           The fileName path like '/path/to/file.mp4'
 *
 * @return  {string}
 *           The extension in lower case or an empty string if no
 *           extension could be found.
 */
export const getFileExtension = function (path)
{
	if (typeof path === "string")
	{
		const splitPathRe = /^(\/?)([\s\S]*?)((?:\.{1,2}|[^/]+?)(\.([^./?]+)))(?:[/]*|[?].*)$/i;
		const pathParts = splitPathRe.exec(path);

		if (pathParts)
		{
			return pathParts.pop().toLowerCase();
		}
	}

	return "";
};

/**
 * Returns whether the url passed is a cross domain request or not.
 *
 * @function
 * @param    {string} url
 *           The url to check.
 *
 * @return   {boolean}
 *           Whether it is a cross domain request or not.
 */
export const isCrossOrigin = function (url)
{
	const winLoc = window.location;
	const urlInfo = parseUrl(url);

	// IE8 protocol relative urls will return ':' for protocol
	const srcProtocol = urlInfo.protocol === ":" ? winLoc.protocol : urlInfo.protocol;

	// Check if url is for another domain/origin
	// IE8 doesn't know location.origin, so we won't rely on it here
	const crossOrigin = (srcProtocol + urlInfo.host) !== (winLoc.protocol + winLoc.host);

	return crossOrigin;
};
