// Similar to Url.js and MimeTypes.js, VideoJS provides an events mixin for
// classes that is not available on their public API. Contrary to those two, I
// don't think their implementation will work for us.
//
// In case we want to create a generic class (not a Component) which supports
// event listeners, I created this base class. It has a very simple API,
// inspired by the VideoJS event system:
//
//    function functionName(event, data) { ... }
//    instance.on("event", functionName);
//    instance.off("event", functionName);
//    instance.one("event", functionName);
//    instance.trigger("event", data);
//
// I also added a helper function for removing all event listeners (to prepare
// object for garbage collection):
//
//    instance.offAll();

class Evented
{
	constructor()
	{
		this.handlers = {};
	}

	on(evtInput, callback)
	{
		let evts = evtInput;
		if (!Array.isArray(evts))
			evts = [evts];

		evts.forEach(evt =>
		{
			this.handlers[evt] = this.handlers[evt] || [];
			this.handlers[evt].push(callback);
		});
	}

	off(evtInput, callback)
	{
		let evts = evtInput;
		if (!Array.isArray(evts))
			evts = [evts];

		evts.forEach(evt =>
		{
			if (Array.isArray(this.handlers[evt]))
			{
				this.handlers[evt].splice(this.handlers[evt].indexOf(callback), 1);
			}
		});
	}

	one(evtInput, callback)
	{
		let evts = evtInput;
		if (!Array.isArray(evts))
			evts = [evts];

		const runOnce = () =>
		{
			this.off(evts, runOnce);
			callback.apply(null, arguments);
		};
		this.on(evts, runOnce);
	}

	trigger(evt, data, sync = false)
	{
		if (sync)
		{
			if (Array.isArray(this.handlers[evt]))
			{
				// Make a copy of the array before running handlers, just in
				// case our handlers array gets modified by any handlers
				// (e.g. a "one" call unbinding itself)
				const copy = this.handlers[evt].slice();
				copy.forEach(handler => handler(data));
			}
		}
		else
		{
			const self = this;
			setTimeout(() =>
			{
				if (Array.isArray(self.handlers[evt]))
				{
					const copy = self.handlers[evt].slice();
					copy.forEach(handler => handler(data));
				}
			}, 1);
		}
	}

	offAll()
	{
		this.handlers = {};
	}
}

export default Evented;
