import {sign, hash, hex} from "@utilities/HMAC";

/**
 * An overly simplified AWS request signing library exclusively for our internal
 * use. This way we don't rely on third-party libraries with their plethora of
 * issues and we can just sign a damn request.
 *
 * TODO: Support for query parameters and such
 *
 * https://docs.aws.amazon.com/general/latest/gr/sigv4_signing.html
 */
export default class AwsClient
{
	constructor(key, secret, region, service)
	{
		this.key = key;
		this.secret = secret;
		this.region = region;
		this.service = service;
	}

	fetch(url, opts)
	{
		if (!opts.method)
			opts.method = opts.body ? "POST" : "GET";

		const algorithm = "AWS4-HMAC-SHA256";
		const datetime = new Date().toISOString().replace(/[:-]|\.\d{3}/g, "");
		const date = datetime.substr(0, 8);
		const credential = [date, this.region, this.service, "aws4_request"].join("/");

		// Using document.createElement("a") is a well-known trick for parsing
		// URLs in a cross-browser way without the need for the "new URL" API
		// or any complex regex
		const link = document.createElement("a");
		link.href = url;

		opts.headers.Host = link.hostname;
		opts.headers["X-Amz-Date"] = datetime;

		let canonicalHeaders = [];
		for (const key in opts.headers)
		{
			if (Object.prototype.hasOwnProperty.call(opts.headers, key))
			{
				canonicalHeaders.push([key.toLowerCase(), opts.headers[key].trim().replace(/\s+/g, " ")]);
			}
		}
		canonicalHeaders = canonicalHeaders.sort((first, second) => first[0].localeCompare(second[0], "en"));

		const signedHeaders = canonicalHeaders.map(pair => pair[0]).join(";");

		const canonicalRequest =
			opts.method + "\n" +
			escape(link.pathname || "/") + "\n" +
			// There is no query string in our requests
			"\n" +
			canonicalHeaders.map(pair => pair[0] + ":" + pair[1]).join("\n") + "\n\n" +
			signedHeaders + "\n" +
			hash(opts.body);

		const hashedRequest = hash(canonicalRequest);

		const stringToSign =
			algorithm + "\n" +
			datetime + "\n" +
			credential + "\n" +
			hashedRequest;

		const kDate = sign("AWS4" + this.secret, date);
		const kRegion = sign(kDate, this.region);
		const kService = sign(kRegion, this.service);
		const kSigning = sign(kService, "aws4_request");

		const signature = hex(sign(kSigning, stringToSign));

		opts.headers.Authorization =
			algorithm + " " +
			"Credential=" + this.key + "/" + credential + ", " +
			"SignedHeaders=" + signedHeaders + ", " +
			"Signature=" + signature;

		// Some browsers don't allow you to set the Host header
		// But we still needed to add it to opts.headers above so it would
		// be listed as a canonical header for signing
		delete opts.headers.Host;
		return fetch(url, opts);
	}
}
