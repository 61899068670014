import videojs from "video.js";
import xPath from "@utilities/XPath";

const Plugin = videojs.getPlugin("plugin");

// Default options for the plugin.
const defaults = {};

/**
 * Extract the ContentVideoTitle tag from the VolarVMAP and handle it
 */
class ContentVideoTitleTagParser extends Plugin
{
	/**
	 * Create a ContentVideoTitleTagParser plugin instance.
	 *
	 * @param  {Player} player
	 *         A Video.js Player instance.
	 *
	 * @param  {Object} [options]
	 *         An optional options object.
	 *
	 *         While not a core part of the Video.js plugin architecture, a
	 *         second argument of options is a convenient way to accept inputs
	 *         from your plugin's caller.
	 */
	constructor(player, options)
	{
		// The parent class will add player under this.player
		super(player);

		this.options = videojs.mergeOptions(defaults, options);

		player.on("vmap-ready", () => { this.handle(player.vmap); });
	}

	handle(vmap)
	{
		this.vmap = vmap;

		const broadcastTag = xPath.ext(
			vmap,
			"Broadcast"
		).iterateNext();

		this.player.el_.setAttribute("aria-description", broadcastTag.getAttribute("title"));
	}
}

videojs.registerPlugin("vmapTagParserContentVideoTitle", ContentVideoTitleTagParser);

export default ContentVideoTitleTagParser;
