import videojs from "video.js";
import "./Plugin.scss";
import SkipBackButton from "./components/SkipBackButton";

const Plugin = videojs.getPlugin("plugin");

// Default options for the plugin.
const defaults = {};

/**
 * An advanced Video.js plugin. For more information on the API
 *
 * See: https://blog.videojs.com/feature-spotlight-advanced-plugins/
 */
class SkipBack extends Plugin
{

	/**
   * Create a SkipBack plugin instance.
   *
   * @param  {Player} player
   *         A Video.js Player instance.
   *
   * @param  {Object} [options]
   *         An optional options object.
   *
   *         While not a core part of the Video.js plugin architecture, a
   *         second argument of options is a convenient way to accept inputs
   *         from your plugin's caller.
   */
	constructor(player, options)
	{
		// The parent class will add player under this.player
		super(player);

		this.options = videojs.mergeOptions(defaults, options);

		this.player.ready(() =>
		{
			// The third parameter is the index where you want to place this button
			// If left out, it defaults to ControlBar.children_.length
			this.player.controlBar.addChild(
				new SkipBackButton(this.player, videojs.mergeOptions(options, {name: "SkipBack"})), options, 1
			);
		});
	}
}

// Register the plugin with video.js.
videojs.registerPlugin("controlBarButtonSkipBack", SkipBack);

export default SkipBack;
