import videojs from "video.js";
import xPath from "@utilities/XPath";
import NotifierOverlay from "./components/NotifierOverlay";
import "./Plugin.scss";

const logger = videojs.createLogger("VMAPTagParserNotifiers");
const Plugin = videojs.getPlugin("plugin");

const STATE = {
	// "_" is unofficial -- we use this when we don't have a valid state
	UNSET: "_",
	UPCOMING: "S",
	TEST: "T",
	LIVE: "L",
	STOP: "X",
	ARCHIVING: "A",
	// Not an offical state -- use this when we think arhiciving is done
	ARCHIVED: "_R",
	ERROR: "E",
	// Deleted?
	FINISHED: "U"
};

// When we broadcast the current state in an event, it'd be nice to have state
// names instead of just letters
const REV_LOOKUP = {};
Object.keys(STATE).forEach(key => REV_LOOKUP[STATE[key]] = key);

const STATUS = {
	SUCCESS: 200,
	FORBIDDEN: 403,
	NOT_FOUND: 404
};

const _minDuration = 10;

// Default options for the plugin.
const defaults = {
	// Time between polls to the state file
	interval: 10,
	// Time after the broadcast goes live before we start playing
	// Should be "a little more than 1 TS file"
	liveWait: 13,
	// Whether or not to display content during test mode
	allow_test: false,
	start_override: false,
	overrideStatus: false,
	// Messages to display in different states
	messages: {
		// ! Not using this, but probably should
		// audioOnly: "This is an audio-only broadcast",
		// Default to undefined
		upcoming: "Broadcast will start at the scheduled time",
		delayed: "Broadcast has been delayed",
		started: "Broadcast has started",
		stopped: "Broadcaster has stopped the stream",
		archiving: "Broadcast has started archiving",
		archived: "Broadcast is now available for on demand playback",
		finished: "Broadcast has finished",
		error: "We are experiencing technical difficulties"
	}
};

/**
 * Extract the Notifiers tag from the VolarVMAP and handle it
 *
 * TODO: This plugin uses flags to maintain state. This is almost an
 * TODO: anti-pattern due to the headache it creates when trying to perform
 * TODO: logic on state transitions. We should creates a "states" subdirectory
 * TODO: with a class for each state, then provide a "transitionTo()" method
 * TODO: to change states. In this way we can trivially add logic to a single
 * TODO: state or to the transition between states (for instance, displaying a
 * TODO: message the first time you enter ARCHIVING but not repeating the
 * TODO: message every 10 seconds thereafter)
 */
class NotifiersTagParser extends Plugin
{
	/**
	 * Create a NotifiersTagParser plugin instance.
	 *
	 * @param  {Player} player
	 *         A Video.js Player instance.
	 *
	 * @param  {Object} [options]
	 *         An optional options object.
	 *
	 *         While not a core part of the Video.js plugin architecture, a
	 *         second argument of options is a convenient way to accept inputs
	 *         from your plugin's caller.
	 */
	constructor(player, options)
	{
		// The parent class will add player under this.player
		super(player);

		this.options = videojs.mergeOptions(defaults, options);

		this.currentState = this.options.overrideStatus || STATE.UNSET;
		this.lastState = this.currentState;
		this.stateUrl = null;
		this.eventUrl = null;
		this.polling = false;
		this.pollTimeout = null;

		this.streaming = false;
		this.waitForArchive = false;

		this.boundUpdateState = this.updateState.bind(this);

		this.overlay = new NotifierOverlay(player, options);
		player.addChild(this.overlay);

		// Convert seconds to milliseconds
		this.options.interval = this.options.interval * 1000;

		if (this.options.overrideStatus)
		{
			this.updatePlayerBasedOnState();
		}
		else
		{
			player.on("vmap-ready", () => { this.handle(player.vmap); });
		}

		this.player.on(["stalled", "waiting"], this.playerStalled.bind(this));
	}

	handle(vmap)
	{
		this.vmap = vmap;

		// If we're currently waiting to poll the state file again, stop
		if (this.pollTimeout)
		{
			clearTimeout(this.pollTimeout);
			this.pollTimeout = null;
		}

		const notifiersTag = xPath.ext(vmap, "Notifiers").iterateNext();

		const msgNotifierTag = xPath(notifiersTag, "//Messages").iterateNext();
		if (msgNotifierTag !== null)
		{
			this.options.messages.upcoming = NotifiersTagParser.getAttribute(msgNotifierTag, "upcoming", this.options.messages.upcoming);
			this.options.messages.delayed = NotifiersTagParser.getAttribute(msgNotifierTag, "delayed", this.options.messages.delayed);
			this.options.messages.started = NotifiersTagParser.getAttribute(msgNotifierTag, "started", this.options.messages.started);
			this.options.messages.stopped = NotifiersTagParser.getAttribute(msgNotifierTag, "stopped", this.options.messages.stopped);
			this.options.messages.archiving = NotifiersTagParser.getAttribute(msgNotifierTag, "archiving", this.options.messages.archiving);
			this.options.messages.archived = NotifiersTagParser.getAttribute(msgNotifierTag, "archived", this.options.messages.archived);
			this.options.messages.finished = NotifiersTagParser.getAttribute(msgNotifierTag, "finished", this.options.messages.finished);
			this.options.messages.error = NotifiersTagParser.getAttribute(msgNotifierTag, "error", this.options.messages.error);
		}

		// if the broadcast state is STOP then we need to check if it is live or not.
		this.broadcastStatus = xPath(vmap, "//Broadcast").iterateNext().getAttribute("status");

		// Archived Broadcasts

		if (this.broadcastStatus === "Archived")
		{
			this.currentState = STATE.ARCHIVED;
			// Don't display the "Broadcast is now archived" message
			this.lastState = STATE.ARCHIVED;
			this.updatePlayerBasedOnState();
			return;
		}

		// Live Broadcasts

		const fileNotifierTag = xPath(notifiersTag, "//File").iterateNext();
		if (fileNotifierTag !== null)
		{
			this.stateUrl = fileNotifierTag.getAttribute("stateUrl").replace(/https?:/, "");
			// ! eventUrl is not currently used
			// It's not that we don't care about it, it's just that... we don't care
			this.eventUrl = fileNotifierTag.getAttribute("eventUrl").replace(/https?:/, "");

			this.polling = true;
		}

		// We also need the broadcast start time (for upcoming broadcasts)
		if (this.options.start_override)
		{
			this.broadcastStartTime = new Date(this.options.start_override * 1000);
		}
		else
		{
			const broadcastTag = xPath.ext(vmap, "Broadcast").iterateNext();
			this.broadcastStartTime = new Date(broadcastTag.getAttribute("date"));
		}

		if (this.polling)
		{
			this.pollState();
		}
	}

	static dateToHHMM(date)
	{
		const hourMod = 12;
		const amOrPm = date.getHours() < hourMod;
		let hours = date.getHours() % hourMod;
		// eslint-disable-next-line no-magic-numbers
		const minutes = ("00" + date.getMinutes()).substr(-2);

		if (hours === 0) hours = hourMod;

		return `${hours}:${minutes} ${amOrPm ? " AM" : " PM"}`;
	}

	static getAttribute(tag, name, defaultVal)
	{
		const newVal = tag.getAttribute(name);
		if (typeof newVal === "string" && newVal.length) return newVal;
		return defaultVal;
	}

	startMessage()
	{
		const now = new Date();
		// Just for convenience (less typing)
		const start = this.broadcastStartTime;
		if (now.getTime() > start.getTime())
		{
			// The broadcast was supposed to start in the past
			return this.options.messages.delayed;
		}
		else
		{
			return this.options.messages.upcoming;
		}
	}

	pollState()
	{
		// TODO: fetch doesn't support timeouts by default
		// TODO: The old player had a 5 second timeout. We should find a way to
		// TODO: implement something similar. Maybe just fall back to XHR
		fetch(this.stateUrl + "?cb=" + Math.random())
			.then(resp =>
			{
				logger.debug(`Retrieved state file. Status: ${resp.status}`);
				this.lastState = this.currentState;

				if (resp.status === STATUS.SUCCESS)
				{
					return resp.text().then(this.boundUpdateState);
				}
				else if (resp.status === STATUS.FORBIDDEN || resp.status === STATUS.NOT_FOUND)
				{
					// This is, itself, a status, unfortunately it's not the best status...
					// It happens when the broadcast is archived or hasn't started yet
					switch (this.currentState)
					{
					case STATE.UNSET:
					case STATE.UPCOMING:
						this.updateState(STATE.UPCOMING);
						break;
					default:
						this.updateState(STATE.ARCHIVED);
					}
				}
				else
				{
					logger.debug(`HTTP Status ${resp.status} when retrieving state file.`);
					this.lastState = this.currentState;
					this.updateState(STATE.ERROR);
				}
				return null;
			})
			.catch(() =>
			{
				logger.debug("Failed to retrieve state file.");
				this.lastState = this.currentState;
				this.updateState(STATE.ERROR);
			})
			.finally(() =>
			{
				this.updatePlayerBasedOnState();
			});
	}

	updateState(newState)
	{
		if (newState === STATE.STOP && (this.broadcastStatus === "Test" || this.broadcastStatus === "Testing" || this.broadcastStatus === "Upcoming"))
		{
			// intercept the new state and handle a special case.
			newState = STATE.TEST; // eslint-disable-line no-param-reassign
		}

		if (this.currentState !== newState)
		{
			logger(`New state: ${newState}`);
			this.currentState = newState;

			this.player.trigger("statechange", REV_LOOKUP[this.currentState]);
		}
	}

	startContent()
	{
		this.overlay.displayMessage(this.options.messages.started);
		this.player.one("playing", () => this.overlay.hide());
		// Wait to trigger this, so the M3U8 has time to get created and settle
		// in. Otherwise things get... weird
		setTimeout(() =>
		{
			this.player.trigger("reload-vmap");
		}, this.options.liveWait * 1000);
		// If autoplay is not enabled, we'll kickstart the content:
		if (!this.player.autoplay())
		{
			this.player.one("vmap-ready", () =>
			{
				this.player.play();
			});
		}
		this.streaming = true;
	}

	playerStalled()
	{
		logger(`Player stalled. Current state: ${REV_LOOKUP[this.currentState]}`);

		const duration = this.player.duration();
		const currentTime = this.player.currentTime();
		const timeFromEnd = duration - currentTime;
		logger(
			`Current Time: ${currentTime}, Duration: ${duration}, Time From End: ${timeFromEnd}`
		);

		// Sometimes the duration and currentTime will report "0" while the
		// player is loading, causing perfectly normal buffering to look like
		// the end of the broadcast
		// Hard-coded to the length of one TS file for now
		if (duration < _minDuration)
		{
			// Player hasn't fully loaded
			return;
		}

		if (timeFromEnd > 2)
		{
			// Just normal buffering.
			return;
		}

		switch (this.currentState)
		{
		case STATE.STOP:
			this.overlay.allowClose = true;
			this.overlay.displayMessage(this.options.messages.stopped);
			break;
		case STATE.ARCHIVED:
			if (this.streaming)
			{
				this.switchToArchived();
			}
			break;
		case STATE.ARCHIVING:
			// Because we haven't finished archiving, we can't refresh the VMAP
			// yet. So let's set a flag to refresh the VMAP the moment we can
			if (this.streaming)
			{
				logger("Content Finished");
				this.streaming = false;

				// Pause the content
				this.player.pause();
				// Hide the loading spinner
				this.player.loadingSpinner.hide();
				// Force the poster image to display (it's prettier than a frozen video)
				this.player.posterImage.lockShowing();
				// Hide the control bar (so they can't try to seek or replay the video)
				this.player.controlBar.hide();
				// Display a finished message
				this.overlay.allowClose = false;
				this.overlay.displayMessage(this.options.messages.finished);

				this.waitForArchive = true;
			}
			break;
		case STATE.ERROR:
			// Pause the content
			this.player.pause();
			// Hide the loading spinner
			this.player.loadingSpinner.hide();
			// Display an error message
			this.overlay.displayMessage(this.options.messages.error);
			break;
		default:
			/* Do nothing. We're just stalled. It'll pass. */
			break;
		}
	}

	switchToArchived()
	{
		this.streaming = false;
		logger("Switching from Live to Archived");
		// Re-load the VMAP (looking for the archived URL)
		this.player.trigger("reload-vmap");
		this.player.one("vmap-ready", () =>
		{
			logger("VMAP Reloaded");
			// Once we've reloaded the VMAP (and have new content), display
			// a new play button
			this.player.bigPlayButton.lockShowing();
			this.player.one("playing", () =>
			{
				logger("Playing");
				// Once they start playing, reset our UI
				this.player.loadingSpinner.show();
				this.player.posterImage.unlockShowing();
				this.player.bigPlayButton.unlockShowing();
				this.player.controlBar.show();
			});
		});
	}

	updatePlayerBasedOnState()
	{
		switch (this.currentState)
		{
		case STATE.UPCOMING:
			this.overlay.allowClose = false;
			this.overlay.displayMessage(this.startMessage());
			// Hide the big play button while we wait
			this.player.getChild("BigPlayButton").hide();
			break;
		case STATE.TEST:
			if (this.options.allow_test && !this.streaming && this.lastState !== STATE.UNSET)
			{
				// Restart the content
				this.startContent();
			}
			else if (this.options.allow_test && (this.lastState === STATE.UNSET || this.lastState === STATE.TEST))
			{
				// The content was already in test mode when we got here
				this.streaming = true;
			}
			else
			{
				this.overlay.allowClose = false;
				this.overlay.displayMessage(this.startMessage());
				this.player.getChild("BigPlayButton").hide();
			}
			break;
		case STATE.LIVE:
			if (this.lastState === STATE.TEST)
			{
				// We just went from test to live
				// This used to be a no-op. Admin players would keep pulling the
				// admin.m3u8 and non-admin players weren't in a streaming state
				// previously so they'd properly call startContent
				// But at some point we started serving a "test.3mu8", so this
				// doesn't work right anymore. We need to force another VMAP
				// reload. Fortunately we shouldn't need to delay one this one.
				this.player.trigger("reload-vmap");
				this.player.one("vmap-ready", () =>
				{
					// Trigger it to detect the new content URL
					this.player.play();
				});
			}
			if (!this.streaming && this.lastState !== STATE.UNSET)
			{
				// Restart the content
				this.startContent();
			}
			else if (this.lastState === STATE.UNSET)
			{
				// The content was already live when we got here
				this.streaming = true;
			}
			break;
		case STATE.STOP:
			// Even though the broadcast is stopped, we have content and will
			// display it - so we're "stremaing" as far as the viewer is aware
			this.streaming = true;
			break;
		case STATE.ARCHIVING:
			if (this.lastState !== STATE.ARCHIVING)
			{
				// We JUST entered this state
				this.overlay.allowClose = true;
				this.overlay.displayMessage(this.options.messages.archiving);
			}
			break;
		case STATE.ARCHIVED:
			this.polling = false;
			if (this.lastState !== STATE.ARCHIVED)
			{
				// We JUST entered this state
				this.overlay.allowClose = true;
				this.overlay.displayMessage(this.options.messages.archived);

				if (this.waitForArchive)
				{
					// We were waiting for this
					this.switchToArchived();
				}
			}
			break;
		case STATE.ERROR:
			break;
		case STATE.FINISHED:
			this.polling = false;
			break;
		default:
			logger.warn(`Unknown State: ${this.currentState}`);
		}

		if (this.polling)
			this.pollTimeout = setTimeout(this.pollState.bind(this), this.options.interval);
	}
}

videojs.registerPlugin("vmapTagParserNotifiers", NotifiersTagParser);

export default NotifiersTagParser;
