import videojs from "video.js";
import HMAC from "@utilities/HMAC";
import xPath from "@utilities/XPath";
import {getMimetype} from "@utilities/MimeTypes";
import GeoblockingOverlay from "./components/GeoblockingOverlay";
import "./Plugin.scss";

const logger = videojs.createLogger("VMAPTagParserContentGeoBlocking");
const Plugin = videojs.getPlugin("plugin");

// Default options for the plugin.
const defaults = {};

/**
 * Extract the ContentGeoBlocking tag from the VolarVMAP and handle it
 */
class ContentGeoBlockingTagParser extends Plugin
{
	/**
	 * Create a ContentGeoBlockingTagParser plugin instance.
	 *
	 * @param  {Player} player
	 *         A Video.js Player instance.
	 *
	 * @param  {Object} [options]
	 *         An optional options object.
	 *
	 *         While not a core part of the Video.js plugin architecture, a
	 *         second argument of options is a convenient way to accept inputs
	 *         from your plugin's caller.
	 */
	constructor(player, options)
	{
		// The parent class will add player under this.player
		super(player);

		this.options = videojs.mergeOptions(defaults, options);

		this.overlay = new GeoblockingOverlay(player, options);
		player.addChild(this.overlay);

		player.on("vmap-ready", () => { this.handle(player.vmap); });
	}

	handle(vmap)
	{
		this.vmap = vmap;

		const requestTag = xPath.ext(vmap, "Request").iterateNext();
		const contentTag = xPath.ext(vmap, "Content").iterateNext();

		this.content = {};
		this.content.geoblocked = contentTag.getAttribute("geoblocked") === "true";

		if (this.content.geoblocked)
		{
			this.content.geoAuthUrl = contentTag.textContent;
			this.content.usePaidGeoLocation = contentTag.getAttribute("usePaidGeoLocation") === "true";
			this.content.usePaidGeoCoding = contentTag.getAttribute("usePaidGeoCoding") === "true";
			this.content.allowUntrustedGeoLocation = contentTag.getAttribute("allowUntrustedGeoLocation") === "true";
			this.content.allowIpLookup = contentTag.getAttribute("allowIpLookup") === "true";
			this.content.googleMapsApiKey = contentTag.getAttribute("googleMapsApiKey");

			this.location = {
				ip: requestTag.getAttribute("ip")
			};

			this.handleGeoblock();
		}
	}

	handleGeoblock()
	{
		// Triangulation won't work on web (no access to cell towers or WiFi
		// access points), so we'll start with GPS
		if (this.content.allowUntrustedGeoLocation)
			return this.getLocationFromGPS();

		if (this.content.usePaidGeoLocation && this.content.allowIpLookup)
			return this.getLocationFromIP();

		// If we got this far, then:
		//  - The broadcast is geoblocked AND
		//  - Paid geolocation is disabled AND
		//  - Unpaid geolocation isn't trusted
		// In this case, we can't play the broadcast

		return this.authorize();
	}

	getLocationFromGPS()
	{
		logger("Getting geolocation from GPS");
		navigator.geolocation.getCurrentPosition(position =>
		{
			this.location.latitude = position.coords.latitude;
			this.location.longitude = position.coords.longitude;

			if (this.content.usePaidGeoCoding)
			{
				this.reverseGeocode();
			}
			else
			{
				this.authorize();
			}
		}, err =>
		{
			switch (err.code)
			{
			case err.PERMISSION_DENIED:
				logger.warn("User denied request for geolocation");
				break;
			case err.POSITION_UNAVAILABLE:
				logger.warn("Location information is unavailable");
				break;
			case err.TIMEOUT:
				logger.warn("The request to get user location timed out");
				break;
			default:
				logger.warn("Unknown geolocation error");
				break;
			}

			if (err.code !== err.PERMISSION_DENIED && this.content.usePaidGeoLocation)
			{
				// IP fallback
				this.getLocationFromIP();
			}
			else
			{
				this.authorize();
			}
		});
	}

	getLocationFromIP()
	{
		logger("Getting geolocation from IP");
		fetch(
			"https://www.googleapis.com/geolocation/v1/geolocate?key=" + this.content.googleMapsApiKey,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify({
					considerIp: true
				})
			}
		)
			.then(resp => resp.json())
			.then(resp =>
			{
				this.location.latitude = resp.location.lat;
				this.location.longitude = resp.location.lng;

				if (this.content.usePaidGeoCoding)
				{
					this.reverseGeocode();
				}
				else
				{
					this.authorize();
				}
			});
	}

	reverseGeocode()
	{
		fetch(
			"https://maps.googleapis.com/maps/api/geocode/json?key=" +
			this.content.googleMapsApiKey +
			"&latlng=" + this.location.latitude + "," + this.location.longitude
		)
			.then(resp => resp.json())
			.then(resp =>
			{
				if (resp.results && resp.results[0])
				{
					for (let i = 0; i < resp.results[0].address_components.length; i++)
					{
						const component = resp.results[0].address_components[i];
						switch (component.types[0])
						{
						case "postal_code":
							this.location.postal_code = component.long_name;
							break;
						case "country":
							this.location.country_code = component.short_name;
							break;
						case "administrative_area_level_1":
							this.location.region_name = component.short_name;
							break;
						case "locality":
							this.location.city = component.short_name;
							break;
						default:
							break;
						}
					}
				}

				this.authorize();
			});
	}

	authorize()
	{
		// Historically geoblocked broadcasts returned an "auth" URL that we
		// would POST location info to and get the content URL back as a result
		// Now they just return the content URL but M3U8 content protection
		// prevents access without signed location info in the query params

		const link = document.createElement("a");
		link.href = this.content.geoAuthUrl;

		if (this.location !== null)
		{
			// 1. Sign our location information
			const orderedKeys = [
				"city",
				"country_code",
				"ip",
				"latitude",
				"longitude",
				"metro_code",
				"postal_code",
				"region_name"
			];
			let stringToHash = "UJUazJPTpas24BPPtQbDBA4F7Bq6kEMg0xykGQ535Ft9OKGw11Mzgl9oaACQ|";
			stringToHash += orderedKeys.map(key => this.location[key] || "").join("|");
			const signature = HMAC.hash(stringToHash);

			// 2. Append the signed location to the content URL
			let query = orderedKeys
				.map(key => { return this.location[key] ? `${key}=${this.location[key]}` : null; })
				.filter(el => el !== null)
				.join("&");
			query = query + "&signature=" + signature;

			link.search += (link.search.length ? "&" : "?") + query;
		}

		// TODO: Instead of fetching the M3U8 and processing the response here,
		// we'll just update the content URL (somehow?) and let the
		// VolarVMAPSourceHandler process the response. Then we'll listen for a
		// "blocked" or "blockedContent" event, and react accordingly
		this.player.vmapTagParserContent().hasContent = true;
		this.player.tech_.sourceHandler_.addSource({
			src: link.href,
			type: getMimetype(link.pathname)
		});

		this.player.on("contentblocked", (evt, reason) =>
		{
			this.overlay.show(reason);
		});

		// Since ContentTagParser didn't run, we have to call this now
		if (this.player.autoplay())
		{
			this.player.play();
			// this.player.tech_.sourceHandler_.playSource();
		}
	}
}

videojs.registerPlugin("vmapTagParserContentGeoBlocking", ContentGeoBlockingTagParser);

export default ContentGeoBlockingTagParser;
