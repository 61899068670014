import videojs from "video.js";

const Button = videojs.getComponent("Button");

// In seconds:
const skipAmount = 30;

/**
 * The base class for buttons
 *
 * @param {Player|Object} player
 * @param {Object=} options
 * @extends Button
 * @class SkipForwardButton
 */
class SkipForwardButton extends Button
{
	/**
	 * Creates an instance of this class.
	 *
	 * @param {Player} player
	 *		The `Player` that this class should be attached to.
	 *
	 * @param {Object} [options={}]
	 *		The key/value store of player options.
	 */
	constructor(player, options = {})
	{
		super(player, options);
		this.player = player;

		this.controlText(this.localize(this.controlText_, [skipAmount]));

		this.player.on("seekingdisabled", this.hide.bind(this));
		this.player.on("seekingenabled", this.show.bind(this));
	}

	/**
	 * Builds the default DOM `className`.
	 *
	 * @return {string}
	 *		The DOM `className` for this object.
	 */
	buildCSSClass()
	{
		return `vjs-skip-forward-button ${super.buildCSSClass()}`;
	}

	/**
	 * This gets called when an `SkipForwardButton` is "clicked". See
	 * {@link ClickableComponent} for more detailed information on what a click can be.
	 *
	 * @param {EventTarget~Event} [event]
	 *		The `keydown`, `tap`, or `click` event that caused this function to be
	 *		called.
	 *
	 * @listens keydown
	 * @listens tap
	 * @listens click
	 */
	handleClick()
	{
		const seekTarget = this.player.currentTime() + skipAmount;
		const duration = this.player.duration();
		this.player.currentTime(Math.min(seekTarget, duration));
	}
}

/**
 * The text that should display over the `SkipForwardButton`s controls. Added for localization.
 *
 * @type {string}
 * @private
 */
SkipForwardButton.prototype.controlText_ = "Seek Forward {1} seconds";

videojs.registerComponent("skipForwardButton", SkipForwardButton);

export default SkipForwardButton;
