import videojs from "video.js";

import "./Plugin.scss";

/**
 * A basic Video.js plugin.
 */
function HidePlaybackControls()
{
	const player = this;

	player.ready(() =>
	{
		const progressControl = player.controlBar.progressControl;
		const remainingTimeDisplay = player.controlBar.remainingTimeDisplay;
		const playbackRateButton = player.controlBar.playbackRateMenuButton;
		player.on("seekingdisabled", () =>
		{
			progressControl.addClass("vjs-lock-hidden");
			remainingTimeDisplay.addClass("vjs-lock-hidden");
			playbackRateButton.addClass("vjs-lock-hidden");
		});
		player.on("seekingenabled", () =>
		{
			progressControl.removeClass("vjs-lock-hidden");
			remainingTimeDisplay.removeClass("vjs-lock-hidden");
			playbackRateButton.removeClass("vjs-lock-hidden");
		});
	});
}

// Register the plugin with video.js.
videojs.registerPlugin("behaviorChangeHidePlaybackControls", HidePlaybackControls);

export default HidePlaybackControls;
