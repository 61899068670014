/**
 * video.js plugin providing seek bar thumbnails
 * Author: Brandon Draeger (@draeger)
 *
 * I (Steven Barnett) copied this from our old player (v3.11.2) to avoid having
 * to roll our own plugin. If for any reason I need to modify some part of this,
 * I'll comment as such up here.
 *
 *  - Removed a bunch of old (commented out) code
 *  - Tweaked styling per eslint rules
 *  - Removed support for default thumbnails when `null` is returned
 *
 * TODO: I would like to extend our thumbnail solution to support several new
 * TODO: features:
 * TODO:    - WebVTT thumbnails
 * TODO:    - TextTrack thumbnails
 * TODO:    - Sprite thumbnails
 * TODO:    - Manually declared thumbnails
 * TODO:    - Thumbnails generated from byte range partials
 * TODO: We can also add some less-important features:
 * TODO:    - Display adjacent thumbnails
 * TODO:    - Zoom effect
 * TODO:    - Animated thumbnails
 * TODO: For inspiration and code examples, see any of the following:
 * TODO: https://www.npmjs.com/package/@straas/videojs-thumbnails
 * TODO: https://www.npmjs.com/package/videojs-vtt-thumbnails
 * TODO: https://www.npmjs.com/package/@hola.org/videojs-thumbnails
 * TODO: https://www.npmjs.com/package/videojs-video-tooltip
 * TODO: https://www.npmjs.com/package/videojs-sprite-thumbnails
 */

import videojs from "video.js";

(function ()
{
	var defaults = {
		enabled: true,
		width: 128,
		height: 72,
		// URL
		backup: null,
		// A function passed from the parent which is given a time in seconds and returns an image url
		thumbnailConstructor: function () { return null; },
		// A function passed from the parent which is given the current image url and returns an image url
		// Called if an image fails to load
		thumbnailError: function () { return null; },
	};

	var instance = null;

	/**
	 * register the thubmnails plugin
	 */
	videojs.registerPlugin("thumbnails", function (options)
	{
		var settings = videojs.mergeOptions(defaults, options);

		if (instance !== null)
		{
			if (options)
			{
				instance.setOptions(settings);
			}
			return instance;
		}

		var player = this;
		var progressControl = player.controlBar.progressControl;
		var mouseTimeDisplay = progressControl.seekBar.mouseTimeDisplay;
		var div, img, background;
		var enabled = settings.enabled;

		// __init__
		(function ()
		{
			// Create the thumbnail
			div = document.createElement("div");
			background = document.createElement("div");
			img = document.createElement("img");

			div.className = "vjs-thumbnail-holder";
			background.className = "vjs-thumbnail vjs-thumbnail-border";
			img.className = "vjs-thumbnail-img";

			// Called if a given src doesn't exist
			img.addEventListener("error", onError);

			background.style.width = settings.width + "px";
			background.style.height = settings.height + "px";
			if (settings.backup) background.style.background = "url('" + settings.backup + "') no-repeat center";

			background.style.left = -(parseFloat(settings.width) / 2) + "px";

			div.appendChild(background);
			background.appendChild(img);

			// add the thumbnail to the player
			progressControl.el().appendChild(div);

			// update the thumbnail while hovering
			progressControl.on("mousemove", moveListener);
			progressControl.on("touchmove", moveListener);

			// move the placeholder out of the way when not hovering
			progressControl.on("mouseout", moveCancel);
			progressControl.on("touchcancel", moveCancel);
			progressControl.on("touchend", moveCancel);
			player.on("userinactive", moveCancel);
		})();

		function onError()
		{
			var newSrc = settings.thumbnailError(img.src);
			if (newSrc && newSrc !== img.src)
			{
				img.src = newSrc;
			}
		}

		function calculateMouseTime()
		{
			var mouseTimeHMS = mouseTimeDisplay.timeTooltip.el().innerText.split(":");
			var mouseTime = 0;

			for (var len = mouseTimeHMS.length, i = len - 1; i >= 0; i--)
			{
				mouseTime += mouseTimeHMS[i] * Math.pow(60, len - 1 - i);
			}

			return mouseTime;
		}

		function moveCancel()
		{
			div.style.left = "-1000px";
		}

		function moveListener(event)
		{
			if (!enabled || !mouseTimeDisplay)
			{
				moveCancel();
				return;
			}

			var mouseTime, /* time, */ left, setting, pageX, width, halfWidth;
			// var active = 0;
			var pageXOffset = getScrollOffset().x;
			var clientRect = offsetParent(progressControl.el()).getBoundingClientRect();
			var right = (clientRect.width || clientRect.right) + pageXOffset;

			pageX = event.pageX;
			if (event.changedTouches)
			{
				pageX = event.changedTouches[0].pageX;
			}

			// Find the page offset of the mouse
			left = pageX || (event.clientX + document.body.scrollLeft + document.documentElement.scrollLeft);
			// Subtract the page offset of the positioned offset parent
			left -= offsetParent(progressControl.el()).getBoundingClientRect().left + pageXOffset;

			// Apply updated styles to the thumbnail if necessary
			// mouseTime is the position of the mouse along the progress control bar
			// `left` applies to the mouse position relative to the player so we need
			// to remove the progress control's left offset to know the mouse position
			// relative to the progress control

			mouseTime = calculateMouseTime();

			setting = {
				src: settings.thumbnailConstructor(mouseTime),
			};

			if (setting.src)
			{
				setting.width = settings.width;
			}
			else
			{
				return;
			}

			if (setting.src && img.src !== setting.src)
			{
				img.src = setting.src;
			}
			if (setting.style && img.style !== setting.style)
			{
				videojs.mergeOptions(img.style, setting.style);
			}

			width = getVisibleWidth(img, setting.width || settings[0].width);
			halfWidth = width / 2;

			// Make sure that the thumbnail doesn't fall off the right side of the left side of the player
			if ((left + halfWidth) > right)
			{
				left -= (left + halfWidth) - right;
			}
			else if (left < halfWidth)
			{
				left = halfWidth;
			}

			div.style.left = left + "px";
		}

		function toggleEnabled(newState)
		{
			enabled = newState;
		}

		function setOptions(newOpts)
		{
			settings = newOpts;
		}

		// public functions and properties
		return instance = {
			toggleEnabled: toggleEnabled,
			setOptions: setOptions
		};
	});

	/* Helper Functions */

	function getComputedStyle(el, pseudo)
	{
		return function (prop)
		{
			if (window.getComputedStyle)
			{
				return window.getComputedStyle(el, pseudo)[prop];
			}
			else
			{
				return el.currentStyle[prop];
			}
		};
	}

	function offsetParent(el)
	{
		if (el.nodeName !== "HTML" && getComputedStyle(el)("position") === "static")
		{
			return offsetParent(el.offsetParent);
		}
		return el;
	}

	function getVisibleWidth(el, width)
	{
		var clip;

		if (width)
		{
			return parseFloat(width);
		}

		clip = getComputedStyle(el)("clip");
		if (clip !== "auto" && clip !== "inherit")
		{
			clip = clip.split(/(?:\(|\))/)[1].split(/(?:,| )/);
			if (clip.length === 4)
			{
				return parseFloat(clip[1]) - parseFloat(clip[3]);
			}
		}
		return 0;
	}

	function getScrollOffset()
	{
		if (window.pageXOffset)
		{
			return {
				x: window.pageXOffset,
				y: window.pageYOffset
			};
		}
		return {
			x: document.documentElement.scrollLeft,
			y: document.documentElement.scrollTop
		};
	}
})();
