import videojs from "video.js";

/*
* By default the player controls turned off when the player in initalized using v().
* At this moment the player *really* doesnt like being played before it is ready when using a vmap.
* When this happens the player is stuck in a state that it can not get out of.
* The two ways I see this happen is when the user presses play to quickly and the vmap hasnt
* loaded yet and when the player is in the middle of completing the geolocation tasks
* for geoblocked broadcast. In both cases the player doesnt have a "source" yet so when
* playSource() is run things get weird. There are some assumption made there that arent always true.
*
* The simplest way I see to fix this is to just not let the user press play until the player is ready.
* Autoplay waits for the player so autoplay is unaffected.
*
* When v() creates the <video> tag I have removed the contols options so it is intialized without controls.
* This should not affect implementations where the player is intialized without v() and not using a vmap
* Why someone that is not blueframe would use our player and not use vmaps I dont know but we dont lose that ability
*/

function ToggleControls()
{
	const player = this;
	// const opts = videojs.mergeOptions(defaults, options);

	// Add controls
	player.on("sourceadded", () =>
	{
		player.controls(true);
	});

	// remove controls
	player.on("contentblocked", () =>
	{
		player.controls(false);
	});
	player.on("playerinactive", () =>
	{
		player.controls(false);
	});
}

// Register the plugin with video.js.
videojs.registerPlugin("ToggleControls", ToggleControls);

export default ToggleControls;
