import videojs from "video.js";

const Component = videojs.getComponent("Component");

// Default options for the plugin.
const defaults = {};

/**
 * @param {Player|Object} player
 * @param {Object=} options
 * @extends Component
 * @class ClipMessaging
 */
class ClipMessaging extends Component
{
	constructor(player, options = {})
	{
		super(player, options);
		this.player = player;
		this.options = videojs.mergeOptions(defaults, options);
		this.el_.className = this.buildCSSClass();

		this.hide();
	}

	display(message)
	{
		this.el_.innerHTML = message;
		this.show();
	}

	/**
	 * Builds the default DOM `className`.
	 *
	 * @return {string}
	 *		The DOM `className` for this object.
	 */
	buildCSSClass()
	{
		return `vjs-clip-message ${super.buildCSSClass()}`;
	}
}

ClipMessaging.prototype.controlText_ = "Clip Messaging";

Component.registerComponent("clipMessaging", ClipMessaging);

export default ClipMessaging;
