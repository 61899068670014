import videojs from "video.js";
import Cache from "@utilities/Cache";

const logger = videojs.createLogger("BehaviorChangeLimitConcurrentViewers");
const ModalDialog = videojs.getComponent("ModalDialog");

// Any string shorter than this is obviously not a Lambda URL
const _expectedSignatureLength = 64;
const _minServiceUrlLength = 10;
const _minHeartbeatInterval = 5;

// Default opts for the plugin.
const defaults = {
	// Other than the signature, these values should be delivered by the CMS and
	// signed. Any value not send by the CMS is therefore invalid, as the
	// signature would not be accurate
	serviceUrl: null,
	heartbeatInterval: null,
	timeout: null,
	orderNumber: null,
	concurrentViewers: null,
	signature: null
};

/**
 * A basic Video.js plugin.
 */
function LimitConcurrentViewers(options)
{
	const player = this;
	const opts = videojs.mergeOptions(defaults, options);

	let enabled = true;

	if (
		typeof opts.serviceUrl !== "string" || opts.serviceUrl.length < _minServiceUrlLength
	)
	{
		logger.debug("serviceUrl was invalid");
		enabled = false;
	}

	if (
		typeof opts.heartbeatInterval !== "number" || opts.heartbeatInterval < _minHeartbeatInterval
	)
	{
		logger.debug("heartbeatInterval was invalid");
		enabled = false;
	}

	if (
		typeof opts.timeout !== "number" || opts.timeout < opts.heartbeatInterval
	)
	{
		logger.debug("timeout was invalid");
		enabled = false;
	}

	if (
		typeof opts.orderNumber !== "string" || opts.orderNumber.length === 0
	)
	{
		logger.debug("orderNumber was invalid");
		enabled = false;
	}

	if (
		typeof opts.concurrentViewers !== "number" || opts.concurrentViewers < 0
	)
	{
		logger.debug("concurrentViewers was invalid");
		enabled = false;
	}

	if (
		typeof opts.signature !== "string" || opts.signature.length !== _expectedSignatureLength
	)
	{
		logger.debug("signature was invalid");
		enabled = false;
	}

	if (!enabled) return;

	opts.playerFingerprint = Cache.set(
		"playerFingerprint",
		Cache.get("playerFingerprint", Cache.guid())
	);

	const modal = new ModalDialog(player, {
		temporary: false,
		uncloseable: true,
		content: "There are too many viewers using this purchase ID." +
					" Please turn off some devices before opening a new player."
	});
	player.addChild(modal);

	// Immediately send the first heartbeat
	sendInitialHeartbeat();

	function sendInitialHeartbeat ()
	{
		fetch(opts.serviceUrl, {
			method: "POST",
			mode: "cors",
			cache: "no-cache",
			credentials: "omit",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(opts)
		})
			.then(resp => resp.json())
			.then(resp =>
			{
				if (resp.kick)
				{
					const con = resp.concurrentViewers;
					const maxCon = opts.concurrentViewers;
					logger.warn(`${con} concurrent viewers > ${maxCon}... Kicking this player`);
					kickSelf();
				}
			})
			.catch(() =>
			{
				logger.error(`Error hitting service URL: ${opts.serviceUrl}`);
			})
			.finally(() =>
			{
				setTimeout(sendHeartbeat, opts.heartbeatInterval * 1000);
			});
	}

	function sendHeartbeat ()
	{
		fetch(opts.serviceUrl, {
			method: "POST",
			mode: "cors",
			cache: "no-cache",
			credentials: "omit",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(opts)
		})
			.then(resp => resp.json())
			.then(resp =>
			{
				if (resp.kick)
				{
					const con = resp.concurrentViewers;
					const maxCon = opts.concurrentViewers;
					logger.debug(`${con} concurrent viewers > ${maxCon}. Other player should be kicked.`);
				}
			})
			.catch(() =>
			{
				logger.error(`Error hitting service URL: ${opts.serviceUrl}`);
			})
			.finally(() =>
			{
				setTimeout(sendHeartbeat, opts.heartbeatInterval * 1000);
			});
	}

	function kickSelf ()
	{
		// Easiest way to prevent anything from playing (ever)
		player.pause();
		player.on(["play", "contentplay", "playing", "contentplaying"], () =>
		{
			logger.debug("Pausing player since they've been kicked from this stream");
			setTimeout(() => player.pause(), 1);
		});
		modal.open();
		player.posterImage.lockShowing();
	}
}

// Register the plugin with video.js.
videojs.registerPlugin("behaviorChangeLimitConcurrentViewers", LimitConcurrentViewers);

export default LimitConcurrentViewers;
