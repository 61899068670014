import videojs from "video.js";
import Cache from "@utilities/Cache";

const logger = videojs.createLogger("BehaviorChangeRememberVolume");

// Default options for the plugin.
const defaults = {
	caching: true
};

/**
 * A basic Video.js plugin.
 */
function RememberVolume(options)
{
	const player = this;
	const opts = videojs.mergeOptions(defaults, options);

	// On touch-enabled devices we just use the device volume
	if (opts.caching && !videojs.browser.TOUCH_ENABLED)
	{
		// If videojs-contrib-ads redispatches with a prefixed event name,
		// we should still update our cached volume
		player.on(["volumechange", "contentvolumechange", "advolumechange"], saveVolume);
		player.one("ready", loadVolume);
	}

	function saveVolume()
	{
		Cache.set("volume", player.volume());
		Cache.set("muted", player.muted());
		logger(
			`SAVED volume=${player.volume()}, ` +
			`muted=${player.muted()}`
		);
	}

	function loadVolume()
	{
		const volume = Cache.get("volume");
		const muted = Cache.get("muted");
		logger(
			`LOADED volume=${volume}, ` +
			`muted=${muted}`
		);
		if (!isNaN(volume))
		{
			player.volume(volume);
			player.muted(muted);
		}
	}
}

// Register the plugin with video.js.
videojs.registerPlugin("behaviorChangeRememberVolume", RememberVolume);

export default RememberVolume;
