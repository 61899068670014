import videojs from "video.js";

const Component = videojs.getComponent("Component");
const MenuItem = videojs.getComponent("MenuItem");

/**
 * The base class for buttons
 *
 * @param {Player|Object} player
 * @param {Object=} options
 * @extends MenuButton
 * @class QualitySelectorMenuItem
 */
class QualitySelectorMenuItem extends MenuItem
{
	/**
	 * Creates an instance of this class.
	 *
	 * @param {Player} player
	 *		The `Player` that this class should be attached to.
	 *
	 * @param {Object} [options={}]
	 *		The key/value store of player options.
	 */
	constructor(player, options = {})
	{
		super(player, options);
		this.player = player;
		this.options = options;
	}

	handleClick()
	{
		// Select this options and de-select other options
		// TODO: See if VideoJS provides a more elegant solution
		const menuItems = this.options.menuButton.menu.children();
		for (let i = 0; i < menuItems.length; i++)
		{
			menuItems[i].selected(menuItems[i] === this);
		}

		// Set quality level accordingly
		const qualityLevels = this.player.qualityLevels();
		for (let i = 0; i < qualityLevels.length; i++)
		{
			// If this is the Auto option, enable all renditions for adaptive selection
			if (this.options.index === -1)
			{
				qualityLevels[i].enabled = true;
			}
			else if (i === this.options.index)
			{
				qualityLevels[i].enabled = true;
			}
			else
			{
				qualityLevels[i].enabled = false;
			}
		}
	}

	selected(val)
	{
		// So we can easily add a breakpoint here
		super.selected(val);
	}
}

Component.registerComponent("qualitySelectorMenuItem", QualitySelectorMenuItem);

export default QualitySelectorMenuItem;
