// Pulled from:
// https://github.com/videojs/video.js/blob/6c696164673d461cae671382df2aaa0d53aef076/src/js/utils/mimetypes.js
// I wish I could just use the one BUILT IN to VideoJS, but it's not publicly
// exposed. For consistent behavior I copied theirs rather than rolling my own
// or finding one on NPMJS.

import * as Url from "./Url.js";

/**
 * Mimetypes
 *
 * @see http://hul.harvard.edu/ois/////systems/wax/wax-public-help/mimetypes.htm
 * @typedef Mimetypes~Kind
 * @enum
 */
export const MimetypesKind = {
	opus: "video/ogg",
	ogv: "video/ogg",
	mp4: "video/mp4",
	mov: "video/mp4",
	m4v: "video/mp4",
	mkv: "video/x-matroska",
	mp3: "audio/mpeg",
	aac: "audio/aac",
	oga: "audio/ogg",
	m3u8: "application/x-mpegURL"
};

/**
 * Get the mimetype of a given src url if possible
 *
 * @param {string} src
 *        The url to the src
 *
 * @return {string}
 *         return the mimetype if it was known or empty string otherwise
 */
export const getMimetype = function (src = "")
{
	const ext = Url.getFileExtension(src);
	const mimetype = MimetypesKind[ext.toLowerCase()];

	return mimetype || "";
};

/**
 * Find the mime type of a given source string if possible. Uses the player
 * source cache.
 *
 * @param {Player} player
 *        The player object
 *
 * @param {string} src
 *        The source string
 *
 * @return {string}
 *         The type that was found
 */
export const findMimetype = (player, src) =>
{
	if (!src)
	{
		return "";
	}

	// 1. check for the type in the `source` cache
	if (player.cache_.source.src === src && player.cache_.source.type)
	{
		return player.cache_.source.type;
	}

	// 2. see if we have this source in our `currentSources` cache
	const matchingSources = player.cache_.sources.filter((srcObj) => srcObj.src === src);

	if (matchingSources.length)
	{
		return matchingSources[0].type;
	}

	// 3. look for the src url in source elements and use the type there
	const sources = player.$$("source");

	for (let i = 0; i < sources.length; i++)
	{
		const srcObj = sources[i];

		if (srcObj.type && srcObj.src && srcObj.src === src)
		{
			return srcObj.type;
		}
	}

	// 4. finally fallback to our list of mime types based on src url extension
	return getMimetype(src);
};
