import videojs from "video.js";
import "./Plugin.scss";
import SkipForwardButton from "./components/SkipForwardButton";

/**
 * A basic Video.js plugin. For more information on the API
 *
 * See: https://docs.videojs.com/tutorial-plugins.html
 */
function SkipForward(options)
{
	const player = this;

	player.ready(() =>
	{
		// The third parameter is the index where you want to place this button
		// If left out, it defaults to ControlBar.children_.length
		player.controlBar.addChild(
			new SkipForwardButton(player, videojs.mergeOptions(options, {name: "SkipForward"})), options, 1
		);
	});
}

// Register the plugin with video.js.
videojs.registerPlugin("controlBarButtonSkipForward", SkipForward);
