import videojs from "video.js";
import ClipButton from "./ClipButton";

const Component = videojs.getComponent("Component");

// Default options for the plugin.
const defaults = {};

/**
 * @param {Player|Object} player
 * @param {Object=} options
 * @extends Component
 * @class ClipDownloadSelect
 */
class ClipDownloadSelect extends Component
{
	constructor(player, options = {})
	{
		super(player, options);
		this.player = player;
		this.options = videojs.mergeOptions(defaults, options);
		this.el_.className = this.buildCSSClass();

		const mp4Button = new ClipButton(player, {buttonText: this.localize("Video (MP4)")});
		const gifButton = new ClipButton(player, {buttonText: this.localize("Animated GIF")});
		const mp3Button = new ClipButton(player, {buttonText: this.localize("Audio (MP3)")});

		this.addChild(mp4Button);
		this.addChild(gifButton);
		this.addChild(mp3Button);

		mp4Button.on("click", () => this.trigger("download", "mp4"));
		gifButton.on("click", () => this.trigger("download", "gif"));
		mp3Button.on("click", () => this.trigger("download", "mp3"));
	}

	/**
	 * Builds the default DOM `className`.
	 *
	 * @return {string}
	 *		The DOM `className` for this object.
	 */
	buildCSSClass()
	{
		return `vjs-clip-download-select ${super.buildCSSClass()}`;
	}
}

ClipDownloadSelect.prototype.controlText_ = "Clip Download Type";

Component.registerComponent("clipDownloadSelect", ClipDownloadSelect);

export default ClipDownloadSelect;
