import videojs from "video.js";

const Component = videojs.getComponent("Component");
const Button = videojs.getComponent("Button");

/**
 * The base class for buttons
 *
 * @param {Player|Object} player
 * @param {Object=} options
 * @extends Button
 * @class StatsForNerdsPluginButton
 */
class StatsForNerdsPluginButton extends Button
{
	/**
	 * Creates an instance of this class.
	 *
	 * @param {Player} player
	 *		The `Player` that this class should be attached to.
	 *
	 * @param {Object} [options={}]
	 *		The key/value store of player options.
	 */
	constructor(player, options = {})
	{
		super(player, options);
		this.player = player;

		this.overlay = player.getChild("StatsForNerdsOverlay");
	}

	/**
	 * Builds the default DOM `className`.
	 *
	 * @return {string}
	 *		The DOM `className` for this object.
	 */
	buildCSSClass()
	{
		return `vjs-stats-for-nerds-plugin-button ${super.buildCSSClass()}`;
	}

	/**
	 * This gets called when an `StatsForNerdsPluginButton` is "clicked". See
	 * {@link ClickableComponent} for more detailed information on what a click can be.
	 *
	 * @param {EventTarget~Event} [event]
	 *		The `keydown`, `tap`, or `click` event that caused this function to be
	 *		called.
	 *
	 * @listens tap
	 * @listens click
	 */
	handleClick()
	{
		super.handleClick();

		// TODO: Toggle display instead of just showing
		this.overlay.show();
	}
}

/**
 * The text that should display over the `StatsForNerdsPluginButton`s controls. Added for localization.
 *
 * @type {string}
 * @private
 */
StatsForNerdsPluginButton.prototype.controlText_ = "StatsForNerdsPlugin";

Component.registerComponent("statsForNerdsPluginButton", StatsForNerdsPluginButton);

export default StatsForNerdsPluginButton;
