import videojs from "video.js";
import xPath from "@utilities/XPath";

const logger = videojs.createLogger("VMAPTagParserPoster");
const Plugin = videojs.getPlugin("plugin");

// Default options for the plugin.
const defaults = {};

/**
 * Set the player poster per the VMAP
 */
class Poster extends Plugin
{
	/**
	 * Create a Poster plugin instance.
	 *
	 * @param  {Player} player
	 *         A Video.js Player instance.
	 *
	 * @param  {Object} [options]
	 *         An optional options object.
	 *
	 *         While not a core part of the Video.js plugin architecture, a
	 *         second argument of options is a convenient way to accept inputs
	 *         from your plugin's caller.
	 */
	constructor(player, options)
	{
		// The parent class will add player under this.player
		super(player);

		this.options = videojs.mergeOptions(defaults, options);

		player.on("vmap-ready", () => { this.handle(player.vmap); });
	}

	handle(vmap)
	{
		this.vmap = vmap;

		const posterTag = xPath.ext(vmap, "Poster").iterateNext();

		const poster = posterTag.textContent.replace(/https?:/, "");

		logger(`Setting poster image to ${poster}`);
		this.player.poster(poster);
	}
}

videojs.registerPlugin("vmapTagParserPoster", Poster);

export default Poster;
