import videojs from "video.js";
import "./Plugin.scss";
import AdDisplayContainer from "./components/AdDisplayContainer";
import AdBreakTimeRemaining from "./components/AdBreakTimeRemaining";
import xPath from "@utilities/XPath";
import { parseUrl, createUrl, isUrl } from "@utilities/Url";
import Cache from "@utilities/Cache";
import Timer from "@utilities/Timer";
import { isObject } from "@utilities/TypeChecks";

/*
***Important general information***
* Not all platform are equal. Apple mobile devices do things differently then desktop and android
* so we need to handle them differently.
* So what is different?

** Desktop/android: These two allow for multiple video tags to be on top of one another
** which allows us to just add a video tag to the ad container and play ads while the background content
** continues to play. This is how the plugin is expected it to work

** iPhone: Native fullscreen for iPhone can not play 2 videos at once. This means that in order
** to support fullscreen the ad needs to replace the contents of the base player instead of just covering
** the base player. Because of restoreCustomPlaybackStateOnAdBreakComplete we can let the SDK handle the
** transition from ad and normal content, though we need to seek to a different time then they do. setupIOSSeekingEvent will
** interrupt the IMA seek and seek to the timestamp we want.
** There are other things to consider for handling iPhone but the most difference is the content swapping.

** iPad: iPad allows for 2 videos to be loaded but for some reason the IMA SDK pauses the base player and will
** pause the ad if you try and start the base content while the ad is playing. So the main things to consider are
** the control bar and seeking after an ad has ended. The control bar is tied to the base player so when the
** content is paused the play button updated. playButtonOverride is used to change the play button to match the ad
** and not base player. when an ad completes or errors we will seek forward similar to how iPhone is handled.
*/

const logger = videojs.createLogger("ImaAds");

const iOS = videojs.browser.IS_IOS;
const iPad = videojs.browser.IS_IPAD;
const iPhone = iOS && !iPad;
const playStates = {
	PLAYING: "play",
	PAUSED: "pause"
};
const headerBiddingBlacklist = [];

// TODO: create enum or map for common google errors

function ImaAds(options)
{
	if (!options.enabled) return;

	const google = window.google;
	const apstag = window.apstag;

	if (options.logMetadata)
	{
		logMetadataSetup(this);
	}

	let headerBiddingEnabled = false;

	if (!options.disableApsHeaderBidding && typeof apstag !== "undefined")
	{
		apstag.init({ pubID: "6d74a82d-4d86-4019-a5e4-0b2d8ca66595", videoAdServer: "DFP" });
		headerBiddingEnabled = true;
	}

	// general
	const player = this;
	const MAX_AD_DURATION_IN_SECONDS = 30;
	const MIN_AD_DURATION = 6;
	const SECONDS_OF_NO_METADATA = 10;
	const DEFAULT_PREROLL_TIMEOUT_MINS = 5;

	let inAd_ = false;
	let inAdBreak_ = false;
	let isAdPaused_ = true; // adsManager doesnt have a .paused() so we'll just track it ourselves.
	let preAdPlaybackRate;
	let numberOfFailedAdsInaRow = 0;
	let preAdBreakTime;
	let currentAdNumber = 1; // increments after ad starts
	let currentBreakNumber = 0; // incrments when break starts

	// There is a non zero percent chance the player misses metadata.
	// Normally this is fine UNLESS that metadata that was missed is used to
	// end the ad break early. This would cause the player to remain in the
	// ad break state for longer then it should. Lets just set a 10 sec timer
	// and end the ad break if we dont see any new metadata for 10 seconds.
	const metadataTimer = new Timer(1, 1, handleMetadataTimerTick.bind(this));
	const adBreakTimer = new Timer(1, 1, handleTick.bind(this));

	const adContainer = new AdDisplayContainer(player);
	player.addChild(adContainer);
	const remainingTimeDisplay = new AdBreakTimeRemaining(player, adBreakTimeRemaining, options);
	player.addChild(remainingTimeDisplay);
	const videoElement = player.tech_.el_;

	// prerolls
	let prerollsCompleted = false;
	let prerollsStarted = false;

	// retry timout
	let retryTimeoutInSeconds = 1;
	let lastRequestEpoch = 0;
	const MAX_AD_RETRY_SECONDS = 120;

	// rewind timeout
	let lastAdPlayedTS = 0;
	let lastAdEpochTS = 0;
	const REWIND_TIMEOUT_MILLISECONDS = 300000; // 5 mins

	// ios
	let preAdTime;
	let preAdSrc;
	const adsRenderingSettings = new google.ima.AdsRenderingSettings();
	// eslint-disable-next-line id-length
	adsRenderingSettings.restoreCustomPlaybackStateOnAdBreakComplete = true;

	// ima
	const adDisplayContainer = new google.ima.AdDisplayContainer(
		adContainer.el_,
		videoElement
	);
	const adsLoader = new google.ima.AdsLoader(adDisplayContainer);
	let adsRequest = null;
	let adsManager = null;

	// controls
	let volume = player.volume();
	let muted = player.muted();
	const playerVolume = player.volume.bind(player);
	const playerMuted = player.muted.bind(player);
	const playerPlay = player.play.bind(player);
	const playerPause = player.pause.bind(player);
	const playerPaused = player.paused.bind(player);

	if (iPhone)
	{
		adDisplayContainer.initialize();
		// TODO: I'm not a fan of starting the broadcast before playing the prerolls but this
		// simplifies the 1000 hoops you have to jump through to make auto play work on iphone.
		// Not 100 sure what the "proper" way is but it certainly isnt this.
		videoElement.addEventListener("playing", function (event)
		{
			event.preventDefault();
			preAdSrc = videoElement.src;
			startPrerolls();
		}, { once: true });
	}
	else
	{
		attachControls();
	}

	const prerollData = {
		tags: [],
		count: 0,
		totalWeight: 0,
	};
	const midrollData = {
		tags: [],
		totalWeight: 0,
	};

	adsLoader.addEventListener(
		google.ima.AdErrorEvent.Type.AD_ERROR,
		onAdError,
		false
	);

	player.on("vmap-ready", () =>
	{
		prerollHandleVmap(player.vmap, prerollData);
		midrollHandleVmap(player.vmap, midrollData);
	});

	player.on("metadata", handleMetadata);

	player.one("prerollsCompleted", onPrerollsCompleted);

	player.on("play", () =>
	{
		if (inAdBreak_)
		{
			logger.debug("Play during adbreak");
			adBreakTimer.start();
			metadataTimer.start();
		}
	});

	player.on("pause", () =>
	{
		if (inAdBreak_)
		{
			// I know this looks a bit weird. The goal
			// is to prevent the IMA SDK from stopping the
			// ad break timer on iPad when it gets auto paused.
			// allows for a much smoother count down.
			if (!iPad || (iPad && !inAd_))
			{
				logger.debug("Paused during adbreak");
				adBreakTimer.stop();
				metadataTimer.stop();
			}

		}
	});

	window.addEventListener("resize", function ()
	{
		if (adsManager)
		{
			const width = videoElement.clientWidth;
			const height = videoElement.clientHeight;
			adsManager.resize(width, height, google.ima.ViewMode.NORMAL);
		}
	});

	function adBreakTimeRemaining()
	{
		return adBreakTimer.remainingTime();
	}

	function handleTick()
	{
		const remainingTime = adBreakTimer.remainingTime();
		logger.debug(`adBreakTimer tick. Time remaining: ${Math.round(remainingTime)}`);

		if (
			iPhone
			&& !inAd_
			&& Math.abs(
				player.currentTime() - (preAdTime + adBreakTimer.elapsedTime())
			) > 1
		)
		{
			logger.debug(
				`Handle Tick: Updating currentTime to preAdTime (${preAdTime}) + runningAdTime (${adBreakTimer.elapsedTime()})`
			);
			player.currentTime(preAdTime + adBreakTimer.elapsedTime());
		}

		if (remainingTime <= 0)
		{
			endAdBreak();
		}
		else
		{
			remainingTimeDisplay.update();
		}
	}

	function handleMetadataTimerTick()
	{
		const remainingTime = metadataTimer.remainingTime();
		logger.debug("Metadata Timer Tick:", Math.round(remainingTime));
		if (remainingTime <= 0)
		{
			endAdBreak();
		}
	}

	function logMetadataSetup(videoPlayer)
	{
		videoPlayer.on("metadata", (event, data) =>
		{
			if (
				Array.isArray(data) &&
				data.length >= 2 &&
				data[0] === "onVolarData"
			)
			{
				console.log(data[1]);
			}
		});
	}

	function setupIOSSeekingEvent(identifier)
	{
		// iPhone uses the "seeking" event to interrupt the IMA SDK's seek so, we can seek where we want.
		videoElement.addEventListener(
			"seeking",
			() =>
			{
				logger.debug(
					`${identifier}: Updating currentTime to preAdTime (${preAdTime}) + runningAdTime (${adBreakTimer.elapsedTime()})`
				);
				player.currentTime(preAdTime + adBreakTimer.elapsedTime());

				lastAdPlayedTS = Math.floor(player.currentTime());
				lastAdEpochTS = Date.now();
			},
			{ once: true }
		);
	}

	function setupIPADSeekingEvent(identifier)
	{
		// similar to ios we need to seek after the ad but since
		// we arent swapping content theres a chance that the content
		// starts playing before we start seeking which means we can see
		// metadata we shouldn't have causing an inconsistent countdown
		videoElement.addEventListener("seeked", () =>
		{
			player.play();
			lastAdPlayedTS = Math.floor(player.currentTime());
			lastAdEpochTS = Date.now();
		}, { once: true });

		logger.debug(
			`${identifier}: Updating currentTime to preAdTime (${preAdTime}) + runningAdTime (${adBreakTimer.elapsedTime()})`
		);
		player.currentTime(preAdTime + adBreakTimer.elapsedTime());
	}

	function playButtonOverride(state)
	{
		if (state === playStates.PLAYING)
		{
			player.controlBar.playToggle.removeClass("vjs-paused");
			player.controlBar.playToggle.addClass("vjs-playing");
		}
		else if (state === playStates.PAUSED)
		{
			player.controlBar.playToggle.addClass("vjs-paused");
			player.controlBar.playToggle.removeClass("vjs-playing");
		}
	}

	function destroyAd()
	{
		if (adsManager)
		{
			adsManager.destroy();
			adsManager = null;
			player.ads.ad = null;
		}
		adsLoader.contentComplete();
	}

	function startPrerolls()
	{
		if (prerollsStarted) return;

		prerollsStarted = true;
		if (prerollData.count === 0
			|| Date.now() - Cache.get("lastpreroll", 0) < prerollData.timeout)
		{
			player.trigger("prerollsCompleted");
			return;
		}

		adsLoader.addEventListener(
			google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
			onPrerollAdsManagerLoaded
		);

		adsLoader.addEventListener(
			google.ima.AdErrorEvent.Type.AD_ERROR,
			onPrerollAdComplete
		);

		playPreroll();
	}

	function playPreroll()
	{
		if (prerollData.count > 0 && prerollData.tags.length > 0)
		{
			prerollData.count--;
			requestAd(prerollData, MAX_AD_DURATION_IN_SECONDS);
		}
		else
		{
			player.trigger("prerollsCompleted");
		}
	}

	function onPrerollAdsManagerLoaded(adsManagerLoadedEvent)
	{
		adsManager = adsManagerLoadedEvent.getAdsManager(videoElement, adsRenderingSettings);
		player.ads.ad = adsManager;

		adsManager.addEventListener(
			google.ima.AdEvent.Type.ALL_ADS_COMPLETED,
			onPrerollAdComplete
		);

		adsManager.addEventListener(
			google.ima.AdErrorEvent.Type.AD_ERROR,
			onPrerollAdComplete
		);

		adsManager.addEventListener(
			google.ima.AdEvent.Type.PAUSED,
			() =>
			{
				playButtonOverride(playStates.PAUSED);
				isAdPaused_ = true;
			}
		);

		adsManager.addEventListener(
			google.ima.AdEvent.Type.RESUMED,
			() =>
			{
				playButtonOverride(playStates.PLAYING);
				isAdPaused_ = false;
			}
		);

		adsManager.addEventListener(
			google.ima.AdEvent.Type.STARTED,
			() =>
			{
				isAdPaused_ = false;
				playButtonOverride(playStates.PLAYING);
				player.trigger("seekingdisabled");
				player.addClass("vjs-ima-ads");
				if (!iPhone)
				{
					adContainer.addClass("blackout");
				}
				adContainer.show();
			}
		);

		const width = videoElement.clientWidth;
		const height = videoElement.clientHeight;
		try
		{
			adsManager.init(width, height, google.ima.ViewMode.NORMAL);
			adsManager.start();
		}
		catch (adError)
		{
			// Play the video without ads, if an error occurs
			logger(adError);
			logger.debug("AdsManager could not be started");
		}
	}

	function onPrerollAdComplete()
	{
		isAdPaused_ = true;

		destroyAd();

		Cache.set("lastpreroll", Date.now());
		playPreroll();
	}

	function onPrerollsCompleted()
	{
		logger.debug("Prerolls Completed");

		// remove preroll listeners
		adsLoader.removeEventListener(
			google.ima.AdErrorEvent.Type.AD_ERROR,
			onPrerollAdComplete
		);

		adsLoader.removeEventListener(
			google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
			onPrerollAdsManagerLoaded
		);

		// add midroll adsmanager listener
		adsLoader.addEventListener(
			google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
			onAdsManagerLoaded,
			false
		);

		adContainer.hide();
		player.removeClass("vjs-ima-ads");
		adContainer.removeClass("blackout");
		player.trigger("seekingenabled");

		prerollsCompleted = true;

		if (iPhone)
		{
			videoElement.src = preAdSrc;
		}

		player.play();
	}

	function onAdsManagerLoaded(adsManagerLoadedEvent)
	{
		// Instantiate the AdsManager from the adsLoader response and pass it the video element
		adsManager = adsManagerLoadedEvent.getAdsManager(videoElement, adsRenderingSettings);
		player.ads.ad = adsManager;

		// reset whenever we get a successful ad load.
		numberOfFailedAdsInaRow = 0;
		retryTimeoutInSeconds = 1;

		if (!inAdBreak_)
		{
			// If we ended the break while the ad was loading
			// we need to clean it up so it doesnt play.
			// there are no methods on adsLoader or adsRequest
			// to cancel a request.
			destroyAd();
			inAd_ = false;
			return;
		}

		adsManager.addEventListener(
			google.ima.AdErrorEvent.Type.AD_ERROR,
			onAdError
		);

		adsManager.addEventListener(
			google.ima.AdEvent.Type.PAUSED,
			onPause
		);

		// weird bug on android tablet. If the ad is tapped and the user
		// is redirected to the ad page, upon returning to the player
		// the ad will remain paused but the broadcast will start playing.
		// maybe the google.ima.AdEvent.Type.PAUSED event is too slow and
		// not pausing in time? android seems to stop all processes for
		// inactive tabs so we might be changing tabs before the player is paused.
		adsManager.addEventListener(
			google.ima.AdEvent.Type.CLICK,
			onPause
		);

		adsManager.addEventListener(
			google.ima.AdEvent.Type.RESUMED,
			onResume
		);

		adsManager.addEventListener(
			google.ima.AdEvent.Type.STARTED,
			onAdStart
		);

		adsManager.addEventListener(
			google.ima.AdEvent.Type.ALL_ADS_COMPLETED,
			onAdComplete
		);


		const width = videoElement.clientWidth;
		const height = videoElement.clientHeight;

		// We arent preloading for later so
		// we can just start the ad when the
		// manager is ready
		try
		{
			adsManager.init(width, height, google.ima.ViewMode.NORMAL);
			adsManager.start();
		}
		catch (adError)
		{
			// Play the video without ads, if an error occurs
			logger(adError);
			logger.debug("AdsManager could not be started");
			player.play();
		}
	}

	function onAdStart()
	{
		if (player.paused() && !iOS)
		{
			adsManager.pause();
		}

		if (iOS)
		{
			// iphone and ipad wont see metadata in ads
			// so we dont want the timer ending the ad break.
			metadataTimer.reset();
		}

		if (!iOS)
		{
			adContainer.addClass("blackout");
			const vol = muted ? 0 : volume;
			adsManager.setVolume(vol);
		}

		if (iPad)
		{
			adContainer.addClass("blackout");
			playButtonOverride(playStates.PLAYING);
		}

		currentAdNumber++;
	}

	function onAdComplete()
	{
		inAd_ = false;
		if (iOS)
		{
			if (iPad)
			{
				setupIPADSeekingEvent("Ad Complete");
			}
			else
			{
				setupIOSSeekingEvent("Ad Complete");
			}

			metadataTimer.reset().setDuration(SECONDS_OF_NO_METADATA).start();
		}
		else
		{
			lastAdPlayedTS = Math.floor(player.currentTime());
			lastAdEpochTS = Date.now();
		}

		destroyAd();

		if (!iPad)
		{
			player.play();
		}
	}

	function onAdError(adErrorEvent)
	{
		inAd_ = false;

		numberOfFailedAdsInaRow++;
		if (numberOfFailedAdsInaRow >= 3)
		{
			adContainer.removeClass("blackout");
		}

		retryTimeoutInSeconds = Math.min(
			MAX_AD_RETRY_SECONDS,
			retryTimeoutInSeconds * 2
		);

		destroyAd();

		if (!adErrorEvent.getError()) return;

		const e = adErrorEvent.getError().getInnerError() || adErrorEvent.getError();
		logger("Ad error:", e.getErrorCode(), e.getMessage());

		if (e.getType() === "adPlayError" && iOS)
		{
			if (iPad)
			{
				setupIPADSeekingEvent("Ad Error");
			}
			else
			{
				setupIOSSeekingEvent("Ad Error");
			}

			// eslint-disable-next-line no-magic-numbers
			if (e.getErrorCode() !== 1205)
			{
				// error 1205: "The browser prevented playback initiated without user interaction."
				// This happens when the player is paused as IMA is transitioning the video src to the ad.
				// I know it says "without user interaction" but the user pressing the pause button makes this happen.
				// https://developers.google.com/interactive-media-ads/docs/sdks/html5/client-side/reference/js/google.ima.AdError
				player.play();
			}
		}
	}

	function onPause()
	{
		isAdPaused_ = true;

		if (!iOS)
		{
			videoElement.pause();
		}

		if (iPad)
		{
			playButtonOverride(playStates.PAUSED);
			adBreakTimer.stop();
		}
	}

	function onResume()
	{
		isAdPaused_ = false;

		if (!iOS)
		{
			videoElement.play();
		}

		if (iPad)
		{
			playButtonOverride(playStates.PLAYING);
			adBreakTimer.start();
		}
	}

	function tryToPlayAnAd(ad, duration)
	{
		const now = Date.now();
		if (
			inAd_
			|| !inAdBreak_
			|| now - lastRequestEpoch < retryTimeoutInSeconds * 1000
			|| ad.tags.length === 0
		)
		{
			return;
		}

		if (duration <= MIN_AD_DURATION)
		{
			endAdBreak();
			return;
		}

		inAd_ = true;
		lastRequestEpoch = Date.now();

		requestAd(ad, duration);
	}

	async function requestAd(ad, duration = MAX_AD_DURATION_IN_SECONDS)
	{
		try
		{
			const selectedTag = selectAdTag(ad);
			const tag = await setDynamicParams(selectedTag, duration);

			adsRequest = new google.ima.AdsRequest();
			adsRequest.adTagUrl = tag;
			adsLoader.requestAds(adsRequest);
		}
		catch (error)
		{
			inAd_ = false;
			logger.error(error);
		}
	}

	async function setDynamicParams(adTag, duration)
	{
		const urlDetails = parseUrl(adTag);
		urlDetails.parameters.max_ad_duration = Math.floor(duration * 1000);
		let paramsDict = {};
		let custParams = urlDetails.parameters.cust_params || "";

		const bid = await fetchBids(adTag);
		if (bid)
		{
			custParams += custParams ? `&${bid}` : bid;
		}

		if (prerollsCompleted)
		{
			paramsDict.midroll_ad_order = currentAdNumber;
			paramsDict.midroll_break_order = currentBreakNumber;
		}

		const metadata = player.adMetadata;
		if (metadata)
		{
			paramsDict = Object.assign( {}, metadata, paramsDict);
		}

		custParams += buildParams(paramsDict);

		if (custParams !== "")
		{
			urlDetails.parameters.cust_params = encodeURIComponent(custParams);
		}

		return createUrl(urlDetails);
	}

	function buildParams(dict)
	{
		let params = "";

		if (!isObject(dict)) return params;

		for (const [key, value] of Object.entries(dict))
		{
			if (value === null) continue;

			params += `&${key}=${value}`;
		}
		return params;
	}

	function shouldFetchBids(adTag)
	{
		const blacklisted = headerBiddingBlacklist.some(adUnitName => adTag.includes(adUnitName));
		return !blacklisted && headerBiddingEnabled;
	}

	// apstag.fetchBids is a network call
	// eslint-disable-next-line require-await
	async function fetchBids(adTag)
	{
		if (shouldFetchBids(adTag))
		{
			return new Promise((resolve) =>
			{
				apstag.fetchBids({
					slots: [{
						slotID: "Hudl_Video",
						mediaType: "video"
					}],
					timeout: 2000
				}, function (bids)
				{
					if (bids.length === 0)
					{
						logger.debug("No Bids received");
						resolve("");
					}
					else
					{
						logger.debug(bids);
						resolve(bids[0].qsParams);
					}
				});
			});
		}

		return "";
	}

	function attachControls()
	{
		player.volume = setVolume;
		player.muted = setMuted;
		player.play = play;
		player.pause = pause;
		player.paused = paused;
	}

	function setVolume(value)
	{
		if (typeof value === "undefined")
		{
			return volume;
		}

		// Hotkeys can cause volume to be >1 and <0. We dont want that.
		if (value > 1)
		{
			volume = 1;
		}
		else if (value < 0)
		{
			volume = 0;
		}
		else
		{
			volume = value;
		}

		if (adsManager) adsManager.setVolume(volume);
		playerVolume(volume);

		// On iOS this is triggered automatically
		if (!iOS)
		{
			player.trigger("volumechange");
		}

		return volume;
	}

	function setMuted(value)
	{
		if (typeof value === "undefined")
		{
			return muted;
		}

		muted = value;

		if (adsManager) adsManager.setVolume(value ? 0 : volume);
		if (!inAd_ || iOS) playerMuted(value);

		// On iOS this is triggered automatically
		if (!iOS)
		{
			player.trigger("volumechange");
		}

		return muted;
	}

	function play()
	{
		if (!prerollsStarted)
		{
			adDisplayContainer.initialize();
			startPrerolls();
			return;
		}

		if (adsManager)
		{
			isAdPaused_ = false;
			adsManager.resume();
		}
		else if (prerollsCompleted)
		{
			playerPlay();
		}
	}

	function pause()
	{
		if (adsManager)
		{
			isAdPaused_ = true;
			adsManager.pause();
		}
		else if (prerollsCompleted)
		{
			playerPause();
		}
	}

	function paused()
	{
		if (!prerollsCompleted)
		{
			return isAdPaused_;
		}

		// iPad base player is paused during ads
		if (iPad && inAd_)
		{
			return isAdPaused_;
		}

		return playerPaused();
	}

	function selectAdTag(ad)
	{
		const tags = ad.tags;
		if (tags.length === 0)
		{
			// This shouldnt be called if theres no tags but just in case
			return "";
		}

		let sumWeight = ad.totalWeight;
		const selected = Math.random() * sumWeight;
		let selectedSource = null;

		for (let i = 0; i < tags.length; i++)
		{
			sumWeight -= tags[i].weight;
			if (sumWeight <= selected)
			{
				selectedSource = tags[i];
				break;
			}
		}

		return selectedSource.uri;
	}

	function createUri(vmapADTag)
	{

		// TODO: vpa=click vpmute=1/0

		const urlDetails = parseUrl(vmapADTag);
		urlDetails.parameters.env = "vp";

		let url = window.location.href;
		if (url.indexOf("localhost") >= 0)
		{
			url = "https://blueframetech.com";
		}
		urlDetails.parameters.url = url;
		// AdX / AdSense expect these two parameters
		urlDetails.parameters.output = "xml_vast4";
		urlDetails.parameters.sdkv = "h.1.0.0";

		const rdid = Cache.set("rdid", Cache.get("rdid", Cache.guid()));
		urlDetails.parameters.rdid = rdid;

		urlDetails.parameters.is_lat =
			navigator.doNotTrack === "unspecified"
				? 1
				: navigator.doNotTrack || window.doNotTrack || 0;
		urlDetails.parameters.idtype = "idfa";
		return createUrl(urlDetails);
	}

	function prerollHandleVmap(vmap, data)
	{
		const contentTag = xPath.ext(vmap, "/Content").iterateNext();
		if (contentTag && !contentTag.textContent)
		{
			// The broadcast is upcoming. Don't play pre-rolls
			return;
		}

		const adBreaks = xPath(
			vmap,
			"//Extensions/Extension[@type='VolarAdBreakExtension']"
		);
		if (adBreaks.length === 0)
		{
			// no ad breaks
			return;
		}

		let preroll;
		let nextAd = adBreaks.iterateNext();
		while (nextAd)
		{
			const type = xPath(nextAd, "//Roll").iterateNext().textContent;
			if (type === "pre-roll")
			{
				preroll = nextAd;
				break;
			}
			nextAd = adBreaks.iterateNext();
		}

		if (!preroll)
		{
			logger.debug("No Prerolls.");
			return;
		}

		const countTag = xPath(preroll, "//Count").iterateNext();
		data.count = 1;
		if (countTag)
		{
			data.count = parseInt(countTag.textContent);
		}

		const adSources = xPath(preroll, "//AdSource");
		let adsrc = adSources.iterateNext();
		while (adsrc)
		{
			const weight = parseFloat(adsrc.getAttribute("weight")) || 1;
			const uri = xPath(adsrc, "//AdTagURI").iterateNext().textContent;
			if (isUrl(uri))
			{
				data.totalWeight += weight;
				data.tags.push({
					weight: weight,
					uri: createUri(uri),
				});
			}
			else
			{
				logger.warn("invalid url:", uri);
			}
			adsrc = adSources.iterateNext();
		}

		const miscTag = xPath.ext(vmap, "MiscInfo").iterateNext();
		data.timeout =
			(miscTag.getAttribute("prerollTimeout") || DEFAULT_PREROLL_TIMEOUT_MINS) * 60 * 1000;
	}

	function midrollHandleVmap(vmap, data)
	{
		const contentTag = xPath.ext(vmap, "/Content").iterateNext();
		if (contentTag && !contentTag.textContent)
		{
			// The broadcast is upcoming. Don't play pre-rolls
			return;
		}

		const adBreaks = xPath(
			vmap,
			"//Extensions/Extension[@type='VolarAdBreakExtension']"
		);
		if (adBreaks.length === 0)
		{
			// no ad breaks
			return;
		}

		let midroll;
		let nextAd = adBreaks.iterateNext();
		while (nextAd)
		{
			const type = xPath(nextAd, "//Roll").iterateNext().textContent;
			if (type === "mid-roll")
			{
				midroll = nextAd;
				break;
			}
			nextAd = adBreaks.iterateNext();
		}

		if (!midroll)
		{
			logger.debug("No Midrolls.");
			// There might be other listeners that should be turned off
			// but this seems like the simpliest way to just cut off
			// all ads if we dont have a tag.
			player.off("metadata", handleMetadata);
			return;
		}

		const adSources = xPath(midroll, "//AdSource");
		let adsrc = adSources.iterateNext();
		while (adsrc)
		{
			const weight = parseFloat(adsrc.getAttribute("weight")) || 1;
			const uri = xPath(adsrc, "//AdTagURI").iterateNext().textContent;
			if (isUrl(uri))
			{
				data.totalWeight += weight;
				data.tags.push({
					weight: weight,
					uri: createUri(uri),
				});
			}
			else
			{
				logger.warn("invalid url:", uri);
			}
			adsrc = adSources.iterateNext();
		}
	}

	function beginAdBreak(duration)
	{
		if (
			duration <= MIN_AD_DURATION
			|| (player.currentTime() < lastAdPlayedTS && Date.now() - lastAdEpochTS < REWIND_TIMEOUT_MILLISECONDS)
		)
		{
			return;
		}

		preAdTime = player.currentTime();
		adBreakTimer.reset().setDuration(duration).start();
		metadataTimer.reset().setDuration(SECONDS_OF_NO_METADATA).start();
		remainingTimeDisplay.update();

		if (inAdBreak_) return;

		preAdBreakTime = player.currentTime();
		preAdPlaybackRate = player.playbackRate();

		muted = playerMuted();
		volume = playerVolume();

		if (!iOS)
		{
			playerMuted(true);
		}
		player.playbackRate(1);
		player.trigger("seekingdisabled");
		player.addClass("vjs-ima-ads");
		adContainer.show();
		remainingTimeDisplay.show();
		currentBreakNumber++;

		inAdBreak_ = true;
	}

	function endAdBreak()
	{
		if (!inAdBreak_) return;

		if (adsManager)
		{
			adsManager.discardAdBreak();

			// iPhone runs into issues if we cut the ad early which
			// prevents the src swap and seeking logic from working
			// so instead of cutting early, we'll just let the ad
			// play and resolve as it normally does since ad shouldn't be
			// much longer then the break. Timer will linger
			// for a few seconds but I don't find that a big deal.
			if (iPhone) return;

			onAdComplete();
		}

		inAdBreak_ = false;

		adBreakTimer.stop();
		metadataTimer.stop();
		remainingTimeDisplay.hide();
		adContainer.hide();
		player.playbackRate(preAdPlaybackRate);
		player.removeClass("vjs-ima-ads");
		adContainer.removeClass("blackout");
		player.trigger("seekingenabled");
		currentAdNumber = 1;
		if (!iPhone)
		{
			playerMuted(muted);
		}
		player.trigger("adbreakended", {
			startTime: preAdBreakTime,
			endTime: player.currentTime(),
		});
	}

	function handleMetadata(event, data)
	{
		if (player.paused()) return;

		if (
			Array.isArray(data) &&
			data.length >= 2 &&
			data[0] === "onVolarData"
		)
		{
			onVolarData(data[1]);
		}
	}

	function onVolarData(data)
	{
		if (data.event === "adBreakStart")
		{
			if (data.offset === 0)
			{
				beginAdBreak(data.duration);
				tryToPlayAnAd(midrollData, data.duration);
			}
		}
		else if (data.event === "adBreakEnd")
		{
			if (data.offset >= 1)
			{
				beginAdBreak(data.offset);
				tryToPlayAnAd(midrollData, data.offset);
			}
			else
			{
				endAdBreak();
			}
		}
	}

	function inAd()
	{
		return inAd_;
	}

	// TODO: clean this up. right now its there bec the source handler uses it for ios
	// Other plugins in the player may use these and
	// it seems its standard convention to use player.ads to
	// interact with ad plugins.
	const adPlugin = {
		player: player,
		adContainer: adContainer,
		ad: null,
		inAdBreak: inAd,
	};
	player.ads = adPlugin;
}

// Register the plugin with video.js.
videojs.registerPlugin("imaAds", ImaAds);
