import videojs from "video.js";

function Snowplow()
{
	const player = this;
	const video = player.tech_.el_;

	player.on("adrewind", (evt, data) =>
	{
		const event = new CustomEvent("adrewind", { detail: data });
		video.dispatchEvent(event);
	});
}

// Register the plugin with video.js.
videojs.registerPlugin("snowplow", Snowplow);
export default Snowplow;
