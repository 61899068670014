import videojs from "video.js";
import "./Plugin.scss";
import StatsForNerdsMenuItem from "./components/StatsForNerdsMenuItem";
import StatsForNerdsPluginButton from "./components/StatsForNerdsPluginButton";
import StatsForNerdsOverlay from "./components/StatsForNerdsOverlay";

const logger = videojs.createLogger("InfoMenuOptionStatsForNerds");
const Plugin = videojs.getPlugin("plugin");

// Default options for the plugin.
const defaults = {
	open: false
};

/**
 * An advanced Video.js plugin. For more information on the API
 *
 * See: https://blog.videojs.com/feature-spotlight-advanced-plugins/
 */
class StatsForNerds extends Plugin
{

	/**
	 * Create a StatsForNerds plugin instance.
	 *
	 * @param  {Player} player
	 *         A Video.js Player instance.
	 *
	 * @param  {Object} [options]
	 *         An optional options object.
	 *
	 *         While not a core part of the Video.js plugin architecture, a
	 *         second argument of options is a convenient way to accept inputs
	 *         from your plugin's caller.
	 */
	constructor(player, options)
	{
		// The parent class will add player under this.player
		super(player);

		this.options = videojs.mergeOptions(defaults, options);

		player.ready(() =>
		{
			this.overlay = new StatsForNerdsOverlay(
				player,
				videojs.mergeOptions(options, {name: "StatsForNerdsOverlay"})
			);
			player.addChild(this.overlay);

			if (this.options.open)
			{
				this.overlay.show();
			}

			if (player.controlBarButtonInfo)
			{
				// If the ControlBarButtonInfo plugin is installed, add this as
				// a menu option
				const menu = player.controlBar.getChild("InfoButton").getChild("InfoMenu");
				if (menu)
				{
					menu.addItem(new StatsForNerdsMenuItem(player, options));
				}
				else
				{
					logger.error("InfoButtonPlugin did not initialize properly.");
					player.controlBar.addChild(new StatsForNerdsPluginButton(player, options), options);
				}
			}
			else
			{
				// If not, create a button on the control bar for this
				player.controlBar.addChild(new StatsForNerdsPluginButton(player, options), options);
			}
		});
	}
}

// Register the plugin with video.js.
videojs.registerPlugin("infoMenuOptionStatsForNerds", StatsForNerds);

export default StatsForNerds;
