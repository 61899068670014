import videojs from "video.js";

const Component = videojs.getComponent("Component");

// Default options for the plugin.
const defaults = {};

/**
 * @param {Player|Object} player
 * @param {Object=} options
 * @extends Component
 * @class ClipThumbnail
 */
class ClipThumbnail extends Component
{
	constructor(player, options = {})
	{
		super(player, options);
		this.player = player;
		this.options = videojs.mergeOptions(defaults, options);

		this.el_.innerHTML = this.options.buttonText;
	}

	createEl(tag, props = {}, attributes = {})
	{
		return Component.prototype.createEl.call(
			this,
			"canvas",
			videojs.mergeOptions({
				className: this.buildCSSClass(),
				// This is the "canvas width" and height, not the display
				// width and height. Trust me, it
				width: 1920,
				height: 1080
			}, props),
			attributes
		);
	}

	/**
	 * Builds the default DOM `className`.
	 *
	 * @return {string}
	 *		The DOM `className` for this object.
	 */
	buildCSSClass()
	{
		return `vjs-clip-thumbnail ${super.buildCSSClass()}`;
	}
}

ClipThumbnail.prototype.controlText_ = "Thumbnail";

Component.registerComponent("clipThumbnail", ClipThumbnail);

export default ClipThumbnail;
