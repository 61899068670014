import Evented from "./Evented";

/**
 * Timer.js by Steven Barnett (@stevendesu)
 * Adds some much-needed functionality to setInterval() without
 * the use of third-party libraries (like jQuery)
 *
 * This class was copied from the old player to give us methods like
 * `timer.remainingTime()` that are not normally available. I copied the old
 * implementation instead of creating a new one to preserve the old
 * functionality (if there are any bugs, they will be carried forward)
 *
 * Adjusted to match the interface of VVCountdownTimer
 *    - Added "duration"
 *    - Added remainingTime()
 *    - Added elapsedTime()
 *    - Converted milliseconds to seconds
 *
 * Note (not sure if this is desired):
 *    If duration is not a multiple of interval, the timer will
 *    run beyond the duration to complete the last inerval.
 *    ex. Timer( 3, 10 ) will tick 4 times: 3, 6, 9, and 12
 *
 * Usage:
 *     var timer = new Timer(10, 1, function(){
 *         this.stop();
 *         console.log("Ten seconds has passed");
 *     });
 *     timer.start();
 *
 * Methods:
 *     start() - Start where you left off
 *     stop() - Stop the timer, remember your place
 *     reset() - Stop the timer, go back to 0
 *     getTime() - Return the number of seconds since the last "tick"
 *     remainingTime() - Return time until duration is hit
 *     elapsedTime() - Return time since last .reset()
 */
class Timer extends Evented
{
	constructor(intervalParam, durationParam, inBlock)
	{
		super();

		this.interval = intervalParam * 1000;
		this.duration = durationParam * 1000;
		this.inBlock = inBlock;
		this.origDuration = this.duration;
		this.block = () =>
		{
			const now = Date.now();
			this.duration -= now - this.lastTick;
			this.runtime += now - this.lastTick;
			this.lastTick = now;
			this.inBlock(this.duration / 1000);
			if (this.duration <= 0) this.stop();
		};
		this.startTime = 0;
		this.runtime = 0;
		this.clock = null;
		this.oddms = 0;
		this.isClockTimeout = false;
		// Fix any weird offset issues
		this.lastTick = 0;

		// Synonym
		this.resume = this.start;
	}

	setInterval(intervalParam)
	{
		this.interval = intervalParam * 1000;
		return this;
	}

	setDuration(durationParam)
	{
		this.duration = durationParam * 1000;
		return this;
	}

	setBlock(blockParam)
	{
		this.inBlock = blockParam;
		return this;
	}

	start()
	{
		if (this.clock === null)
		{
			this.startTime = Date.now();
			this.lastTick = this.startTime;
			if (this.oddms > 0)
			{
				this.clock = setTimeout(() =>
				{
					this.clock = setInterval(this.block, this.interval);
					this.isClockTimeout = false;
					this.block();
				}, this.interval - this.oddms);
				this.isClockTimeout = true;
			}
			else
			{
				this.clock = setInterval(this.block, this.interval);
			}
		}
		// Return "this" allows you start Timers inline with declaration:
		//    var myTimer = new Timer(1, 10).start();
		return this;
	}

	stop()
	{
		if (this.clock !== null)
		{
			const now = Date.now();
			this.oddms += now - this.startTime;
			this.oddms %= this.interval;
			if (this.isClockTimeout)
			{
				clearTimeout(this.clock);
			}
			else
			{
				clearInterval(this.clock);
			}
			this.clock = null;
		}
		return this;
	}

	reset()
	{
		if (this.clock !== null)
		{
			if (this.isClockTimeout)
			{
				clearTimeout(this.clock);
			}
			else
			{
				clearInterval(this.clock);
			}
			this.clock = null;
		}
		this.duration = this.origDuration;
		this.oddms = 0;
		this.runtime = 0;
		return this;
	}

	getTime()
	{
		if (this.clock === null)
		{
			return this.oddms / 1000;
		}
		else
		{
			const time = this.oddms + (Date.now() - this.startTime);
			return (time % this.interval) / 1000;
		}
	}

	remainingTime()
	{
		return (this.duration / 1000) - this.getTime();
	}

	elapsedTime()
	{
		return (this.runtime / 1000) + this.getTime();
	}

	running()
	{
		return this.clock !== null;
	}
}

export default Timer;
