// Analytics

import videojs from "video.js";
// import Hls from "hls.js";
import AwsClient from "./AwsClient";
import xPath from "@utilities/XPath";
import {parseUrl} from "@utilities/Url";
// import Geo from "@utilities/Geo";
import Cache from "@utilities/Cache";
import * as UAParser from "ua-parser-js";
import webMap from "./normalize/webMap";
import osMap from "./normalize/osMap";
import {version} from "../../../../package.json";
import TimeViewedTracker from "./TimeViewedTracker";


const logger = videojs.createLogger("VMAPTagParserStats");
const Plugin = videojs.getPlugin("plugin");

// Default options for the plugin.
const defaults = {
	enabled: true,
	caching: true,
	passthru: false,
	domain: "UNKNOWN",
	purchaseOrderNumber: null,
	// TODO: Instead of a batchSize, we should send as many messages as will fit
	// TODO: in the request. See:
	// http://stackoverflow.com/questions/4715415/base64-what-is-the-worst-possible-increase-in-space-usage
	batchSize: 20,
	maxQueueSize: 1000
};


/**
 * Extract the Stats tag from the VolarVMAP and handle it
 */
class StatsTagParser extends Plugin
{
	/**
	 * Create a StatsTagParser plugin instance.
	 *
	 * @param  {Player} player
	 *         A Video.js Player instance.
	 *
	 * @param  {Object} [options]
	 *         An optional options object.
	 *
	 *         While not a core part of the Video.js plugin architecture, a
	 *         second argument of options is a convenient way to accept inputs
	 *         from your plugin's caller.
	 */
	constructor(player, options)
	{
		// The parent class will add player under this.player
		super(player);

		// used to parse user agent and return device details
		const uaParser = new UAParser();
		this.platformInfo = uaParser.setUA(navigator.userAgent);
		this.browser = this.platformInfo.getBrowser();
		this.os = this.platformInfo.getOS();

		this.options = videojs.mergeOptions(defaults, options);

		this.GUID = Cache.set("guid", Cache.get("guid", Cache.guid()));

		// TODO: Support for other types of analytics
		// We currently only use Kinesis, but if we did start using alternate
		// analytics sources we might want something like this.kinesis.region
		// Alternatively, different types of stats should probably be different
		// plugins. Instead of "StatsTagParser" this would be "KinesisTagParser"
		this.streamName = null;
		this.region = null;
		this.key = null;
		this.secret = null;
		this.heartbeat = null;
		this.sendHeartbeat = true;
		this.messageQueue = [];
		this.firstload = false;
		this.firstplay = false;
		this.playerControls = true;
		this.playbackType = null;

		this.secondsBeforeInactive = 1800; // 30mins
		this.timeViewedTracker = new TimeViewedTracker(player);
		this.maxStagnantHeartbeats = 5;
		this.stagnantCounter = 0;

		this.boundHeartbeat = this.onHeartbeat.bind(this);

		this.hlsjs = null;
		// TODO: Add VHS support for m3u8download and tsdownload messages
		// While we aren't using VHS ourselves, so this isn't *that* important,
		// for completeness we should ensure that any plugin we write has
		// support for it - in case someone chooses to use our plugins outside
		// of our player.
		if (videojs.Html5Hlsjs)
		{
			videojs.Html5Hlsjs.addHook("beforeinitialize", (x, hlsjs) =>
			{
				this.hlsjs = hlsjs;

				// hlsjs.on(Hls.Events.LEVEL_LOADED, this.onLevelLoaded.bind(this));
				// hlsjs.on(Hls.Events.FRAG_LOADED, this.onFragLoaded.bind(this));

				// hlsjs.on(Hls.ErrorDetails.BUFFER_STALLED_ERROR, this.onBufferStalled.bind(this));
			});
		}

		if (this.options.enabled)
		{
			player.on("vmap-ready", () => { this.handle(player.vmap); });
			player.on("playing", () =>
			{
				const prevTimestamp = this.lastMessage.timestamp;
				const currentTime = Math.floor(Date.now() / 1000) - this.timeOffset;

				if (currentTime - prevTimestamp > this.secondsBeforeInactive)
				{
					this.firstload = false;
					this.firstplay = false;
					this.onFirstLoad();
					this.onFirstPlay();
				}
				else if (this.isStagnant())
				{
					this.stagnantCounter = 0;
					this.timeViewedTracker.start();
					setTimeout(this.boundHeartbeat, this.heartbeat);
				}
			});
		}
	}

	handle(vmap)
	{
		this.vmap = vmap;

		// We need to extract some information from **multiple** parts of the
		// VMAP to send on firstload:

		const customerTag = xPath.ext(vmap, "Customer").iterateNext();
		const siteTag = xPath.ext(vmap, "Site").iterateNext();
		const broadcastTag = xPath.ext(vmap, "Broadcast").iterateNext();
		const miscInfoTag = xPath.ext(vmap, "MiscInfo").iterateNext();
		const statsTag = xPath.ext(vmap, "Stats").iterateNext();
		const contentTag = xPath.ext(vmap, "Content").iterateNext();

		if (!customerTag || !siteTag || !broadcastTag || !miscInfoTag || !statsTag || !contentTag)
		{
			logger.warn("Required tag is missing...\n");
			logger.warn(`
				customerTag: ${!!customerTag}
				siteTag: ${!!siteTag}
				broadcastTag: ${!!broadcastTag}
				miscInfoTag: ${!!miscInfoTag}
				statsTag: ${!!statsTag}
				contentTag: ${!!contentTag}
			`);
			return;
		}

		const kinesisTag = xPath(statsTag, "//Kinesis").iterateNext();
		const dataTag = xPath(statsTag, "//Data").iterateNext();

		if (!kinesisTag)
		{
			logger.warn("No Kinesis info provided in VMAP");
			return;
		}

		// TODO: Status should always exist now but leaving the backup case for now.
		if (dataTag)
		{
			this.playbackType = dataTag.getAttribute("status");
		}
		else
		{
			this.playbackType = contentTag.getAttribute("type" === "live") ? "live" : "archived";
		}

		this.customerId = parseInt(customerTag.getAttribute("id"));
		this.siteId = parseInt(siteTag.getAttribute("id"));
		this.broadcastId = parseInt(broadcastTag.getAttribute("id"));

		this.broadcastUUID = miscInfoTag.getAttribute("uuid");
		this.phpSessionID = miscInfoTag.getAttribute("sid");
		this.timestamp = miscInfoTag.getAttribute("currentTime");
		this.timeOffset = Math.floor(Date.now() / 1000) - this.timestamp;

		this.streamName = kinesisTag.getAttribute("streamName");
		this.region = kinesisTag.getAttribute("region");
		const key = kinesisTag.getAttribute("key");
		const secret = kinesisTag.getAttribute("secret");
		this.heartbeat = parseInt(kinesisTag.getAttribute("heartbeat"));

		this.aws = new AwsClient(key, secret, this.region, "kinesis");

		this.onFirstLoad();
		this.player.one("playingcontent", this.onFirstPlay.bind(this));
	}

	onFirstLoad()
	{
		if (this.firstload) return;
		this.firstload = true;

		logger("***** FirstLoad *****");

		this.addMessage({
			type: "action",
			action: "load"
		});

		this.send();
	}

	onFirstPlay()
	{
		if (this.firstplay) return;
		this.firstplay = true;

		logger("***** FirstPlay *****");
		this.addMessage({
			type: "action",
			action: "firstplay"
		});

		this.send();

		// Start heartbeat timer
		this.timeViewedTracker.start();
		setTimeout(this.boundHeartbeat, this.heartbeat);
	}

	/*
	onLevelLoaded(evt, data)
	{
		const stats = data.stats;
		const timeOfRequest = stats.trequest;
		const latency = stats.tfirst - timeOfRequest;
		const downloadTime = stats.tload - timeOfRequest;
		const bytes = stats.total;

		const rendition = data.details.url.match(/cloudfront\.net\/[a-z0-9]+\/([^/]+)\/index\.m3u8/i);
		const bitrate = this.hlsjs.levels[data.level].bitrate;

		this.addMessage({
			action: "m3u8download",
			m3u8URL: data.details.url,
			bytesDownloaded: bytes,
			downloadTime: downloadTime,
			latency: latency,
			renditionDescriptor: (rendition ? rendition[1] : "") + "/" + bitrate,
			// TODO: Support for more status codes, location code, and last hop
			statusCode: "200",
			locationCode: null,
			lastHopASNumber: null
		});
	}
	*/

	/*
	onFragLoaded(evt, data)
	{
		const stats = data.stats;
		const timeOfRequest = stats.trequest;
		const latency = stats.tfirst - timeOfRequest;
		const downloadTime = stats.tload - timeOfRequest;
		const bytes = stats.total;

		const rendition = data.frag.baseurl.match(/cloudfront\.net\/[a-z0-9]+\/([^/]+)\/[a-z0-9_]+\.ts/i);
		const bitrate = this.hlsjs.levels[data.frag.level].bitrate;

		this.addMessage({
			action: "tsdownload",
			tsURL: data.frag._url,
			bytesDownloaded: bytes,
			downloadTime: downloadTime,
			latency: latency,
			segmentNumber: data.frag.sn,
			renditionDescriptor: (rendition ? rendition[1] : "") + "/" + bitrate,
			// TODO: Support for more status codes, location code, and last hop
			statusCode: "200",
			locationCode: null,
			lastHopASNumber: null
		});
	}
	*/

	/*
	onBufferStalled()
	{
		TODO: This function
	}
	*/

	onHeartbeat()
	{
		// Never send a heartbeat before firstplay as it confuses the server and screws up the data
		if (!this.firstplay) return;

		let timeViewed = Math.round(this.timeViewedTracker.getTimeViewed());
		this.stagnantCounter = timeViewed === 0 ? this.stagnantCounter + 1 : 0;
		if (this.isStagnant())
		{
			this.timeViewedTracker.reset();
			return;
		}

		const heartbeatInSeconds = this.heartbeat / 1000;
		if (timeViewed > heartbeatInSeconds) timeViewed = heartbeatInSeconds;
		if (timeViewed < 0) timeViewed = 0;

		// builds hearbeat message
		logger.debug("***** Heartbeat *****");
		this.addMessage({
			type: "heartbeat",
			heartbeatInterval: heartbeatInSeconds,
			timeViewed: timeViewed
		});

		// Reset this on heartbeat
		this.timeViewedTracker.reset().start();

		// Send on heartbeat
		this.send();

		// Restarts the timer
		setTimeout(this.boundHeartbeat, this.heartbeat);
	}

	isStagnant()
	{
		return this.stagnantCounter > this.maxStagnantHeartbeats;
	}

	/**
	 * The HTML5 video buffer can be broken into several disjointed timespans.
	 *
	 * This method finds which timespan contains our current playhead, then
	 * returns the time left in that timespan (after which point we'll stall).
	 *
	 * Furthermore, if two timespans overlap, it will combine them.
	 */
	getBufferDuration()
	{
		const player = this.player;
		const buffer = player.buffered();
		const playhead = player.currentTime();

		let foundTimespan = false;
		let end = -1;

		for (let i = 0; i < buffer.length; i++)
		{
			if (buffer.start(i) <= playhead && buffer.end(i) >= playhead)
			{
				// We've found the first timespan containing our playhead
				foundTimespan = true;
				end = buffer.end(i);
			}
			if (foundTimespan && buffer.start(i) <= end)
			{
				// This timespan extends the previous one
				end = buffer.end(i);
			}
		}

		if (end === -1)
		{
			// Assume we have no buffer
			end = playhead;
		}

		return end - playhead;
	}

	addMessage(message)
	{
		this.messageQueue.push(this.attachCommon(message));
	}

	attachCommon(message)
	{
		message.version = 7;
		message.domain = parseUrl(this.player.src()).hostname;
		message.broadcastID = this.broadcastId;
		message.siteID = this.siteId;
		message.customerID = this.customerId;
		message.playbackType = this.playbackType;

		message.os = osMap[this.os.name.toLowerCase()];
		message.osVersion = this.os.version;

		message.app = webMap[this.browser.name.toLowerCase()];
		message.appVersion = this.browser.version;

		message.sdk = "web";
		message.sdkVersion = version;
		message.purchaseOrderNumber = this.options.purchaseOrderNumber;
		message.playerUUID = this.GUID;

		// TODO: Geo currently returns an empty object so ill hold off on adding this until a later time
		// const geo = Geo.getLocation().then(() =>
		// {
		// 	this.city = geo.city || null;
		// 	this.country = geo.country_code || null;
		// 	this.province = geo.state || null;
		// });

		message.city = this.city || null;
		message.country = this.country_code || null;
		message.province = this.province || null;

		message.timestamp = Math.floor(Date.now() / 1000) - this.timeOffset;
		return message;
	}

	send()
	{
		if (this.messageQueue.length)
		{
			// Remove older messages so we don't hog too much memory
			if (this.messageQueue.length > this.options.maxQueueSize)
			{
				this.messageQueue.splice(0, this.messageQueue.length - this.options.maxQueueSize);
			}
			this.sendMessage(this.messageQueue.splice(0, this.options.batchSize));
		}
	}

	sendMessage(messages)
	{
		this.lastMessage = messages[0];
		// console.log(this.lastMessage);

		const encodedData = JSON.stringify(messages);

		this.aws.fetch(`https://kinesis.${this.region}.amazonaws.com`, {
			method: "POST",
			headers: {
				"Content-Type": "application/x-amz-json-1.1",
				"X-Amz-Target": "Kinesis_20131202.PutRecord"
			},
			body: JSON.stringify({
				Data: btoa(encodedData),
				PartitionKey: this.GUID,
				StreamName: this.streamName
			})
		})
			.then(() =>
			{
				console.log("Heartbeat Sent");
			})
			.catch(err =>
			{
				logger.error(err);
				this.ingestFailures++;
			});
	}
}

videojs.registerPlugin("vmapTagParserStats", StatsTagParser);

export default StatsTagParser;
