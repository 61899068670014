import videojs from "video.js";
import xPath from "@utilities/XPath";
import {getMimetype} from "@utilities/MimeTypes";

const Plugin = videojs.getPlugin("plugin");

/**
 * This class serves as a template for additional VMAP parsing plugins that will
 * be added in the future. The general workflow will be as follows:
 *
 *  1. The plugin binds an event listener to the player for "vmap-ready" events
 *  2. The player throws a "vmap-ready" event, at which point the VMAP may be
 *     accessed on the `player.vmap` member)
 *  3. The plugin uses our XPath utility to locate a tag of interest (e.g. the
 *     NetworKID tag, or the TrackingEvents tag)
 *  4. The plugin will either initialize UI components (e.g. thumbnails), call
 *     methods on the player (e.g. `player.poster()`), or call methods on the
 *     SourceHandler (e.g. `player.tech().sourceHandler_.addMedia()`)
 *
 * While this method of accessing the SourceHandler is not ideal (the underscore
 * implies this is a private member, and its name is subject to change without
 * warning in future versions of VideoJS), it's inline with how many official
 * plugins work. If it becomes an issue we can always add a "sourcehandle-ready"
 * event in the future to access the source handler instance.
 */
class ContentTagParser extends Plugin
{
	/**
	 * Create a ContentTagParser plugin instance.
	 *
	 * @param  {Player} player
	 *         A Video.js Player instance.
	 *
	 * @param  {Object} [options]
	 *         An optional options object.
	 *
	 *         While not a core part of the Video.js plugin architecture, a
	 *         second argument of options is a convenient way to accept inputs
	 *         from your plugin's caller.
	 */
	constructor(player, options)
	{
		// The parent class will add player under this.player
		super(player, options);

		this.hasContent = false;

		player.on("vmap-ready", () => { this.handle(player.vmap); });

		// Because this class is part of the VolarVMAPPlugin, we do not need to
		// add **another** class to the player.
		// player.addClass("vjs-content-tag-parser-plugin");
	}

	handle(vmap)
	{
		this.vmap = vmap;

		const contentTag = xPath(vmap, "//Extensions/Extension[@type='VolarVMAPExtension']/Content").iterateNext();

		if (!contentTag)
			return;

		if (contentTag.textContent === "")
			return;

		this.hasContent = true;

		this.content = {};
		this.content.geoblocked = contentTag.getAttribute("geoblocked") === "true";

		if (!this.content.geoblocked)
		{
			this.content.src = {
				src: contentTag.textContent.replace(/https?:/, ""),
				type: getMimetype(contentTag.textContent)
			};

			this.player.tech_.sourceHandler_.addSource(this.content.src);

			// Let other tag parser run (e.g. NetworkID) before we try to play
			if (this.player.autoplay())
			{
				setTimeout(() =>
				{
					// focus the player so hotkeys work without needing to click the player when autoplay is enabled
					this.player.el().focus();

					// this.player.tech_.sourceHandler_.playSource();
					this.player.play();
				}, 1);
			}
		}
	}
}

videojs.registerPlugin("vmapTagParserContent", ContentTagParser);

export default ContentTagParser;
