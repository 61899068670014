import videojs from "video.js";

if (videojs.browser.IS_IOS)
{
	const oldCreateLogger = videojs.createLogger;
	videojs.createLogger = function()
	{
		const logger = oldCreateLogger.apply(videojs, arguments);
		logger.debug = function()
		{
			logger.apply(logger, arguments);
		};
		return logger;
	};
}
