import videojs from "video.js";
import ClipTimeInput from "./ClipTimeInput";
import ClipButton from "./ClipButton";
import {sign, hex} from "@utilities/HMAC";
import ClipDownloadSelect from "./ClipDownloadSelect";

const Component = videojs.getComponent("Component");

// Default options for the plugin.
const defaults = {
	errorTimeout: 5,
	maxResolution: 5000
};

/**
 * @param {Player|Object} player
 * @param {Object=} options
 * @extends Component
 * @class BlueFrameClippingUI
 */
class BlueFrameClippingUI extends Component
{
	/**
	 * Creates an instance of this class.
	 *
	 * @param {Player} player
	 *		The `Player` that this class should be attached to.
	 *
	 * @param {Object} [options={}]
	 *		The key/value store of player options.
	 */
	constructor(player, options = {})
	{
		super(player, options);
		this.player = player;
		this.options = videojs.mergeOptions(defaults, options);

		this.clippingUrl = "";
		this.secret = "";

		this.hlsjs = null;
		videojs.Html5Hlsjs.addHook("beforeinitialize", (x, hlsjs) =>
		{
			this.hlsjs = hlsjs;
		});

		this.el_.className = this.buildCSSClass();

		this.startInput = new ClipTimeInput(player, {labelText: this.localize("Clip Start:")});
		this.addChild(this.startInput);
		this.endInput = new ClipTimeInput(player, {labelText: this.localize("Clip End:")});
		this.addChild(this.endInput);
		this.previewButton = new ClipButton(player, {buttonText: this.localize("Preview")});
		this.addChild(this.previewButton);
		this.downloadButton = new ClipButton(player, {buttonText: this.localize("Download ▼")});
		this.downloadButton.el_.style.width = "120px";
		this.addChild(this.downloadButton);

		this.downloadSelect = new ClipDownloadSelect(player);
		this.downloadButton.addChild(this.downloadSelect);

		this.controlBar = this.player.controlBar;
		this.progressControl = this.controlBar.progressControl;
		this.clipProgressBar = this.progressControl.seekBar.getChild("BlueFrameClipProgressBar");
		this.clipMessaging = this.player.getChild("ClipMessaging");

		this.playToggle = this.controlBar.getChild("PlayToggle");
		this.skipBack = this.controlBar.getChild("SkipBack");
		this.skipForward = this.controlBar.getChild("skipForward");

		this.clipProgressBar.on("scrub", this.handleScrub.bind(this));
		this.clipProgressBar.on("activateScrubber", this.handleActivateScrubber.bind(this));
		this.previewButton.on("click", this.preview.bind(this));
		this.downloadSelect.on("download", this.download.bind(this));
		this.startInput.on("change", this.handleStartChange.bind(this));
		this.endInput.on("change", this.handleEndChange.bind(this));

		this.thumbnails = player.thumbnails();

		this.boundKeepPaused = this.keepPaused.bind(this);
		this.boundTimeUpdate = this.timeUpdated.bind(this);
		this.boundPlayerPaused = this.playerPaused.bind(this);

		// End scrubber defaults to active, so end input should default as well
		this.endInput.addClass("active");

		this.hide();
	}

	/**
	 * Builds the default DOM `className`.
	 *
	 * @return {string}
	 *		The DOM `className` for this object.
	 */
	buildCSSClass()
	{
		return `vjs-blueframe-clipping-ui ${super.buildCSSClass()}`;
	}

	show()
	{
		super.show();

		this.player.trigger("clippingstarted");

		this.wasPlayingBefore = !this.player.paused();

		// When showing the clipping UI, we need to hide the seek bar -- we have
		// injected a custom one
		this.progressControl.disable();
		this.progressControl.seekBar.playProgressBar.hide();
		this.thumbnails.toggleEnabled(false);

		// Now display our custom seek bar(s)
		this.clipProgressBar.show();

		// Set limits on our TimeInput components
		this.startInput.setRange(this.clipProgressBar.barStart, this.clipProgressBar.barEnd - 2);
		this.endInput.setRange(this.clipProgressBar.barStart + 2, this.clipProgressBar.barEnd);

		// Prevent the control bar from hiding during clipping
		this.controlBar.addClass("vjs-force-opacity-1");

		// Set up our time listener (so we can auto-pause content)
		this.player.on("timeupdate", this.boundTimeUpdate);
		this.player.on("pause", this.boundPlayerPaused);

		// Disable several buttons
		if (this.playToggle) this.playToggle.disable();
		if (this.skipBack) this.skipBack.disable();
		if (this.skipForward) this.skipForward.disable();

		// Finally, pause the player
		this.player.on("play", this.boundKeepPaused);
		this.player.pause();
	}

	hide()
	{
		super.hide();

		// Invert what we do when we show
		this.progressControl.enable();
		this.progressControl.seekBar.playProgressBar.show();
		this.thumbnails.toggleEnabled(true);
		this.clipProgressBar.hide();
		this.controlBar.removeClass("vjs-force-opacity-1");
		this.player.off("play", this.boundKeepPaused);
		this.player.off("timeupdate", this.boundTimeUpdate);
		this.player.off("pause", this.boundPlayerPaused);
		if (this.wasPlayingBefore)
			this.player.play();

		// Enable several buttons
		if (this.playToggle) this.playToggle.enable();
		if (this.skipBack) this.skipBack.enable();
		if (this.skipForward) this.skipForward.enable();

		// Also hide the messaging (if it's visible)
		this.clipMessaging.hide();

		this.player.trigger("clippingended");
	}

	keepPaused()
	{
		this.player.pause();
	}

	playerPaused()
	{
		this.cuePoint = null;

		// Re-bind this:
		this.player.off("play", this.boundKeepPaused);
		this.player.on("play", this.boundKeepPaused);
	}

	timeUpdated()
	{
		if (this.cuePoint)
		{
			// If we have a cuepoint, we're waiting to pause
			if (this.player.currentTime() >= this.cuePoint)
			{
				this.player.pause();
			}
		}
		else
		{
			// If we don't have a cuepoint, the time shouldn't be updating
			this.player.pause();
		}
	}

	showing()
	{
		return !this.hasClass("vjs-hidden");
	}

	handleScrub(evt, data)
	{
		if (data.scrubber === "start")
		{
			this.startInput.setTime(data.time);
		}
		else
		{
			this.endInput.setTime(data.time);
		}

		// If they're scrubbing, they don't care about their cuepoint anymore
		this.cuePoint = null;
	}

	handleActivateScrubber(evt, which)
	{
		if (which === "start")
		{
			this.startInput.addClass("active");
			this.endInput.removeClass("active");
		}
		else
		{
			this.startInput.removeClass("active");
			this.endInput.addClass("active");
		}
	}

	handleStartChange(evt, time)
	{
		this.clipProgressBar.setTime(time, "start");
	}

	handleEndChange(evt, time)
	{
		this.clipProgressBar.setTime(time, "end");
	}

	preview()
	{
		// Seek to the starting point
		this.player.currentTime(this.startInput.getTime());

		// Prepare to pause when we reach the endpoint
		this.cuePoint = this.endInput.getTime();

		// Disable our "keepPaused" listener, so that we CAN play
		this.player.off("play", this.boundKeepPaused);

		// Play
		this.player.play();
	}

	getSrc()
	{
		// TODO: Write this so that it can function without HLS.js
		// We need to handle blob URLs (figure out where they originally
		// came from, or throw an error about lack of support)

		const levels = this.hlsjs.levels;
		const bestRendition = {
			url: null,
			height: -1
		};
		levels.forEach(level =>
		{
			if (level.height > bestRendition.height && level.height <= this.options.maxResolution)
			{
				bestRendition.url = level.url[0];
				bestRendition.height = level.height;
			}
		});

		if (bestRendition.url.substr(0, 2) === "//")
		{
			bestRendition.url = window.location.protocol + bestRendition.url;
		}

		return bestRendition.url;
	}

	download(evt, type)
	{
		const request = {
			video: this.getSrc(),
			start: this.startInput.getTime(),
			end: this.endInput.getTime(),
			type: type
		};

		request.signature = hex(sign(
			this.secret,
			`${request.video}\n${request.start}\n${request.end}\n${request.type}\n`
		));

		fetch(
			this.clippingUrl,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify(request)
			}
		)
			.then(resp => resp.json())
			.then(data =>
			{
				if (data.url)
				{
					const link = document.createElement("a");
					link.href = data.url;
					link.download = "VideoClip.mp4";
					// Prevents the link from leaving the player if the
					// Content-Disposition header is not set, at the cost of
					// being blocked by pop-up blockers:
					// link.target = "_blank";
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					this.clipMessaging.hide();
				}
				else
				{
					this.clipMessaging.display("ERROR<br /><br />" + data.message);
					setTimeout(() => this.clipMessaging.hide(), this.options.errorTimeout * 1000);
				}
			});

		// Display a loading spinner
		this.clipMessaging.display(
			"Generating clip... This may take up to 30 seconds.<br /><span class=\"loading-spinner\"></span>"
		);
	}
}

/**
 * The text that should display over the `StatsForNerdsOverlay`s controls. Added for localization.
 *
 * @type {string}
 * @private
 */
BlueFrameClippingUI.prototype.controlText_ = "Clipping UI";

Component.registerComponent("blueframeClippingUI", BlueFrameClippingUI);

export default BlueFrameClippingUI;
