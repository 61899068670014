import videojs from "video.js";

const MenuItem = videojs.getComponent("MenuItem");

/**
 * The base class for buttons
 *
 * @param {Player|Object} player
 * @param {Object=} options
 * @extends MenuItem
 * @class WebsiteMenuItem
 */
class WebsiteMenuItem extends MenuItem
{
	handleClick()
	{
		super.handleClick();

		// Open the new window
		window.open("https://www.hudl.com/blueframe/");
	}

	/**
	 * Builds the default DOM `className`.
	 *
	 * @return {string}
	 *		The DOM `className` for this object.
	 */
	buildCSSClass()
	{
		// ! For some stupid reason, VideoJS doesn't seem to call this method?
		return `vjs-website-menu-item ${super.buildCSSClass()}`;
	}
}

/**
 * The text for this MenuItem Added for localization.
 *
 * @type {string}
 * @private
 */
WebsiteMenuItem.prototype.options_ = {label: "Website"};

videojs.registerComponent("websiteMenuItem", WebsiteMenuItem);

export default WebsiteMenuItem;
