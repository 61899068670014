import videojs from "video.js";
import {parseUrl} from "@utilities/Url";

const Component = videojs.getComponent("Component");
const Button = videojs.getComponent("Button");

const defaults = {
	newWindowHeight: 360,
	newWindowWidth: 640
};

/**
 * The base class for buttons
 *
 * @param {Player|Object} player
 * @param {Object=} options
 * @extends Button
 * @class PopupWindowButton
 */
class PopupWindowButton extends Button
{
	/**
	 * Creates an instance of this class.
	 *
	 * @param {Player} player
	 *		The `Player` that this class should be attached to.
	 *
	 * @param {Object} [options={}]
	 *		The key/value store of player options.
	 */
	constructor(player, options = {})
	{
		const mergedOptions = videojs.mergeOptions(defaults, options);

		super(player, mergedOptions);
		this.player = player;

		this.hide();
	}

	/**
	 * Builds the default DOM `className`.
	 *
	 * @return {string}
	 *		The DOM `className` for this object.
	 */
	buildCSSClass()
	{
		return `vjs-popup-window-button ${super.buildCSSClass()}`;
	}

	/**
	 * This gets called when an `PopupWindowButton` is "clicked". See
	 * {@link ClickableComponent} for more detailed information on what a click can be.
	 *
	 * @listens tap
	 * @listens click
	 */
	handleClick()
	{
		super.handleClick();

		// Get the source URL (VMAP)
		const sourceUrl = this.player.src();

		// Convert to a windows player URL. e.g.:
		// https://vcloud.blueframetech.com/broadcast/embed/79999?windowed=true
		// TODO: This should be passed in via the NewWindowPlugin instead of
		// TODO: computed here. That way we can change how it's calculated.
		const urlDetails = parseUrl(sourceUrl);
		const broadcastId = urlDetails.pathname.split("/").pop();
		const newPath = `broadcast/embed/${broadcastId}`;
		const windowedUrl = `${urlDetails.protocol}//${urlDetails.host}/${newPath}?windowed=true`;

		// Open the new window
		this.player.pause();
		window.open(
			windowedUrl,
			"_blank",
			`width=${this.options_.newWindowWidth},height=${this.options_.newWindowHeight}`
		);
	}
}

/**
 * The text that should display over the `PopupWindowButton`s controls. Added for localization.
 *
 * @type {string}
 * @private
 */
PopupWindowButton.prototype.controlText_ = "Open in New Window";

Component.registerComponent("popupWindowButton", PopupWindowButton);

export default PopupWindowButton;
