import videojs from "video.js";

// Default opts for the plugin.
// const defaults = {};

/**
 * A basic Video.js plugin. For more information on the API
 *
 * See: https://docs.videojs.com/tutorial-plugins.html
 *
 * This is a customized version of:
 * https://github.com/ctd1500/videojs-hotkeys/blob/7f62ce8b5c26ce012e76108066c39b5208fc3df9/videojs.hotkeys.js
 * I want to be able to easily make direct changes to this plugin so I added it as a custom plugin instead of
 * using the methods their docs suggest.
 *
 *
 * all magic number should be commented with what key they are.
 * They correspond to browsers event keycodes
 * https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
 *
 */
function Hotkeys(options)
{
	var player = this;

	function hotkeySetup()
	{
		var pEl = player.el();
		var doc = document;
		var activeEl = doc.activeElement;
		var defaultOptions = {
			volumeStep: 0.1,
			seekStep: 5,
			enableSeek: true,
			enablePlay: true,
			enableMute: true,
			enableVolumeScroll: false,
			enableHoverScroll: false,
			enableFullscreen: true,
			enableNumbers: false,
			enableJogStyle: false,
			alwaysCaptureHotkeys: false,
			captureDocumentHotkeys: false,
			documentHotkeysFocusElementFilter: function (){ return false; },
			enableModifiersForNumbers: true,
			enableInactiveFocus: true,
			skipInitialFocus: false,
			playPauseKey: playPauseKey,
			rewindKey: rewindKey,
			forwardKey: forwardKey,
			volumeUpKey: volumeUpKey,
			volumeDownKey: volumeDownKey,
			muteKey: muteKey,
			fullscreenKey: fullscreenKey,
			playBackUpKey: playBackUpKey,
			playBackDownKey: playBackDownKey,
			playBackDefaultKey: playBackDefaultKey,
			customKeys: {}
		};

		const cPlay = 1,
			  cRewind = 2,
			  cForward = 3,
			  cVolumeUp = 4,
			  cVolumeDown = 5,
			  cMute = 6,
			  cFullscreen = 7,
			  cPlayBackUpKey = 8,
			  cPlayBackDownKey = 9,
			  cPlayBackDefaultKey = 10;

		// Use built-in merge function from Video.js v5.0+ or v4.4.0+
		var mergeOptions = videojs.mergeOptions || videojs.util.mergeOptions;
		var opts = mergeOptions(defaultOptions, options || {});

		var volumeStep = opts.volumeStep,
			seekStep = opts.seekStep,
			enableSeek = opts.enableSeek,
			enablePlay = opts.enablePlay,
			enableMute = opts.enableMute,
			enableVolumeScroll = opts.enableVolumeScroll,
			enableHoverScroll = opts.enableHoverScroll,
			enableFull = opts.enableFullscreen,
			enableNumbers = opts.enableNumbers,
			enableJogStyle = opts.enableJogStyle,
			alwaysCaptureHotkeys = opts.alwaysCaptureHotkeys,
			captureDocumentHotkeys = opts.captureDocumentHotkeys,
			documentHotkeysFocusElementFilter = opts.documentHotkeysFocusElementFilter,
			enableModifiersForNumbers = opts.enableModifiersForNumbers,
			enableInactiveFocus = opts.enableInactiveFocus,
			skipInitialFocus = opts.skipInitialFocus;

		var videojsVer = videojs.VERSION;

		// Set default player tabindex to handle keydown and doubleclick events
		if (!pEl.hasAttribute("tabIndex"))
		{
			pEl.setAttribute("tabIndex", "-1");
		}

		// Remove player outline to fix video performance issue
		pEl.style.outline = "none";

		if (alwaysCaptureHotkeys || !player.autoplay())
		{
			if (!skipInitialFocus)
			{
				player.one("play", function ()
				{
					// Fixes the .vjs-big-play-button handing focus back to body instead of the player
					pEl.focus();
				});
			}
		}

		if (enableInactiveFocus)
		{
			// weird scenario where two functions use each other. This fails to pass the linter.
			// disableing the linter for this section
			// error:  'cancelFocusingPlayer' was used before it was defined
			/* eslint-disable */
			player.on("userinactive", function ()
			{
				var focusingPlayerTimeout = setTimeout(function ()
				{
					player.off("useractive", cancelFocusingPlayer);
					var activeElement = doc.activeElement;
					var controlBar = pEl.querySelector(".vjs-control-bar");
					if ((activeElement && activeElement.parentElement === controlBar) || activeEl === pEl)
					{
						pEl.focus();
					}
				}, 10);

				// When the control bar fades, re-apply focus to the player if last focus was a control button
				var cancelFocusingPlayer = function ()
				{
					clearTimeout(focusingPlayerTimeout);
				};

				player.one("useractive", cancelFocusingPlayer);
			});
			/* eslint-enable */
		}

		player.on("play", function ()
		{
			// Fix allowing the YouTube plugin to have hotkey support.
			var ifblocker = pEl.querySelector(".iframeblocker");
			if (ifblocker && ifblocker.style.display === "")
			{
				ifblocker.style.display = "block";
				ifblocker.style.bottom = "39px";
			}
		});

		function checkKeys(e)
		{
			// Allow some modularity in defining custom hotkeys

			// Play/Pause check
			if (opts.playPauseKey(e))
			{
				return cPlay;
			}

			// Seek Backward check
			if (opts.rewindKey(e))
			{
				return cRewind;
			}

			// Seek Forward check
			if (opts.forwardKey(e))
			{
				return cForward;
			}

			// Volume Up check
			if (opts.volumeUpKey(e))
			{
				return cVolumeUp;
			}

			// Volume Down check
			if (opts.volumeDownKey(e))
			{
				return cVolumeDown;
			}

			// Mute check
			if (opts.muteKey(e))
			{
				return cMute;
			}

			// Fullscreen check
			if (opts.fullscreenKey(e))
			{
				return cFullscreen;
			}

			// PlayBack speed up check
			if (opts.playBackUpKey(e))
			{
				return cPlayBackUpKey;
			}

			// PlayBack speed down check
			if (opts.playBackDownKey(e))
			{
				return cPlayBackDownKey;
			}

			// PlayBack speed default check
			if (opts.playBackDefaultKey(e))
			{
				return cPlayBackDefaultKey;
			}

			return null;
		}

		function keyDown(event)
		{
			var ewhich = event.which,
				wasPlaying,
				seekTime,
				rateIndex;
			var ePreventDefault = event.preventDefault.bind(event);
			var duration = player.duration();
			// When controls are disabled, hotkeys will be disabled as well
			if (player.controls())
			{
				// Don't catch keys if any control buttons are focused, unless alwaysCaptureHotkeys is true
				activeEl = doc.activeElement;
				if (
					alwaysCaptureHotkeys ||
					(captureDocumentHotkeys && documentHotkeysFocusElementFilter(activeEl)) ||
					activeEl === pEl.querySelector(".vjs-tech") ||
					activeEl === pEl.querySelector(".iframeblocker") ||
					isPlayerOrChild(activeEl)
				)
				{
					switch (checkKeys(event, player))
					{

					// Spacebar toggles play/pause
					case cPlay:
						if (!enablePlay) break;

						ePreventDefault();
						if (alwaysCaptureHotkeys || captureDocumentHotkeys)
						{
							// Prevent control activation with space
							event.stopPropagation();
						}

						if (player.paused())
						{
							silencePromise(player.play());
						}
						else
						{
							player.pause();
						}
						break;

					// Seeking with the left/right arrow keys
					case cRewind:
						if (!enableSeek) break;

						// Seek Backward
						wasPlaying = !player.paused();
						ePreventDefault();
						if (wasPlaying)
						{
							player.pause();
						}
						seekTime = player.currentTime() - seekStepD(event);
						// The flash player tech will allow you to seek into negative
						// numbers and break the seekbar, so try to prevent that.
						if (seekTime <= 0)
						{
							seekTime = 0;
						}
						player.currentTime(seekTime);
						if (wasPlaying)
						{
							silencePromise(player.play());
						}
						break;
					case cForward:
						if (!enableSeek) break;

						// Seek Forward
						wasPlaying = !player.paused();
						ePreventDefault();
						if (wasPlaying)
						{
							player.pause();
						}
						seekTime = player.currentTime() + seekStepD(event);
						// Fixes the player not sending the end event if you
						// try to seek past the duration on the seekbar.
						if (seekTime >= duration)
						{
							// eslint-disable-next-line no-magic-numbers
							seekTime = wasPlaying ? duration - 0.001 : duration;
						}
						player.currentTime(seekTime);
						if (wasPlaying)
						{
							silencePromise(player.play());
						}
						break;

					// Volume control with the up/down arrow keys
					case cVolumeDown:
						ePreventDefault();
						if (player.muted())
						{
							player.muted(false);
							break;
						}
						if (enableJogStyle)
						{
							seekTime = player.currentTime() - 1;
							if (player.currentTime() <= 1)
							{
								seekTime = 0;
							}
							player.currentTime(seekTime);
						}
						else
						{
							player.volume(player.volume() - volumeStep);
						}
						break;
					case cVolumeUp:
						ePreventDefault();
						if (player.muted())
						{
							player.muted(false);
							break;
						}
						if (enableJogStyle)
						{
							seekTime = player.currentTime() + 1;
							if (seekTime >= duration)
							{
								seekTime = duration;
							}
							player.currentTime(seekTime);
						}
						else
						{
							player.volume(player.volume() + volumeStep);
						}
						break;

					// Toggle Mute with the M key
					case cMute:
						if (enableMute)
						{
							player.muted(!player.muted());
						}
						break;

					// Toggle Fullscreen with the F key
					case cFullscreen:
						if (enableFull)
						{
							if (player.isFullscreen())
							{
								player.exitFullscreen();
							}
							else
							{
								player.requestFullscreen();
							}
						}
						break;

					case cPlayBackUpKey:
						ePreventDefault();
						rateIndex = opts.playbackRates.indexOf(player.playbackRate()) + 1;
						player.playbackRate(opts.playbackRates[rateIndex] || opts.playbackRates[rateIndex - 1]);
						break;

					case cPlayBackDownKey:
						ePreventDefault();
						rateIndex = opts.playbackRates.indexOf(player.playbackRate()) - 1;
						player.playbackRate(opts.playbackRates[rateIndex] || opts.playbackRates[0]);
						break;

					case cPlayBackDefaultKey:
						ePreventDefault();
						player.playbackRate(1);
						break;

					default:
						/* eslint-disable no-magic-numbers */
						// Number keys from 0-9 skip to a percentage of the video. 0 is 0% and 9 is 90%
						if ((ewhich > 47 && ewhich < 59) || (ewhich > 95 && ewhich < 106))
						{
							// Do not handle if enableModifiersForNumbers set to false and keys are Ctrl, Cmd or Alt
							if (enableModifiersForNumbers || !(event.metaKey || event.ctrlKey || event.altKey))
							{
								if (enableNumbers)
								{
									var sub = 48;
									if (ewhich > 95)
									{
										sub = 96;
									}
									var number = ewhich - sub;
									ePreventDefault();
									// 0.1 = 10%
									player.currentTime(player.duration() * number * 0.1);
								}
							}
						}
						/* eslint-enable no-magic-numbers */

						// Handle any custom hotkeys
						var customKeys = Object.keys(opts.customKeys);
						customKeys.forEach(
							customKey =>
							{
								var customHotkey = opts.customKeys[customKey];
								// Check for well formed custom keys
								if (customHotkey && customHotkey.key && customHotkey.handler)
								{
									// Check if the custom key"s condition matches
									if (customHotkey.key(event))
									{
										ePreventDefault();
										customHotkey.handler(player, opts, event);
									}
								}
							}
						);
					}
				}
			}
		}

		function doubleClick(event)
		{
			// Video.js added double-click fullscreen in 7.1.0
			if (videojsVer !== null && videojsVer <= "7.1.0")
			{
				// When controls are disabled, hotkeys will be disabled as well
				if (player.controls())
				{
					// Don"t catch clicks if any control buttons are focused
					activeEl = event.relatedTarget || event.toElement || doc.activeElement;
					if (
						activeEl === pEl ||
						activeEl === pEl.querySelector(".vjs-tech") ||
						activeEl === pEl.querySelector(".iframeblocker")
					)
					{
						if (enableFull)
						{
							if (player.isFullscreen())
							{
								player.exitFullscreen();
							}
							else
							{
								player.requestFullscreen();
							}

						}

					}

				}

			}

		}

		var volumeHover = false;
		var volumeSelector = pEl.querySelector(".vjs-volume-menu-button") || pEl.querySelector(".vjs-volume-panel");
		if (volumeSelector !== null)
		{
			volumeSelector.onmouseover = function ()
			{
				volumeHover = true;
			};
			volumeSelector.onmouseout = function ()
			{
				volumeHover = false;
			};
		}

		function mouseScroll(event)
		{
			if (enableHoverScroll)
			{
				// If we leave this undefined then it can match non-existent elements below
				activeEl = 0;
			}
			else
			{
				activeEl = doc.activeElement;
			}

			// When controls are disabled, hotkeys will be disabled as well
			if (player.controls())
			{
				if (
					alwaysCaptureHotkeys ||
					activeEl === pEl ||
					activeEl === pEl.querySelector(".vjs-tech") ||
					activeEl === pEl.querySelector(".iframeblocker") ||
					activeEl === pEl.querySelector(".vjs-control-bar") ||
					volumeHover)
				{
					if (enableVolumeScroll)
					{
						var delta = Math.max(-1, Math.min(1, event.wheelDelta || - event.detail));
						event.preventDefault();

						if (delta === 1)
						{
							player.volume(player.volume() + volumeStep);
						}
						else if (delta === -1)
						{
							player.volume(player.volume() - volumeStep);
						}
					}
				}
			}
		}

		function playPauseKey(e)
		{
			// Space bar or MediaPlayPause
			// eslint-disable-next-line no-magic-numbers
			return e.which === 32 || e.which === 179;
		}

		function rewindKey(e)
		{
			// Left Arrow or MediaRewind
			// eslint-disable-next-line no-magic-numbers
			return e.which === 37 || e.which === 177;
		}

		function forwardKey(e)
		{
			// Right Arrow or MediaForward
			// eslint-disable-next-line no-magic-numbers
			return e.which === 39 || e.which === 176;
		}

		function volumeUpKey(e)
		{
			// Up Arrow
			// eslint-disable-next-line no-magic-numbers
			return e.which === 38;
		}

		function volumeDownKey(e)
		{
			// Down Arrow
			// eslint-disable-next-line no-magic-numbers
			return e.which === 40;
		}

		function muteKey(e)
		{
			// M key
			// eslint-disable-next-line no-magic-numbers
			return e.which === 77;
		}

		function fullscreenKey(e)
		{
			// F key
			// eslint-disable-next-line no-magic-numbers
			return e.which === 70;
		}

		function playBackUpKey(e)
		{
			// >
			// I know is this differnt then the norm
			// but this appears to be the only common reference
			// between all the broswers ive tested.
			return e.key === ">";
		}

		function playBackDownKey(e)
		{
			// <
			// I know is this differnt then the norm
			// but this appears to be the only common reference
			// between all the broswers ive tested.
			return e.key === "<";
		}

		function playBackDefaultKey(e)
		{
			// 0 and shift
			// eslint-disable-next-line no-magic-numbers
			return e.which === 48 && e.shiftKey;
		}

		function seekStepD(e)
		{
			// SeekStep caller, returns an int, or a function returning an int
			return typeof seekStep === "function" ? seekStep(e) : seekStep;
		}

		function silencePromise(value)
		{
			if (value !== null &&
				typeof value !== "undefined" &&
				typeof value.then === "function")
			{
				value.then(null, function (e){ console.log(e); });
			}
		}

		function isPlayerOrChild(el)
		{
			if (!el.parentElement)
			{
				return false;
			}
			if (el === pEl)
			{
				return true;
			}
			return isPlayerOrChild(el.parentElement);
		}

		player.on("keydown", keyDown);
		player.on("dblclick", doubleClick);
		player.on("mousewheel", mouseScroll);
		player.on("DOMMouseScroll", mouseScroll);

		if (captureDocumentHotkeys)
		{
			document.addEventListener("keydown", function (event)
			{
				keyDown(event);
			});
		}

		player.on("seekingdisabled", () => enableSeek = false);
		player.on("seekingenabled", () => enableSeek = true);
		player.on("clippingstarted", () =>
		{
			enableSeek = false;
			enablePlay = false;
		});
		player.on("clippingended", () =>
		{
			enableSeek = true;
			enablePlay = true;
		});

		return this;
	}

	player.ready(() => hotkeySetup());
}

// Register the plugin with video.js.
videojs.registerPlugin("Hotkeys", Hotkeys);
export default Hotkeys;
