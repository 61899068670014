import videojs from "video.js";
import xPath from "@utilities/XPath";

import "./videojs.thumbnails";
import "./videojs.thumbnails.css";

const logger = videojs.createLogger("VMAPTagParserPreviewSets");
const Plugin = videojs.getPlugin("plugin");

// Default options for the plugin.
const defaults = {
	enabled: true
};

/**
 * This plugin will parse the PreviewSets for the video and then register
 * thumbnails to be displayed. Furthermore, as the video plays, it will update
 * this list of thumbnails as new ones should exist
 */
class PreviewSets extends Plugin
{
	/**
	 * Create a PreviewSets plugin instance.
	 *
	 * @param  {Player} player
	 *         A Video.js Player instance.
	 *
	 * @param  {Object} [options]
	 *         An optional options object.
	 *
	 *         While not a core part of the Video.js plugin architecture, a
	 *         second argument of options is a convenient way to accept inputs
	 *         from your plugin's caller.
	 */
	constructor(player, options)
	{
		// The parent class will add player under this.player
		super(player);

		this.options = videojs.mergeOptions(defaults, options);

		// The following setting was in the old player
		this.interval = 10;

		// To avoid flooding the logs
		this.knownFailures = {};

		if (this.options.enabled)
			player.on("vmap-ready", () => { this.handle(player.vmap); });
	}

	handle(vmap)
	{
		this.vmap = vmap;

		const previewSetsTag = xPath.ext(vmap, "PreviewSets").iterateNext();

		if (!previewSetsTag)
		{
			// There are no thumbnails on this broadcast
			// Maybe we haven't started streaming yet
			return;
		}

		// TODO: Figure out if we ever plan to support multiple PreviewSets (the VMAP supports it)
		// If not, we should probably remove that from the VMAP
		const previewMeta = xPath(previewSetsTag, "//PreviewSet").iterateNext().textContent;

		// Some browsers do not allow loading of JSON from insecure domains if
		// you are in a secure context. Therefore we'll strip the protocol from
		// the URL and try a protocol-relative link

		this.baseUrl = previewMeta.substring(0, previewMeta.lastIndexOf("/") + 1).replace(/^https?:/, "");

		fetch(previewMeta.replace(/^https?:/, "")).then(resp => resp.json()).then(this.setupThumbnails.bind(this));
	}

	setupThumbnails(metadata)
	{
		// Live broadcasts give a base_url, archived broadcasts do not...
		this.baseUrl = metadata.base_url ? metadata.base_url + "/" : this.baseUrl;
		this.startIndex = metadata.ranges[0][0];
		this.frameRate = metadata.frame_rate;
		this.format = metadata.format;
		this.supported = true;

		this.player.thumbnails({
			thumbnailConstructor: this.thumbnailConstructor.bind(this),
			thumbnailError: this.thumbnailError.bind(this)
		});
	}

	thumbnailConstructor(time = 0)
	{
		if (this.frameRate === null) return "";

		const index = Math.trunc((Math.trunc(time / this.interval) * this.interval) * this.frameRate);
		const newSrc = this.buildUrl(index);
		return newSrc;
	}

	thumbnailError(src)
	{
		// Sometimes an image doesn't exist (wasn't generated), so parse out the
		// index and increment it. If we keep failing to find an image, give up
		// before the next interval set (e.g. if interval is 10 and we started
		// at index 0, give up at index 9)

		// Given "http://domain.com/id/prev.image/####.jpg" parse out ####
		let index = parseInt(src.split("/").pop().split(".")[0]);
		// Something went wrong
		if (isNaN(index)) return "";

		if (!this.knownFailures[index])
		{
			logger.warn(`Preview [${index}] failed to load`);
			this.knownFailures[index] = true;
		}

		// reverse our steps
		index -= this.startIndex;

		// the starting index for this interval set
		const baseIndex = index - (index % this.interval);
		// highest index in this interval set
		const maxIndex = Math.trunc((this.interval * this.frameRate) + baseIndex - 1);
		index++;
		// we've gone far enough, give up to prevent an infinite loop of failure
		if (index > maxIndex) return "";

		return this.buildUrl(index);
	}

	buildUrl(index)
	{
		if (this.baseUrl && this.interval && this.format && this.startIndex !== null)
			return this.baseUrl + (this.startIndex + index) + "." + this.format;
		else
			return "";
	 }
}

videojs.registerPlugin("vmapTagParserPreviewSets", PreviewSets);

export default PreviewSets;
