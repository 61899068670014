import videojs from "video.js";

const logger = videojs.createLogger("BehaviorChangeGlobalHLSJS");

/**
 * A basic Video.js plugin.
 */
function GlobalHLSJS()
{
	if (videojs.Html5Hlsjs)
	{
		videojs.Html5Hlsjs.addHook("beforeinitialize", (x, hlsjs) =>
		{
			logger.debug("HLS.js loaded and assigned to $v().hlsjs");
			this.hlsjs = hlsjs;
		});
	}
}

// Register the plugin with video.js.
videojs.registerPlugin("behaviorChangeGlobalHLSJS", GlobalHLSJS);

export default GlobalHLSJS;
