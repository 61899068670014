import videojs from "video.js";
import InfoMenuHeader from "./InfoMenuHeader";

const Component = videojs.getComponent("Component");
const Menu = videojs.getComponent("Menu");

/**
 * The base class for buttons
 *
 * @param {Player|Object} player
 * @param {Object=} options
 * @extends Button
 * @class InfoMenu
 */
class InfoMenu extends Menu
{
	/**
	 * Creates an instance of this class.
	 *
	 * @param {Player} player
	 *		The `Player` that this class should be attached to.
	 *
	 * @param {Object} [options={}]
	 *		The key/value store of player options.
	 */
	constructor(player, options = {})
	{
		super(player, options);
		this.player = player;

		this.addItem(new InfoMenuHeader(player, options));
	}

	/**
	 * Builds the default DOM `className`.
	 *
	 * @return {string}
	 *		The DOM `className` for this object.
	 */
	buildCSSClass()
	{
		// ! For some stupid reason, VideoJS doesn't seem to call this method?
		return `vjs-info-menu ${super.buildCSSClass()}`;
	}

	/**
	 * A bug in VideoJS prevents Menu Components from calling buildCSSClass
	 */
	createEl()
	{
		const el = super.createEl();

		el.classList.add("vjs-info-menu");

		return el;
	}
}

Component.registerComponent("infoMenu", InfoMenu);

export default InfoMenu;
