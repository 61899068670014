import videojs from "video.js";
import Hls from "hls.js";

const logger = videojs.createLogger("MetadataEvents");
const Plugin = videojs.getPlugin("plugin");

// Default options for the plugin.
const defaults = {};

/**
 * An advanced Video.js plugin. For more information on the API
 *
 * See: https://blog.videojs.com/feature-spotlight-advanced-plugins/
 */
class MetadataEvents extends Plugin
{

	/**
	 * Create a MetadataEvents plugin instance.
	 *
	 * @param  {Player} player
	 *         A Video.js Player instance.
	 *
	 * @param  {Object} [options]
	 *         An optional options object.
	 *
	 *         While not a core part of the Video.js plugin architecture, a
	 *         second argument of options is a convenient way to accept inputs
	 *         from your plugin's caller.
	 */
	constructor(player, options)
	{
		// The parent class will add player under this.player
		super(player);

		this.options = videojs.mergeOptions(defaults, options);

		this.hlsjsTextTrack = null;

		// If the content changes, we may need to bind things again
		this.bindTrackListenersFunc = this.bindTrackListeners.bind(this);
		player.on("contentchanged", this.detectNewTracks.bind(this));
		this.detectNewTracks();

		// If the list of text tracks changes, check for new metadata tracks

		if (videojs.Html5Hlsjs)
		{
			videojs.Html5Hlsjs.addHook("beforeinitialize", (x, hlsjs) =>
			{
				// HLS.js isn't directly aware of VideoJS, so it uses the native
				// video element's addTextTrack method instead of the VideoJS
				// polyfill. This native addTextTrack method has a different API
				// depending on the browser, so we can't depend on it to work
				// outside of Chrome.
				//
				// Unfortunately to make matter worse, the VideoJS polyfill will
				// fall back to the native addTextTrack if supported, meaning
				// just overriding the native method leads to an infinite loop.
				//
				// We either need to bypass native ENTIRELY, or we need to use
				// the same "supported" flag that VideoJS uses
				hlsjs.on(Hls.Events.MEDIA_ATTACHING, this.hlsjsAttachMedia.bind(this));
				hlsjs.on(Hls.Events.MEDIA_DETACHING, this.hlsjsDetachMedia.bind(this));
			});
		}

		this.boundTracks = [];
	}

	hlsjsAttachMedia(evt, data)
	{
		// Override the native addTextTrack method with VideoJS
		this.hlsjsMedia = data.media;
		this.oldAddTextTrack = data.media.addTextTrack;
		const self = this;
		logger.debug(
			"featuresNativeTextTracks? " +
			this.player.tech_.featuresNativeTextTracks
		);
		if (!this.player.tech_.featuresNativeTextTracks)
		{
			data.media.addTextTrack = (type, name, lang) =>
			{
				logger.debug(`addTextTrack(${type}, ${name}, ${lang})`);
				const newTextTrack = self.player.addTextTrack(type, name, lang);
				if (newTextTrack.kind === "metadata")
				{
					newTextTrack.player = self.player;

					// There are two instances where we are trying to add a "cuechange" listener with the same handler.
					// If both are added then we start triggering all metadata events multiple times.
					// So, I'm removing the listener incase it already exist so we can never have 2 for no reason.
					newTextTrack.removeEventListener("cuechange", self.handleCueChange);
					newTextTrack.addEventListener("cuechange", self.handleCueChange);
					newTextTrack.bound = true;
				}
				return newTextTrack;
			};
		}
	}

	hlsjsDetachMedia()
	{
		// Restore the native addTextTrack method
		this.hlsjsMedia.addTextTrack = this.oldAddTextTrack;
		this.hlsjsMedia = null;
	}

	detectNewTracks()
	{
		// The first time we start playing, enable any metadata tracks
		this.player.on(["playing", "contentplaying", "contentresumed"], this.bindTrackListenersFunc);
		// If new tracks are added, enable any metadata tracks
		this.player.textTracks().addEventListener("addtrack", this.bindTrackListenersFunc);
	}

	bindTrackListeners()
	{
		logger.debug("Checking for TextTracks to bind listeners");
		const tracks = this.player.textTracks();

		for (let i = 0; i < tracks.length; i++)
		{
			if (tracks[i].kind === "metadata")
			{
				// On some devices textTracks default to "disabled"
				tracks[i].mode = "hidden";

				// By storing a reference to the player on the tracks, we can
				// emit events on it later when we don't have access to it
				tracks[i].player = this.player;

				if (!tracks[i].bound)
				{
					logger.debug("Discovered unbound metadata track. Binding now.");

					// We cannot override the "this" value for handleCueChange, as
					// that is our only way to know WHICH cue changed
					// There are two instances where we are trying to add a "cuechange" listener with the same handler.
					// If both are added then we start triggering all metadata events multiple times.
					// So, I'm removing the listener incase it already exist so we can never have 2 for no reason.
					tracks[i].removeEventListener("cuechange", this.handleCueChange);
					tracks[i].addEventListener("cuechange", this.handleCueChange);

					// Some content types (like MP4) provide a list of all cues
					// upfront. We may want to bind `onenter` and `onexit`
					for (let j = 0; j < tracks[i].cues.length; j++)
					{
						const cue = tracks[i].cues[j];
						cue.onenter = this.enterCue.bind(this, cue);
					}
				}

				// Avoid binding the same track twice
				tracks[i].bound = true;
			}
		}
	}

	handleCueChange()
	{
		// TODO: Add support for multiple cues triggering simultaneously
		// It's not likely to happen (and won't happen for our broadcasts)
		// But it's technically possible
		// As part of that, we'll need to track which activeCues are leftover
		// from previous events
		for (var i = 0; i < this.activeCues.length; i++)
		{
			const cue = this.activeCues[i];
			// cue.text for Chrome, cue.value.data for iOS
			// We may need alternate voodoo for other devices
			var msg = MetadataEvents.parseCue(cue.text || cue.value.data);

			logger.debug(`Metadata: ${JSON.stringify(msg)}`);
			this.player.trigger("metadata", msg);
		}
	}

	enterCue(cue)
	{
		// TODO: Verify this is correct
		var msg = MetadataEvents.parseCue(cue.text || cue.value.data);
		logger.debug("enterCue. Data: ", msg);
		this.player.trigger("metadata", msg);
	}

	static parseCue(text)
	{
		let msg = text;

		// If a cue contains non-printable characters, VideoJS seems to convert
		// it to a comma-separated list of ASCII-encoded decimal byte values
		// That inconsistency (sometimes returning a string, sometimes returning
		// a list of bytes) can make it hard to interface with metadata. Let's
		// make it more consistent.
		if (MetadataEvents.asciiBytesRegex.test(text))
		{
			msg = msg.split(",").map(byte => String.fromCharCode(byte)).join("");
		}

		// BlueFrame metadata events are JSON-encoded, but possess a starting
		// 0x03 byte and ending 0x00 byte.
		// TODO: Ensure we are dealing with BlueFrame metadata before trimming
		// TODO: these bytes (we might be corrupting third party binary data)
		if (msg[0] === "\u0003" && msg[msg.length - 1] === "\u0000")
		{
			msg = msg.substring(1, msg.length - 1);
		}

		// The vast majority of metadata is JSON-encoded. If so, let's parse it
		var msgData;
		try
		{
			msgData = JSON.parse(msg);
		}
		catch (e)
		{
			msgData = msg;
		}

		return msgData;
	}
}

// Utilized by parseCue
// This regular expression checks for:
//     - The following patterns one or more times:
//         - an optional 1 or 2 (hundreds place)
//         - followed by one or two of 0 through 9 (tens and ones place)
//         - followed by a comma
//     - Followed by a final byte (the same pattern, no comma)
MetadataEvents.asciiBytesRegex = /^([12]?[0-9]{1,2},)+[12]?[0-9]{1,2}$/;

// Register the plugin with video.js.
videojs.registerPlugin("metadataEvents", MetadataEvents);

export default MetadataEvents;
