/**
 * Just as with the osMap, we may want to normalize our app names
 *
 * Unfortunately "app" could mean either a native application or it could refer
 * to a web browser. In the case of native applications we expect many may be
 * added in the future, and we have a vested interest in tracking every single
 * one without any getting shunted into an "Other" field. But in the case of
 * browsers there are already hundreds if not thousands, we have no control over
 * the creation of new ones, and we absolutely don't mind shunting a bunch into
 * an "Other" field
 *
 * For this reason, usage of this map should be a little bit more nuanced.
 */

const spec = {
	// Google Chrome + Forks
	"Chrome": ["chrome", "chrome webview", "chromium", "chrome headless", "yandex"],

	// Apple Safari + Forks
	"Safari": ["safari", "mobile safari", "webkit"],

	// Internet Explorer + Edge + Forks
	"Edge": ["edge"],
	"Internet Explorer": ["ie", "internet explorer"],

	// Mozilla Firefox + Forks
	"Firefox": ["firefox", "mozilla", "palemoon", "waterfox", "firefox focus", "icedragon"],

	// Device Native
	"Native Browser": ["android browser", "samsung browser", "miui browser"],

	// Other browsers we may care about
	"Opera": ["opera", "opera mini", "opera touch"],
	"Silk": ["silk"],
	"Facebook": ["facebook"],

	// Explicit "Unknown" values
	"Unknown": ["unknown", "unset"]
};

// Convert the above to a hash map for more efficient lookups:

const compiled = {};

Object.keys(spec).forEach((app) =>
{
	spec[app].forEach(str =>
	{
		compiled[str.toLowerCase()] = app;
	});
});

// And return the hash map:

export default compiled;
