import videojs from "video.js";
import "./Plugin.scss";
import FloatingSeekBarSpacer from "./components/FloatingSeekBarSpacer";

/**
 * A basic Video.js plugin.
 */
function FloatingSeekBar()
{
	const player = this;

	player.ready(() =>
	{
		// The third parameter is the index where you want to place this button
		// If left out, it defaults to ControlBar.children_.length
		player.controlBar.addChild(
			new FloatingSeekBarSpacer(player),
			{},
			player.controlBar.children().indexOf(
				player.controlBar.getChild("ProgressControl")
			) + 1
		);
	});
}

// Register the plugin with video.js.
videojs.registerPlugin("behaviorChangeFloatingSeekBar", FloatingSeekBar);

export default FloatingSeekBar;
