import videojs from "video.js";
import Hls from "hls.js";
import StatsForNerdsCloseButton from "./StatsForNerdsCloseButton";

const Component = videojs.getComponent("Component");

const defaults = {
	bitrateWindow: 40
};

function formatNum(num, formInput, decimalsInput)
{
	// In some cases (e.g. live) num will be NaN. Let's give something more user-friendly
	// We'll also round to remove the decimal
	const form = formInput || "";
	const decimals = decimalsInput || 0;
	return isNaN(num) ? "[N / A]" : num.toFixed(decimals) + form;
}

/**
 * TODO: See if there's a better superclass than "Component". Maybe like
 * TODO: "Overlay" or "Window" or something
 *
 * @param {Player|Object} player
 * @param {Object=} options
 * @extends Component
 * @class StatsForNerdsOverlay
 */
class StatsForNerdsOverlay extends Component
{
	/**
	 * Creates an instance of this class.
	 *
	 * @param {Player} player
	 *		The `Player` that this class should be attached to.
	 *
	 * @param {Object} [options={}]
	 *		The key/value store of player options.
	 */
	constructor(player, options = {})
	{
		super(player, options);
		this.player = player;

		this.options = videojs.mergeOptions(defaults, options);

		this.hlsjs = null;
		this.hlsjsStats = null;
		if (videojs.Html5Hlsjs)
		{
			videojs.Html5Hlsjs.addHook("beforeinitialize", (x, hlsjs) =>
			{
				this.hlsjs = hlsjs;
				this.hlsjsStats = {
					bytes: 0,
					bitrateInfoDuration: 0,
					bitrateInfo: [],
					bitrate: null,
					requests: 0,
					requestsComplete: 0,
					requestsAborted: 0,
					requestsTimedout: 0,
					sumLatency: 0,
					latency: null,
					bandwidth: null,
					speed: null
				};
				hlsjs.on(Hls.Events.FRAG_BUFFERED, this.fragBuffered.bind(this));
				hlsjs.on(Hls.Events.FRAG_LOAD_EMERGENCY_ABORTED, this.fragAborted.bind(this));
				hlsjs.on(Hls.ErrorDetails.FRAG_LOAD_TIMEOUT, this.fragTimeout.bind(this));
			});
		}

		this.el_ = this.createEl("div", {
			className: this.buildCSSClass(),
			title: this.localize(this.controlText_)
		});

		// To position our StatsForNerdsOverlay we want position:absolute
		// However to position elements within it, we want position:relative
		this.contentEl_ = videojs.dom.createEl("div", {
			className: "content-wrapper"
		});
		this.el_.appendChild(this.contentEl_);

		this.display = videojs.dom.createEl("pre", {
			className: "vjs-stats-for-nerds-data"
		});
		this.contentEl_.appendChild(this.display);

		this.lastFrame = 0;

		this.boundUpdate = this.update.bind(this);
		setTimeout(this.boundUpdate, 1);

		this.addChild(new StatsForNerdsCloseButton(player, options, this));

		this.hide();
	}

	fragBuffered(evt, data)
	{
		const stats = data.stats;
		const timeOfRequest = stats.trequest;
		const latency = stats.tfirst - timeOfRequest;
		const loadTime = stats.tload - timeOfRequest;
		const bufferTime = stats.tbuffered - timeOfRequest;
		const bytes = stats.total;
		const duration = data.frag.duration;
		const bitrateInfo = this.hlsjsStats.bitrateInfo;

		this.hlsjsStats.requests++;
		this.hlsjsStats.requestsComplete++;
		this.hlsjsStats.bytes += bytes;

		// Bitrate

		bitrateInfo.push({
			bits: bytes * 8,
			duration: duration
		});
		this.hlsjsStats.bitrateInfoDuration += duration;
		while (this.hlsjsStats.bitrateInfoDuration > this.options.bitrateWindow)
		{
			const removed = bitrateInfo.splice(0, 1)[0];
			this.hlsjsStats.bitrateInfoDuration -= removed.duration;
		}
		let sumBits = 0;
		let sumDuration = 0;
		for (let i = 0; i < bitrateInfo.length; i++)
		{
			sumBits += bitrateInfo[i].bits;
			sumDuration += bitrateInfo[i].duration;
		}
		this.hlsjsStats.bitrate = sumBits / sumDuration;

		// Mean Latency

		this.hlsjsStats.sumLatency += latency;
		this.hlsjsStats.latency = this.hlsjsStats.sumLatency / this.hlsjsStats.requestsComplete;

		// Bandwidth

		if (this.hlsjsStats.bandwidth)
		{
			// Harmonic Mean Bandwidth
			// Multiply by 8 to convert bytes/second to bits/second
			// Multiply by 1000 to convert from bits/millisecond to bits/second
			const invBandwidth = (loadTime - latency) / (8 * 1000 * bytes);
			const invPrevBandwidth = 1 / this.hlsjsStats.bandwidth;
			this.hlsjsStats.bandwidth = 2 / (invBandwidth + invPrevBandwidth);
		}
		else
		{
			// We don't have a measurement yet. Use this as our baseline
			this.hlsjsStats.bandwidth = (8 * 1000 * bytes) / (loadTime - latency);
		}

		// System Speed

		if (this.hlsjsStats.speed)
		{
			// Harmonic Mean System Speed
			// Multiply by 8 to convert bytes/second to bits/second
			const invSpeed = (bufferTime - latency) / (8 * 1000 * bytes);
			const invPrevSpeed = 1 / this.hlsjsStats.speed;
			this.hlsjsStats.speed = 2 / (invSpeed + invPrevSpeed);
		}
		else
		{
			// We don't have a measurement yet. Use this as our baseline
			this.hlsjsStats.speed = (8 * 1000 * bytes) / (bufferTime - latency);
		}
	}

	fragAborted()
	{
		this.hlsjsStats.requests++;
		this.hlsjsStats.requestsAborted++;
	}

	fragTimeout()
	{
		this.hlsjsStats.requests++;
		this.hlsjsStats.requestsTimedout++;
	}

	update()
	{
		setTimeout(this.boundUpdate, 1000);

		// TODO: The old player had separated VideoJS and VHS into two separate
		// TODO: "reader" classes. For simplicity I dumped all of the relevant
		// TODO: code into this single block. I would really like to extract
		// TODO: this into separate readers in the future, though.
		var resolution = "[unknown]",
			duration = "[unknown]",
			time = "[unknown]",
			rate = "[unknown]",
			volume = "[unknown]",
			frameRate = "[unknown]",
			decodedFrames = "[unknown]",
			droppedFrames = "[unknown]";

		// Don't want to validate all of these, so just use "try"
		try { resolution = this.player.videoWidth() + "x" + this.player.videoHeight(); }
		catch (e) { /* do nothing */ }
		try { duration = formatNum(this.player.duration(), " s"); }
		catch (e) { /* do nothing */ }
		try { time = formatNum(this.player.currentTime(), " s"); }
		catch (e) { /* do nothing */ }
		try { rate = formatNum(this.player.playbackRate(), "x", 2); }
		catch (e) { /* do nothing */ }
		try { volume = ((this.player.volume() * 100) | 0) + "%" + (this.player.muted() ? " (muted)" : ""); }
		catch (e) { /* do nothing */ }
		try { decodedFrames = formatNum(this.player.getVideoPlaybackQuality().totalVideoFrames); }
		catch (e) { /* do nothing */ }
		try { droppedFrames = formatNum(this.player.getVideoPlaybackQuality().droppedVideoFrames); }
		catch (e) { /* do nothing */ }
		try
		{
			// Frame Rate must be calculated
			var frameDiff = this.player.getVideoPlaybackQuality().totalVideoFrames - this.lastFrame;
			this.lastFrame += frameDiff;
			var timeDiff = (new Date() - this.lastFrameTime) / 1000;
			this.lastFrameTime = new Date();
			frameRate = formatNum(frameDiff / timeDiff, " fps", 2);
		}
		catch (e) { /* do nothing */ }

		const videojsStats = {
			Resolution: resolution,
			Duration: duration,
			FrameRate: frameRate,
			Rate: rate,
			Time: time,
			DecodedFrames: decodedFrames,
			DroppedFrames: droppedFrames,
			Volume: volume,
		};

		const unknown = "[unknown]";

		/*
		const vhsStats = {
			bandwidth: unknown,
			latency: unknown,
			speed: unknown,
			codecs: unknown,
			reps: unknown,
			mediaRequests: unknown,
			mediaRequestsAborted: unknown,
			mediaRequestsTimedout: unknown,
			mediaBytesTransferred: unknown
		};
		*/

		const hlsStats = {
			bandwidth: unknown,
			latency: unknown,
			speed: unknown,
			codecs: unknown,
			reps: unknown,
			bitrate: unknown,
			mediaRequests: unknown,
			mediaRequestsAborted: unknown,
			mediaRequestsTimedout: unknown,
			mediaBytesTransferred: unknown
		};

		// VideoJS HTTP Streaming:

		/*
		if (this.player.tech_.hls)
		{
			// VideoJS recommends calling this.player.tech() instead of using
			// this.player.tech_, however this.player.tech() prints a warning to
			// the console EVERY TIME you call it. That's just annoying.
			const hls = this.player.tech_.hls;
			// This is actually a Getter which runs a rather expensive function
			// Let's cache the results
			const stats = hls.stats;

			vhsStats.bandwidth = formatNum(hls.bandwidth / 1024, " Kbps");
			// vhsStats.latency = -1;
			vhsStats.speed = formatNum(hls.systemBandwidth / 1024, " Kbps");
			vhsStats.codecs = hls.playlists.media().attributes.CODECS;
			vhsStats.reps = hls.representations().length;
			vhsStats.mediaRequests = stats.mediaRequests;
			vhsStats.mediaRequestsAborted = stats.mediaRequestsAborted;
			vhsStats.mediaRequestsTimedout = stats.mediaRequestsTimedout;
			vhsStats.mediaBytesTransferred = stats.mediaBytesTransferred;
		}
		*/

		// HLS.js:
		if (this.hlsjs)
		{
			const hls = this.hlsjs;

			// If we haven't loaded yet, we'll have no levels
			if (!hls.levels)
				return;

			// Sometimes (no idea why) level is undefined
			// It's intermittent and usually if it happens on the very next
			// frame it works fine
			// Let's just cowardly refuse to update the stats until we get a
			// good value
			const level = hls.levels[hls.currentLevel];

			if (!level)
				return;

			hlsStats.bandwidth = formatNum(this.hlsjsStats.bandwidth / 1024, " Kbps");
			hlsStats.latency = formatNum(this.hlsjsStats.latency, "ms");
			hlsStats.speed = formatNum(this.hlsjsStats.speed / 1024, " Kbps");
			hlsStats.codecs = `${level.videoCodec}, ${level.audioCodec}`;
			hlsStats.reps = hls.levels.length;
			hlsStats.bitrate = formatNum(this.hlsjsStats.bitrate / 1024, " Kbps");
			hlsStats.mediaRequests = this.hlsjsStats.requests;
			hlsStats.mediaRequestsAborted = this.hlsjsStats.requestsAborted;
			hlsStats.mediaRequestsTimedout = this.hlsjsStats.requestsTimedout;
			hlsStats.mediaBytesTransferred = formatNum(this.hlsjsStats.bytes / 1024 / 1024, " MB", 3);
		}

		var arr = [];
		arr.push("--- VideoJS ---");
		for (const key in videojsStats)
			if (Object.prototype.hasOwnProperty.call(videojsStats, key))
				arr.push(key + ": " + videojsStats[key]);
		arr.push("");

		/*
		if (this.player.tech_.hls)
		{
			arr.push("--- VHS ---");
			for (const key in vhsStats)
				if (Object.prototype.hasOwnProperty.call(vhsStats, key))
					arr.push(key + ": " + vhsStats[key]);
			arr.push("");
		}
		*/

		if (this.hlsjs)
		{
			arr.push("--- HLS ---");
			for (const key in hlsStats)
				if (Object.prototype.hasOwnProperty.call(hlsStats, key))
					arr.push(key + ": " + hlsStats[key]);
			arr.push("");
		}

		if (videojs.browser.IS_IOS)
		{
			arr.push("--- HLS stats not available on iOS ---");
			arr.push("");
		}

		this.display.innerHTML = arr.join("\n");
	}

	/**
	 * Builds the default DOM `className`.
	 *
	 * @return {string}
	 *		The DOM `className` for this object.
	 */
	buildCSSClass()
	{
		return `vjs-stats-for-nerds-overlay ${super.buildCSSClass()}`;
	}
}

/**
 * The text that should display over the `StatsForNerdsOverlay`s controls. Added for localization.
 *
 * @type {string}
 * @private
 */
StatsForNerdsOverlay.prototype.controlText_ = "Stats";

Component.registerComponent("statsForNerdsOverlay", StatsForNerdsOverlay);

export default StatsForNerdsOverlay;
