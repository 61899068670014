import videojs from "video.js";
import "./Plugin.scss";
import InfoButtonPluginButton from "./components/InfoButton";

const Plugin = videojs.getPlugin("plugin");

// Default options for the plugin.
const defaults = {};

/**
 * An advanced Video.js plugin. For more information on the API
 *
 * See: https://blog.videojs.com/feature-spotlight-advanced-plugins/
 */
class Info extends Plugin
{

	/**
	 * Create a InfoButtonPlugin plugin instance.
	 *
	 * @param  {Player} player
	 *         A Video.js Player instance.
	 *
	 * @param  {Object} [options]
	 *         An optional options object.
	 *
	 *         While not a core part of the Video.js plugin architecture, a
	 *         second argument of options is a convenient way to accept inputs
	 *         from your plugin's caller.
	 */
	constructor(player, options)
	{
		// The parent class will add player under this.player
		super(player);

		this.options = videojs.mergeOptions(defaults, options);

		player.ready(() =>
		{
			// Player UI components cannot be registered until the player is "ready"
			// Before then, "controlBar" is undefined.
			// The third parameter is the index where you want to place this button
			// If left out, it defaults to ControlBar.children_.length
			player.controlBar.addChild(
				new InfoButtonPluginButton(player, options),
				options,
				this.player.controlBar.children().indexOf(
					this.player.controlBar.getChild("FullscreenToggle")
				)
			);
		});
	}
}

// Register the plugin with video.js.
videojs.registerPlugin("controlBarButtonInfo", Info);

export default Info;
