import videojs from "video.js";

const Component = videojs.getComponent("Component");
const Button = videojs.getComponent("Button");

/**
 * The base class for buttons
 *
 * @param {Player|Object} player
 * @param {Object=} options
 * @extends Button
 * @class AutoplayMuteOverlay
 */
class AutoplayMuteOverlay extends Button
{
	/**
	 * Creates an instance of this class.
	 *
	 * @param {Player} player
	 *		The `Player` that this class should be attached to.
	 *
	 * @param {Object} [options={}]
	 *		The key/value store of player options.
	 */
	constructor(player, options = {})
	{
		super(player, options);
		this.player = player;
	}

	/**
	 * Builds the default DOM `className`.
	 *
	 * @return {string}
	 *		The DOM `className` for this object.
	 */
	buildCSSClass()
	{
		return `vjs-autoplay-mute-overlay ${super.buildCSSClass()}`;
	}
}

/**
 * The text that should display over the `AutoplayMuteOverlay`s controls. Added
 * for localization.
 * NOTE: Because "Unmute" is one of the default phrases in VideoJS, we don't
 * need to create custom lang files.
 *
 * @type {string}
 * @private
 */
AutoplayMuteOverlay.prototype.controlText_ = "Unmute";

Component.registerComponent("autoplayMuteOverlay", AutoplayMuteOverlay);

export default AutoplayMuteOverlay;
