/**
 * Abstracts away the details of LocalStorage (or any other caching method we
 * may choose to use in the future), and centralizes it to deduplicate the code
 * across all of our plugins
 */

let enabled = true;

function enable()
{
	enabled = true;
}

function disable()
{
	enabled = false;
}

function get(key, defaultValue)
{
	if (!enabled) return defaultValue;

	var returnValue = null;
	try
	{
		returnValue = JSON.parse(localStorage.getItem("bf_" + key));
	}
	catch (e)
	{
		/* do nothing */
	}
	return returnValue === null ? defaultValue : returnValue;
}

function set(key, value)
{
	if (!enabled) return value;

	try
	{
		localStorage.setItem("bf_" + key, JSON.stringify(value));
	}
	catch (e)
	{
		/* do nothing */
	}
	return value;
}

function generateGUID ()
{
	return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(char)
	{
		var random = Math.random() * 16 | 0;
		var value = char === "x" ? random : (random & 0x3) | 0x8;
		return value.toString(16);
	});
}

module.exports = {
	get: get,
	set: set,
	enable: enable,
	disable: disable,
	guid: generateGUID
};
