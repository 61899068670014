import videojs from "video.js";

const Component = videojs.getComponent("Component");
const MenuItem = videojs.getComponent("MenuItem");

/**
 * The base class for buttons
 *
 * @param {Player|Object} player
 * @param {Object=} options
 * @extends MenuItem
 * @class StatsForNerdsMenuItem
 */
class StatsForNerdsMenuItem extends MenuItem
{
	constructor(player, options)
	{
		super(player, options);

		this.overlay = player.getChild("StatsForNerdsOverlay");
	}

	handleClick()
	{
		super.handleClick();

		// TODO: Toggle display instead of just showing
		this.overlay.show();
	}

	/**
	 * Builds the default DOM `className`.
	 *
	 * @return {string}
	 *		The DOM `className` for this object.
	 */
	buildCSSClass()
	{
		// ! For some stupid reason, VideoJS doesn't seem to call this method?
		return `vjs-stats-for-nerds-menu-item ${super.buildCSSClass()}`;
	}
}

/**
 * The text for this MenuItem Added for localization.
 *
 * @type {string}
 * @private
 */
StatsForNerdsMenuItem.prototype.options_ = {label: "Stats"};

Component.registerComponent("statsForNerdsMenuItem", StatsForNerdsMenuItem);

export default StatsForNerdsMenuItem;
