import videojs from "video.js";

const Component = videojs.getComponent("Component");

// Default options for the plugin.
const defaults = {
	buttonText: "Button"
};

/**
 * @param {Player|Object} player
 * @param {Object=} options
 * @extends Component
 * @class ClipButton
 */
class ClipButton extends Component
{
	constructor(player, options = {})
	{
		super(player, options);
		this.player = player;
		this.options = videojs.mergeOptions(defaults, options);

		this.el_.innerHTML = this.options.buttonText;
	}

	createEl(tag, props = {}, attributes = {})
	{
		return Component.prototype.createEl.call(
			this,
			"button",
			videojs.mergeOptions({
				className: this.buildCSSClass()
			}, props),
			videojs.mergeOptions({type: "button"}, attributes)
		);
	}

	/**
	 * Builds the default DOM `className`.
	 *
	 * @return {string}
	 *		The DOM `className` for this object.
	 */
	buildCSSClass()
	{
		return `vjs-clip-button ${super.buildCSSClass()}`;
	}
}

ClipButton.prototype.controlText_ = "Button";

Component.registerComponent("clipButton", ClipButton);

export default ClipButton;
