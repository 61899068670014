import videojs from "video.js";
import ReleaseNotesMenuItem from "./components/ReleaseNotesMenuItem";

const Plugin = videojs.getPlugin("plugin");

// Default options for the plugin.
const defaults = {
	enable: true
};

/**
 * An advanced Video.js plugin. For more information on the API
 *
 * See: https://blog.videojs.com/feature-spotlight-advanced-plugins/
 */
class ReleaseNotes extends Plugin
{

	/**
	 * Create a ReleaseNotes plugin instance.
	 *
	 * @param  {Player} player
	 *         A Video.js Player instance.
	 *
	 * @param  {Object} [options]
	 *         An optional options object.
	 *
	 *         While not a core part of the Video.js plugin architecture, a
	 *         second argument of options is a convenient way to accept inputs
	 *         from your plugin's caller.
	 */
	constructor(player, options)
	{
		// The parent class will add player under this.player
		super(player);

		this.options = videojs.mergeOptions(defaults, options);

		if (this.options.enable)
		{
			this.player.ready(() =>
			{
				const menu = player.controlBar.getChild("InfoButton").getChild("InfoMenu");
				menu.addItem(new ReleaseNotesMenuItem(player, options));
			});
		}
	}
}

// Register the plugin with video.js.
videojs.registerPlugin("infoMenuOptionReleaseNotes", ReleaseNotes);

export default ReleaseNotes;
