import videojs from "video.js";
import ToLiveButton from "./components/ToLiveButton";

import "./Plugin.scss";

const Plugin = videojs.getPlugin("plugin");

// Default options for the plugin.
const defaults = {
	enabled: true,
	// Previously this was hard-coded to be disabled on Android, Edge, and IE
	// The claim was that "these browsers were tested to not support DVR"
	// Things may have changed in recent years. I just tested in Edge and DVR
	// worked perfectly. So for the time being I'm going to comment this out and
	// if any issues are found with DVR then hopefully they'll be kicked back by
	// our testing
	// !videojs.browser.IS_ANDROID && !videojs.browser.IS_EDGE && !videojs.browser.IE_VERSION,
	supportsLiveDVR: true,
	// If you're within this many seconds, we count is as "live"
	liveThreshold: 10,
	iosUpdateInterval: 10
};

/**
 * An advanced Video.js plugin. For more information on the API
 *
 * By default in VideoJS the progress bar is only displayed if the duration of
 * the video is finite. This makes sense, as the far left-side of the progress
 * bar is "0" and the far right side is the duration. So an infinite duration
 * means that the bar must either be infinitely long, or every single point on
 * the bar is undefined.
 *
 * Therefore in order to seek during a live broadcast (Live DVR), we need to
 * ensure that the duration is always finite. Pretty simple.
 *
 * Since the duration can only be set using the VideoJS API, by intercepting
 * requests to this API call we can force our own duration on the player.
 *
 * See: https://blog.videojs.com/feature-spotlight-advanced-plugins/
 */
class LiveDVR extends Plugin
{

	/**
   * Create a LiveDVR plugin instance.
   *
   * @param  {Player} player
   *         A Video.js Player instance.
   *
   * @param  {Object} [options]
   *         An optional options object.
   *
   *         While not a core part of the Video.js plugin architecture, a
   *         second argument of options is a convenient way to accept inputs
   *         from your plugin's caller.
   */
	constructor(player, options)
	{
		// The parent class will add player under this.player
		super(player);

		this.options = videojs.mergeOptions(defaults, options);
		this._isLive = false;

		player.ready(() =>
		{
			if (!this.options.enabled)
				return;

			const controlBar = this.player.controlBar;
			this.remainingTimeDisplay = controlBar.remainingTimeDisplay;

			this.toLiveButton = new ToLiveButton(this.player, this.options);
			controlBar.addChild(
				this.toLiveButton,
				this.options,
				// The "To Live" button should come immediately before the remaining time display
				controlBar.children().indexOf(this.remainingTimeDisplay)
			);

			player.on("timeupdate", this.onPlayerTimeUpdate.bind(this));

			player.addClass("vjs-live-dvr");
		});

		player._liveDVRIsActive = true;
	}

	updateTimeDisplay(isAtLive)
	{
		if (this.remainingTimeDisplay)
		{
			if (isAtLive) this.remainingTimeDisplay.hide(); else this.remainingTimeDisplay.show();
		}
	}

	updateLiveDisplay(isAtLive)
	{
		// The to live button shows even if live DVR isn't supported, but it's just a placebo
		if (this.toLiveButton)
		{
			if (this._isLive) this.toLiveButton.show(); else this.toLiveButton.hide();
			this.toLiveButton.atLive(isAtLive);
		}
	}

	onPlayerTimeUpdate()
	{
		var isAtLive = this.atLive();
		this.updateLiveDisplay(isAtLive);
		this.updateTimeDisplay(isAtLive);
	}

	isLive(value)
	{
		this._isLive = value;
		this.onPlayerTimeUpdate();
	}

	// https://github.com/videojs/videojs-contrib-hls/issues/826
	atLive()
	{
		// Definitely not live
		if (!this._isLive) return false;

		const duration = this.player.duration();

		// Definitely live
		if (!isFinite(duration)) return true;

		// We're "live" if within this delay
		return duration - this.player.currentTime() < this.options.liveThreshold;
	}
}

let iosDurationUpdateTimer = null;

videojs.use("*", function (player)
{
	return {
		duration: function (duration)
		{
			if (player._liveDVRIsActive)
			{
				const liveDVRPlugin = player.liveDVR();
				const liveDVROptions = liveDVRPlugin.options;

				if (duration === Infinity)
				{
					liveDVRPlugin.isLive(true);

					if (liveDVROptions.enabled && liveDVROptions.supportsLiveDVR)
					{
						// It's the job of the HLS plugin to maintain a buffer
						// from live. VHS has a hard-coded 30-second buffer.
						// HLS.js is configurable (our "temporal_delay"
						// player_config). "seekable" excludes this buffer
						const seekable = player.seekable();

						if (seekable.length)
						{
							// If we're within live, we don't want the progress
							// bar to jump randomly when a TS file is downloaded
							const newDuration = seekable.end(seekable.length - 1);
							const currentTime = player.currentTime();

							if (newDuration < currentTime)
							{
								return currentTime;
							}
							else
							{
								return newDuration;
							}
						}
						else if (videojs.browser.IS_IOS)
						{
							if (iosDurationUpdateTimer)
							{
								clearInterval(iosDurationUpdateTimer);
							}

							player.one("timeupdate", () =>
							{
								const newSeekable = player.seekable();
								player.duration(newSeekable.end(newSeekable.length - 1));
							});

							iosDurationUpdateTimer = setInterval(() =>
							{
								const newSeekable = player.seekable();
								player.duration(newSeekable.end(newSeekable.length - 1));
							}, liveDVROptions.iosUpdateInterval * 1000);
						}
						else
						{
							// If the video isn't seekable, we don't have a valid
							// manifest. We can't do anything with this.
							return 0;
						}
					}
				}
				else
				{
					liveDVRPlugin.isLive(false);
				}
			}
			return duration;
		}
	};
});

// Register the plugin with video.js.
videojs.registerPlugin("liveDVR", LiveDVR);

export default LiveDVR;
