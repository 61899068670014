import videojs from "video.js";
import ErrorOverlay from "./components/ErrorOverlay";
import "./Plugin.scss";

const logger = videojs.createLogger("DetectErrors");
const Plugin = videojs.getPlugin("plugin");

// Default options for the plugin.
const defaults = {
	// Seconds to wait before assuming the video is broken
	threshold: 5,
	// Should be approximatley 1 TS file
	corruptionSkipLimit: 10
};

/**
 * An advanced Video.js plugin. For more information on the API
 *
 * See: https://blog.videojs.com/feature-spotlight-advanced-plugins/
 */
class DetectErrors extends Plugin
{

	/**
   * Create a DetectErrors plugin instance.
   *
   * @param  {Player} player
   *         A Video.js Player instance.
   *
   * @param  {Object} [options]
   *         An optional options object.
   *
   *         While not a core part of the Video.js plugin architecture, a
   *         second argument of options is a convenient way to accept inputs
   *         from your plugin's caller.
   */
	constructor(player, options)
	{
		// The parent class will add player under this.player
		super(player);
		this.options = videojs.mergeOptions(defaults, options);

		this.watchdog = null;
		this.boundTestStream = this.testStream.bind(this);

		this.buffering = false;

		this.lastCurrentTime = player.currentTime();
		this.lastBufferedPercent = 0;

		// To avoid flooding the logs
		this.expectedNonMovement = false;

		this.errorDetectors = [];

		this.overlay = new ErrorOverlay(player, options);
		player.addChild(this.overlay);

		this.hasEverEnded = false;

		this.lastRecoveryAttempt = -1;

		// Temporarily disabled on iPad while figuring some things out
		if (!videojs.browser.IS_IPAD)
		{
			player.ready(() =>
			{
				// If the video is in an error state, there's a chance it won't ever
				// throw a "stalled" or "waiting" event. Instead, we'll poll the video
				// every second to see if it's chugging along as expected
				this.watchdog = setTimeout(this.boundTestStream, 1000);

				player.on("ended", () => { this.hasEverEnded = true; });
				player.on("contentchanged", () => { this.hasEverEnded = false; });
			});
		}

		player.validateBroadcast = this.detectErrors.bind(this);
	}

	testStream()
	{
		// If this is bound, it no longer should be
		this.player.off("timeupdate", this.boundTestStream);

		// If we're in error mode, the stream is moving, so let's stop it
		this.player.error(null);

		// First, see if the player has moved at all in the last second
		const currentTime = this.player.currentTime();

		if (currentTime === this.lastCurrentTime)
		{
			if (!this.expectedNonMovement)
				logger.debug("Stream has not moved");

			// Log this one specific weird csae
			if (this.player.ended() && !this.hasEverEnded)
			{
				logger.error("Player in an ended state, but ended event was never thrown");
			}

			// The time hasn't moved, but that doesn't mean things are broken.
			// The video could be buffering, paused, or ended
			const expected =
				this.player.paused() ||
				(this.hasEverEnded && this.player.ended()) ||
				// networkState 0 = "uninitialized" (player isn't ready)
				// networkState 1 = "idle" (video selected, but haven't loaded)
				// networkState 2 = "loading" (video is playing)
				// networkState 3 = "no_source" (video data does not exist)
				this.player.networkState() !== 2 ||
				(this.player.ads && this.player.ads.inAdBreak()) ||
				this.player.currentTime() >= this.player.duration() - 1
			;

			if (expected)
			{
				if (!this.expectedNonMovement)
					logger.debug(
						"This was expected. " +
						`Paused: ${this.player.paused()}, ` +
						`Ended: ${this.player.ended()}, ` +
						`NetworkState: ${this.player.networkState()}, ` +
						`InAdBreak: ${this.player.ads ? this.player.ads.inAdBreak() : false}`
					);

				this.expectedNonMovement = true;
			}
			else
			{
				if (this.expectedNonMovement)
					logger.debug("Stream state changed, but time did not");

				logger.debug(
					"This was NOT expected. " +
					`Paused: ${this.player.paused()}, ` +
					`Ended: ${this.player.ended()}, ` +
					`NetworkState: ${this.player.networkState()}, ` +
					`InAdBreak: ${this.player.ads ? this.player.ads.inAdBreak() : false}`
				);
				// Just because we had an unexpected freeze doesn't mean the
				// video is corrupt. We might just be buffering. Watch the
				// buffer for a few seconds to see if we get any new data
				if (this.buffering)
				{
					logger.debug("We were already in a buffering state. How did we get here?");
				}
				else
				{
					this.lastBufferedPercent = this.player.bufferedPercent();
					logger.debug(
						"Entering a buffering state. " +
						`Will check for more data in ${this.options.threshold} seconds. ` +
						`lastCurrentTime: ${this.lastCurrentTime}, lastBufferedPercent: ${this.lastBufferedPercent}`
					);
					this.buffering = setTimeout(this.checkForRecovery.bind(this), this.options.threshold * 1000);
				}
			}
		}
		else
		{
			// The clock moved, therefore we expect it to continue moving
			if (this.expectedNonMovement)
				logger.debug("Stream has started moving again.");
			this.expectedNonMovement = false;
		}

		if (!this.buffering)
		{
			this.lastCurrentTime = currentTime;
			this.watchdog = setTimeout(this.boundTestStream, 1000);
			this.overlay.hide();
		}
	}

	checkForRecovery()
	{
		logger.debug("Checking for recovery from buffering.");
		this.buffering = false;

		// Check if we recovered, either via currentTime or bufferedPercent
		const currentTime = this.player.currentTime();
		const bufferedPercent = this.player.bufferedPercent();
		logger.debug(
			`currentTime: ${currentTime}, lastCurrentTime: ${this.lastCurrentTime}, ` +
			`bufferedPercent: ${bufferedPercent}, lastBufferedPercent: ${this.lastBufferedPercent}`
		);
		if (
			currentTime === this.lastCurrentTime &&
			bufferedPercent === this.lastBufferedPercent
		)
		{
			// We did not
			logger.error("Failed to recover");
			// this.overlay.displayMessage("There is an issue playing your stream. Please try reloading the page.");

			// Dim the content, hide the controls, and set "vjs-error" class
			// Plus inform other plugins of the error so they can handle it
			this.player.trigger("error");

			// Wait until the clock starts moving to resume our watchdog
			this.player.on("timeupdate", this.boundTestStream);

			// Dump to the console whatever errors we find in the video
			this.detectErrors();
		}
		else
		{
			// We did recover, so restart our watchdog
			logger.debug("Recovered. Resuming WatchDog.");
			this.testStream();
		}
	}

	async detectErrors()
	{
		const errors = Array.prototype.concat.apply(
			[],
			await Promise.all(this.errorDetectors.map(detector => detector.test()))
		);

		if (errors.length)
		{
			// We detected known failure states with this video

			// Get a mapping of rendition indexes to renditions, for possible
			// later use
			const renditions = {};
			const qualityLevels = this.player.qualityLevels && this.player.qualityLevels();
			const currentTime = this.player.currentTime();
			let recoverable = true;
			let seekTo = currentTime;
			if (qualityLevels)
			{
				for (let i = 0; i < qualityLevels.length; i++)
				{
					const level = qualityLevels[i];
					renditions[level.id] = {
						ptr: level,
						disabled: false
					};
				}
			}
			for (let i = 0; i < errors.length; i++)
			{
				const error = errors[i];

				// Log the error
				logger.error(`INVALID VIDEO: ${errors[i].msg}`);

				// Track if all errors are recoverable
				recoverable = recoverable && error.recoverable;

				// If any renditions are corrupt, disable them
				if (typeof error.corruptRendition === "number")
				{
					const corruptRendition = renditions[error.corruptRendition];
					// Only disable each rendition once
					if (corruptRendition && !corruptRendition.disabled)
					{
						logger.warn(`Disabling corrupt rendition (${corruptRendition.ptr.height}p)`);
						qualityLevels.removeQualityLevel(corruptRendition.ptr);
						corruptRendition.disabled = true;
					}
				}

				// If any TimeRanges are corrupt, seek past them
				const range = error.corruptRange;
				if (range)
				{
					logger.warn(`Corrupt range ${range.start(0)} - ${range.end(0)} (vs. ${seekTo})`);
					if (
						// If the corrupt range is in the past, ignore it
						range.end(0) > seekTo
						&&
						// If the corrupt range is too far in the future, ignore it
						range.start(0) < seekTo + this.options.corruptionSkipLimit
					)
					{
						// Seek PAST the corruption
						seekTo = range.end(0) + this.options.corruptionSkipLimit;
					}
				}
			}

			// TODO: We need to send these errors somewhere
			// ! We need to send these errors somewhere
			// // We need to send these errors somewhere

			if (!recoverable)
			{
				// We can't fix the video. Put up an error message
				this.displayFailure();
				return;
			}
			else if (seekTo === currentTime)
			{
				// We don't need to seek, but the error SHOULD be recoverable...
				// So just pause and play?
				if (this.lastRecoveryAttempt === currentTime)
				{
					// We already tried to recover, and failed
					this.displayFailure();
					return;
				}
				else
				{
					this.player.pause();
					this.player.play();
					this.lastRecoveryAttempt = currentTime;
				}
			}
			else
			{
				// Try to seek past the corruption to fix the video
				logger.warn(`Seeking to ${seekTo} to try and bypass corrupt video`);
				this.player.currentTime(seekTo);
				// Sometimes trying to seek while in an error state triggers a
				// media reload. Let's check for this.
				this.boundSeek = () =>
				{
					logger.warn("Re-firing seek due to loadedmetadata");
					this.player.currentTime(seekTo);
					this.player.pause();
					this.player.play();
				};
				this.player.one("loadedmetadata", this.boundSeek);
				setTimeout(() =>
				{
					logger.warn("Failed to seek");
					this.player.off("loadedmetadata", this.boundSeek);
					this.displayFailure();
				}, 1000);
			}
		}
		else
		{
			logger.error("REASON UNKNOWN (no ErrorDetection plugins returned results)");
		}
	}

	displayFailure()
	{
		this.overlay.displayMessage("There is an issue playing your stream. Please try reloading the page.");
	}

	register(detector)
	{
		this.errorDetectors.push(detector);
	}
}

// Register the plugin with video.js.
videojs.registerPlugin("detectErrors", DetectErrors);

export default DetectErrors;
