import videojs from "video.js";

import Loader from "./Loader";

/**
 * A basic Video.js plugin.
 */
function HLSJSLoader()
{
	if (videojs.Html5Hlsjs)
	{
		videojs.Html5Hlsjs.addHook("beforeinitialize", (x, hlsjs) =>
		{
			hlsjs.config.loader = Loader;
		});
	}
}

// Register the plugin with video.js.
videojs.registerPlugin("behaviorChangeHLSJSLoader", HLSJSLoader);

export default HLSJSLoader;
