import videojs from "video.js";

/**
 * A basic Video.js plugin.
 */
function HideEmtpyCaptions()
{
	const player = this;

	if (videojs.browser.IS_IOS)
	{
		player.on("texttrackchange", handleTracksChanged);
	}

	function handleTracksChanged()
	{
		var textTracks = player.textTracks();
		for (var i = 0; i < textTracks.length; i++)
		{
			var track = textTracks[i];
			// TODO: Verify that these three checks are sufficient and we don't
			// TODO: accidentally remove legitimate captions or subtitles
			if (track.kind === "captions" && track.label === "" && track.language === "")
			{
				textTracks.removeTrack(track);
			}
		}
	}
}

// Register the plugin with video.js.
videojs.registerPlugin("behaviorChangeHideEmtpyCaptions", HideEmtpyCaptions);

export default HideEmtpyCaptions;
