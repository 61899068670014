import videojs from "video.js";
import ClipScrubber from "./ClipScrubber";
import ClipThumbnail from "./ClipThumbnail";

const Component = videojs.getComponent("Component");
const TimeTooltip = videojs.getComponent("TimeTooltip");

// Default options for the plugin.
const defaults = {
	defaultClipLength: 10,
	seekDelay: 500,
	progressBarRange: 300,
	thumbnailQuality: {
		height: 1080,
		width: 1920
	}
};

/**
 * @param {Player|Object} player
 * @param {Object=} options
 * @extends Component
 * @class ClipProgressBar
 */
class ClipProgressBar extends Component
{
	/**
	 * Creates an instance of this class.
	 *
	 * @param {Player} player
	 *		The `Player` that this class should be attached to.
	 *
	 * @param {Object} [options={}]
	 *		The key/value store of player options.
	 */
	constructor(player, options = {})
	{
		super(player, options);
		this.options = videojs.mergeOptions(defaults, options);
		this.player = player;

		this.startTimeDisplay = new TimeTooltip(player);
		this.endTimeDisplay = new TimeTooltip(player);
		this.addChild(this.startTimeDisplay);
		this.addChild(this.endTimeDisplay);

		this.buildCSSClass()
			.trim()
			.split(" ")
			.forEach(cls => this.addClass(cls));
		this.el_.title = this.localize(this.controlText_);

		this.doc = this.el_.ownerDocument;
		this.boundEndScrubbing = this.endScrubbing.bind(this);
		this.boundHandleScrub = this.handleScrub.bind(this);

		this.startScrubber = new ClipScrubber(player, {className: "vjs-clip-start-scrubber"});
		this.addChild(this.startScrubber);
		this.endScrubber = new ClipScrubber(player, {className: "vjs-clip-end-scrubber"});
		this.addChild(this.endScrubber);

		// 1. A click on the start scrubber will trigger if they click to the
		// left of the start scrubber
		this.startScrubber.on(["mousedown", "touchstart"], this.clickLeft.bind(this));

		// 2. A click on the end scrubber will trigger if they click between
		// the start and end scrubbers
		this.endScrubber.on(["mousedown", "touchstart"], this.clickMid.bind(this));

		// 3. A click on neither (which will hit the parent element) will
		// trigger if they click to the right of the end scrubber
		this.on(["mousedown", "touchstart"], this.clickRight.bind(this));

		// Start with the end scrubber active
		this.activateScrubber("end");

		// Keep a thumbnail to place over the "inactive" scrubber
		this.thumbnail = new ClipThumbnail();

		this.seekTimeout = null;
		this.boundPerformSeek = this.performSeek.bind(this);

		this.hide();
	}

	show()
	{
		super.show();

		const currentTime = this.player.currentTime();
		const duration = this.player.duration();
		const startTime = Math.max(0, currentTime - this.options.defaultClipLength);
		const endTime = Math.min(duration, currentTime);

		// Establish the start and end of the scrub bar
		const range = this.options.progressBarRange;
		if (currentTime < range / 2)
		{
			// We're too early in the video, so show the first X minutes
			this.barStart = 0;
			this.barEnd = Math.min(range, duration);
		}
		else
		{
			// We MAY be too late in the video, so set the end first
			this.barEnd = Math.min(currentTime + (range / 2), duration);
			this.barStart = Math.max(this.barEnd - range, 0);
		}
		this.startScrubber.setRange(this.barStart, this.barEnd);
		this.endScrubber.setRange(this.barStart, this.barEnd);

		// Display range on progress bar
		this.startTimeDisplay.updateTime(this.el_.getBoundingClientRect(), 1, this.barStart);
		this.endTimeDisplay.updateTime(this.el_.getBoundingClientRect(), 1, this.barEnd);
		// The TimeTooltip element assumes it will be on the far-right of the progress bar
		// We need to move the startTimeDisplay appropriately
		window.requestAnimationFrame(() =>
		{
			this.startTimeDisplay.el_.style.left = this.startTimeDisplay.el_.style.right;
			this.startTimeDisplay.el_.style.right = null;
		});

		// Default the scrubber positions
		this.startScrubber.setTime(startTime);
		this.trigger("scrub", {scrubber: "start", time: startTime});
		this.endScrubber.setTime(endTime);
		this.trigger("scrub", {scrubber: "end", time: endTime});

		// Hide the thumbnail at first
		this.startScrubber.removeChild(this.thumbnail);
		this.endScrubber.removeChild(this.thumbnail);
	}

	clickLeft(evt)
	{
		// Stop propagation (so we don't trigger clickRight)
		evt.stopPropagation();

		// Activate the start scrubber
		this.activateScrubber("start");

		// Begin scrubbing
		this.beginScrubbing(evt);
	}

	clickMid(evt)
	{
		// Stop propagation (so we don't trigger clickRight)
		evt.stopPropagation();

		// It's possible they clicked the actual scrubber and not the bar
		// We need to check for this
		const bounds = evt.target.getBoundingClientRect();
		if (evt.clientY < bounds.top)
		{
			this.activateScrubber("end");
		}

		// Begin scrubbing
		this.beginScrubbing(evt);
	}

	clickRight(evt)
	{
		// For consistency
		evt.stopPropagation();

		// Activate the end scrubber
		this.activateScrubber("end");

		// Begin scrubbing
		this.beginScrubbing(evt);
	}

	activateScrubber(scrubber)
	{
		if (this.active)
		{
			this.active.removeClass("active");
		}

		if (scrubber === "start")
		{
			this.startScrubber.removeChild(this.thumbnail);
			if (this.active === this.endScrubber && this.updateThumbnail())
			{
				// We're swapping scrubbers, set up the thumbnail
				this.endScrubber.addChild(this.thumbnail);
			}
			this.active = this.startScrubber;
		}
		else
		{
			this.endScrubber.removeChild(this.thumbnail);
			if (this.active === this.startScrubber && this.updateThumbnail())
			{
				// We're swapping scrubbers, set up the thumbnail
				this.startScrubber.addChild(this.thumbnail);
			}
			this.active = this.endScrubber;
		}

		this.active.addClass("active");

		this.trigger("activateScrubber", scrubber);
	}

	updateThumbnail()
	{
		if (!this.player.paused())
		{
			// We're probably in the middle of playing a preview. We can't
			// grab an accurate thumbnail if the player is moving
			return false;
		}

		// TODO: This should probably be a method on the thumbnail
		const vid = this.player.tech_.el_;
		const ctx = this.thumbnail.el_.getContext("2d");
		ctx.drawImage(
			vid,
			0,
			0,
			vid.videoWidth,
			vid.videoHeight,
			0,
			0,
			this.options.thumbnailQuality.width,
			this.options.thumbnailQuality.height
		);

		return true;
	}

	beginScrubbing(evt)
	{
		this.active.scrubStart(evt);
		this.on(this.doc, "mouseup", this.boundEndScrubbing);
		this.on(this.doc, "touchend", this.boundEndScrubbing);

		this.active.on("scrub", this.boundHandleScrub);
	}

	endScrubbing()
	{
		this.active.scrubEnd();
		this.off(this.doc, "mouseup", this.boundEndScrubbing);
		this.off(this.doc, "touchend", this.boundEndScrubbing);

		this.active.off("scrub", this.boundHandleScrub);

		if (this.active === this.startScrubber)
		{
			this.trigger("scrub", {scrubber: "start", time: this.active.getTime()});
		}
		else
		{
			this.trigger("scrub", {scrubber: "end", time: this.active.getTime()});
		}

		this.performSeek();
	}

	handleScrub(evt, newTime)
	{
		if (this.active === this.startScrubber)
		{
			if (newTime > this.endScrubber.getTime() - 2)
			{
				// We tried to move the start beyond the end
				this.endScrubber.setTime(newTime + 2);
				// Update the input
				this.trigger("scrub", {scrubber: "end", time: newTime + 2});
				// And remove the (now inaccurate) thumbnail
				this.endScrubber.removeChild(this.thumbnail);
			}

			this.trigger("scrub", {scrubber: "start", time: newTime});
		}

		if (this.active === this.endScrubber)
		{
			if (newTime < this.startScrubber.getTime() + 2)
			{
				// We tried to move the end beyond the start
				this.startScrubber.setTime(newTime - 2);
				// Update the input
				this.trigger("scrub", {scrubber: "start", time: newTime - 2});
				// And remove the (now inaccurate) thumbnail
				this.startScrubber.removeChild(this.thumbnail);
			}

			this.trigger("scrub", {scrubber: "end", time: newTime});
		}

		this.startSeekTimer();
	}

	startSeekTimer()
	{
		if (this.seekTimeout)
		{
			clearTimeout(this.seekTimeout);
		}

		this.seekTimeout = setTimeout(this.boundPerformSeek, this.options.seekDelay);
	}

	performSeek()
	{
		if (this.seekTimeout)
		{
			clearTimeout(this.seekTimeout);
			this.seekTimeout = null;
		}

		this.player.currentTime(this.active.getTime());
	}

	setTime(newTime, scrubber)
	{
		let updateScrubber = null;

		// Adjust the appropriate scrubber
		if (scrubber === "start")
		{
			updateScrubber = this.startScrubber;
		}
		else if (scrubber === "end")
		{
			updateScrubber = this.endScrubber;
		}
		else
		{
			updateScrubber = this.active;
		}

		// Adjust the appropriate scrubber
		if (Math.abs(updateScrubber.getTime() - newTime) > 1)
		{
			// Only adjust if the times are significantly different
			updateScrubber.setTime(newTime);

			// If we updated the inactive scrubber, the thumbnail is no longer valid
			if (this.active !== updateScrubber)
			{
				updateScrubber.removeChild(this.thumbnail);
			}
		}
	}

	/**
	 * Builds the default DOM `className`.
	 *
	 * @return {string}
	 *		The DOM `className` for this object.
	 */
	buildCSSClass()
	{
		return `vjs-clip-progress-bar ${super.buildCSSClass()}`;
	}
}

ClipProgressBar.prototype.controlText_ = "Clip Progress Bar";

Component.registerComponent("clipProgressBar", ClipProgressBar);

export default ClipProgressBar;
