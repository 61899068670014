// Polyfills for older browser support (iPad Mini and IE 11)

import "./polyfills";

// Basic dependencies

import { version } from "../package.json";
import videojs from "video.js";
import hlsPlugin from "videojs-hlsjs-plugin";
import { getMimetype } from "../utilities/MimeTypes";
import "video.js/dist/video-js.css";

// Internationalization (multi-language support)

// ! This file is generated by scripts/create-language-files.js
// TODO: Resarch a nicer way to do this. Maybe create a Webpack Loader
import "../dist/i18n.js";

// Plugins

import "../plugins";

// Initialization script (for compatibility with CMS)

import "./v";

// Initialize HLS support
// iPad added "support" for MediaSourceExtensions in iPadOS 13, but there's
// something buggy about their implementation causing HLS.js to return 0-second
// videos. So let's just disable HLS.js on iPad.
if (!videojs.browser.IS_IPAD)
{
	hlsPlugin.register(videojs);
}

// Utilize something akin to currying so we can force a class and source element
// onto the player before initialization VideoJS
window.bfplayer = function(elInput, options)
{
	var el;
	if (typeof elInput === "string")
		el = document.getElementById(elInput);
	else
		el = elInput;

	// Append "video-js" class (if not already present)
	el.classList.add("video-js");

	// Replace "src" attribute with <source> tag
	const src = el.src;
	if (src)
	{
		const srcEl = document.createElement("source");
		srcEl.src = src;
		srcEl.type = getMimetype(src);
		el.appendChild(srcEl);
	}

	// Override some crummy defaults (while still allowing the user of this
	// library to override them again)
	const defaultOptions = {
		controlBar: {
			volumePanel: {
				inline: false
			}
		},
		techCanOverridePoster: true,
		// HLS.js config
		html5: {
			hlsjsConfig: {
				// Temporal delay
				liveSyncDuration: 30,

				/* eslint-disable no-magic-numbers */
				// Avoiding quota exceeded errors and saving bandwidth
				maxMaxBufferLength: 180,
				maxBufferSize: 30 * 1024 * 1024,
				// Waiting for broadcasts when they first go live
				// Sometimes it takes a minute for the M3U8 to appear
				manifestLoadingMaxRetry: 3,
				manifestLoadingTimeOut: 5 * 1000,
				manifestLoadingRetryDelay: 8 * 1000,
				/* eslint-enable no-magic-numbers */

				// Limit bitrate by player size
				capLevelToPlayerSize: true
			}
		},
	};

	const player = videojs(el, videojs.mergeOptions(defaultOptions, options));

	// Make logs easily accessible
	player.getLogs = videojs.log.history;

	player.VERSION = version;

	player.dumpLogs = function()
	{
		const logs = player.getLogs();
		for (var i = 0; i < logs.length; i++)
		{
			console.log(new Date(logs[i].time).toLocaleString() + " -- " + logs[i].type + " -- " + logs[i].msg);
		}
	};

	// Convenience for debugging
	player.browser = videojs.browser;

	return player;
};


// If an element exists on the page with the ID "blueframe-player", we'll
// auto-initialize it with all plugins and default settings

const playerElement = document.getElementById("blueframe-player");
if (playerElement)
{
	const player = window.bfplayer(playerElement);

	// Dynamically initialize all plugins

	// If videojs-contrib-ads is available, it MUST be loaded first
	// This is because videojs-contrib-ads overrides a lot of player events
	if (Object.prototype.hasOwnProperty.call(videojs.getPlugins(), "ads"))
	{
		player.ads();
	}

	const pluginOptions = player.options_.plugins || {};
	Object.keys(videojs.getPlugins()).forEach(plugin =>
	{
		if (typeof player[plugin] === "function")
		{
			player[plugin](pluginOptions[plugin] || {});
		}
	});

	window.player = window.player || player;
}

window.bfplayer.getPlugins = videojs.getPlugins;

videojs.log(`BlueFrame Player v${version} initialized`);
