import videojs from "video.js";
import Hls from "hls.js";
import "videojs-contrib-quality-levels";

import "./Plugin.scss";

import QualitySelectorButton from "./components/QualitySelectorButton";

const logger = videojs.createLogger("ControlBarButtonQualitySelector");
const Plugin = videojs.getPlugin("plugin");

// Default options for the plugin.
const defaults = {
	// in Kbps
	defaultBitrate: 950
};

/**
 * An advanced Video.js plugin. For more information on the API
 *
 * See: https://blog.videojs.com/feature-spotlight-advanced-plugins/
 */
class QualitySelector extends Plugin
{

	/**
   * Create a QualitySelector plugin instance.
   *
   * @param  {Player} player
   *         A Video.js Player instance.
   *
   * @param  {Object} [options]
   *         An optional options object.
   *
   *         While not a core part of the Video.js plugin architecture, a
   *         second argument of options is a convenient way to accept inputs
   *         from your plugin's caller.
   */
	constructor(player, options)
	{
		// The parent class will add player under this.player
		super(player);

		this.options = videojs.mergeOptions(defaults, options);

		this.qualitySelectorButton = new QualitySelectorButton(player, options);

		player.ready(() =>
		{
			// Add the quality selector to the control bar
			player.controlBar.addChild(
				this.qualitySelectorButton,
				options,
				// Place this just before the FullscreenToggle button
				this.player.controlBar.children().indexOf(
					this.player.controlBar.getChild("FullscreenToggle")
				)
			);
		});

		this.hlsjs = null;

		// On Android we don't support auto bitrate selection
		if (videojs.browser.IS_ANDROID)
		{
			if (videojs.Html5Hlsjs)
			{
				videojs.Html5Hlsjs.addHook("beforeinitialize", (x, hlsjs) =>
				{
					this.hlsjs = hlsjs;
					hlsjs.on(Hls.Events.MANIFEST_LOADED, this.selectHlsJSLevel.bind(this));
				});
			}
			else
			{
				// Wait until we've downloaded a single TS file
				// See: https://github.com/videojs/http-streaming/issues/464
				player.on("loadedmetadata", this.setDefaultQualityLevel.bind(this));
			}
		}
	}

	setDefaultQualityLevel()
	{
		const qualityLevels = this.player.qualityLevels();
		if (qualityLevels.length < 2)
			return;

		const bestLevel = this.findBestLevel(qualityLevels);

		this.updateQualitySelector(bestLevel);
	}

	selectHlsJSLevel(evt, data)
	{
		const levels = data.levels;

		const bestLevel = this.findBestLevel(levels);

		this.hlsjs.config.startLevel = bestLevel;

		// Wait until we're playing to update our qualtiy selector, lest we
		// accidentally pause something
		this.player.one("playing", this.updateQualitySelector.bind(this, bestLevel));
	}

	findBestLevel(levels)
	{
		logger.debug(
			`Setting default quality level to match ${this.options.defaultBitrate}kbps`
		);

		let closestIdx = 0;
		let closestDiff = Math.abs((levels[0].bitrate / 1000) - this.options.defaultBitrate);
		logger.debug(
			`Bitrate[0] = ${levels[0].height}p, ${levels[0].bitrate / 1000}kbps. +/-${closestDiff}`
		);
		for (let i = 1; i < levels.length; i++)
		{
			const currentDiff = Math.abs((levels[i].bitrate / 1000) - this.options.defaultBitrate);
			logger.debug(
				`Bitrate[${i}] = ${levels[i].height}p, ${levels[i].bitrate / 1000}kbps. +/-${currentDiff}`
			);

			if (currentDiff < closestDiff)
			{
				logger.debug(`    Bitrate[${i}] is a better match!`);
				closestDiff = currentDiff;
				closestIdx = i;
			}
		}

		return closestIdx;
	}

	updateQualitySelector(bestLevel)
	{
		const qualityLevels = this.player.qualityLevels();

		for (let i = 0; i < qualityLevels.length; i++)
		{
			if (i === bestLevel)
			{
				qualityLevels[i].enabled = true;
			}
			else
			{
				qualityLevels[i].enabled = false;
			}
		}

		// Update our menu with the new selection
		this.qualitySelectorButton.update();
	}
}

// Register the plugin with video.js.
videojs.registerPlugin("controlBarButtonQualitySelector", QualitySelector);

export default QualitySelector;
