import videojs from "video.js";

const Spacer = videojs.getComponent("Spacer");

/**
 * The base class for buttons
 *
 * @param {Player|Object} player
 * @param {Object=} options
 * @extends Spacer
 * @class FloatingSeekBarSpacer
 */
class FloatingSeekBarSpacer extends Spacer
{
	/**
	 * Builds the default DOM `className`.
	 *
	 * @return {string}
	 *		The DOM `className` for this object.
	 */
	buildCSSClass()
	{
		return `vjs-floating-seek-bar-spacer ${super.buildCSSClass()}`;
	}
}

/**
 * The text that should display over the `FloatingSeekBarSpacer`s controls. Added for localization.
 *
 * @type {string}
 * @private
 */
FloatingSeekBarSpacer.prototype.controlText_ = "";

videojs.registerComponent("floatingSeekBarSpacer", FloatingSeekBarSpacer);

export default FloatingSeekBarSpacer;
