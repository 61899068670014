import videojs from "video.js";

const Component = videojs.getComponent("Component");

/**
 * TODO: See if there's a better superclass than "Component". Maybe like
 * TODO: "Overlay" or "Window" or something
 *
 * @param {Player|Object} player
 * @param {Object=} options
 * @extends Component
 * @class AdBreakTimeRemaining
 */
class AdBreakTimeRemaining extends Component
{
	/**
	 * Creates an instance of this class.
	 *
	 * @param {Player} player
	 *		The `Player` that this class should be attached to.
	 *
	 * @param {Object} [options={}]
	 *		The key/value store of player options.
	 */
	constructor(player, timeFunc, options = {})
	{
		super(player, options);
		this.player = player;

		this.el_ = this.createEl("div", {
			className: this.buildCSSClass(),
			title: this.localize(this.controlText_)
		});

		this.timeFunc = timeFunc;

		this.hide();
	}

	update()
	{
		const timeRemaining = this.timeFunc();

		if (timeRemaining > 0)
		{
			this.el_.innerHTML = "Ad Break: " + AdBreakTimeRemaining.formatTime(timeRemaining);
		}
		else
		{
			this.el_.innerHTML = "";
		}
	}

	static formatTime(time)
	{
		const minutes = Math.floor(time / 60);
		const seconds = Math.round(time - (minutes * 60));

		const lastTwoDigits = -2;
		return `${("00" + minutes).substr(lastTwoDigits)}:${("00" + seconds).substr(lastTwoDigits)}`;
	}

	/**
	 * Builds the default DOM `className`.
	 *
	 * @return {string}
	 *		The DOM `className` for this object.
	 */
	buildCSSClass()
	{
		return `vjs-ad-break-time-remaining ${super.buildCSSClass()}`;
	}
}

/**
 * The text that should display over the `AdBreakTimeRemaining`s controls. Added for localization.
 *
 * @type {string}
 * @private
 */
AdBreakTimeRemaining.prototype.controlText_ = "Time Remaining in AdBreak";

videojs.registerComponent("adBreakTimeRemaining", AdBreakTimeRemaining);

export default AdBreakTimeRemaining;
