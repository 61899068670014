import videojs from "video.js";

const Component = videojs.getComponent("Component");

// Default options for the plugin.
const defaults = {
	name: "AdDisplayContainer"
};

/**
 * @param {Player|Object} player
 * @param {Object=} options
 * @extends Component
 * @class AdDisplayContainer
 */
class AdDisplayContainer extends Component
{
	/**
	 * Creates an instance of this class.
	 *
	 * @param {Player} player
	 *		The `Player` that this class should be attached to.
	 *
	 * @param {Object} [options={}]
	 *		The key/value store of player options.
	 */
	constructor(player, options = {})
	{
		const mergedOptions = videojs.mergeOptions(defaults, options);
		super(player, mergedOptions);

		this.el_ = this.createEl("div", {
			className: this.buildCSSClass(),
			title: this.localize(this.controlText_)
		});

		this.hide();
	}

	/**
	 * Builds the default DOM `className`.
	 *
	 * @return {string}
	 *		The DOM `className` for this object.
	 */
	buildCSSClass()
	{
		return `vjs-ad-display-container ${super.buildCSSClass()}`;
	}
}

/**
 * The text that should display over the `AdDisplayContainer`s controls. Added for localization.
 *
 * @type {string}
 * @private
 */
AdDisplayContainer.prototype.controlText_ = "Ad Display Container";

videojs.registerComponent("adDisplayContainer", AdDisplayContainer);

export default AdDisplayContainer;
