import videojs from "video.js";
import "./Plugin.scss";
import PostAdRewindOverlay from "./components/Overlay";
import xPath from "@utilities/XPath";


// Default options for the plugin.
// const defaults = {};

/**
 * A basic Video.js plugin. For more information on the API
 *
 * See: https://docs.videojs.com/tutorial-plugins.html
 */

const FIVE_SECONDS = 5000;

function PostAdRewind()
{
	// Only support desktop for now
	const IS_MOBILE = videojs.browser.IS_ANDROID || videojs.browser.IS_IOS || videojs.browser.IS_IPAD || videojs.browser.IS_IPOD;
	if (IS_MOBILE) return;

	const player = this;

	let siteID;
	let broadcastID;
	let adData;
	let bannerTimeout;

	const overlay = new PostAdRewindOverlay(player, handleRewind);
	player.addChild(overlay);

	player.on("adbreakended", handleAdBreakEnded);

	player.on("vmap-ready", () =>
	{

		const siteTag = xPath.ext(player.vmap, "Site").iterateNext();
		siteID = siteTag.getAttribute("id");

		const broadcastTag = xPath.ext(player.vmap, "Broadcast").iterateNext();
		broadcastID = broadcastTag.getAttribute("id");

		const miscTag = xPath.ext(player.vmap, "MiscInfo").iterateNext();
		const origin = miscTag.getAttribute("streamOrigin") || "";

		if (origin === "PT")
		{
			player.off("adbreakended", handleAdBreakEnded);
		}
	});

	function handleAdBreakEnded(evt, data)
	{
		adData = data;
		overlay.show();
		bannerTimeout = setTimeout(() => overlay.hide(), FIVE_SECONDS);
	}

	function handleRewind()
	{
		if (adData)
		{
			const context = {
				playerTimeMS: Math.floor(player.currentTime() * 1000),
				adStartTimeMS: Math.floor(adData.startTime * 1000),
				adEndTimeMS: Math.floor(adData.endTime * 1000),
				adDuration: Math.floor((adData.endTime - adData.startTime) * 1000),
				broadcastID,
				siteID,
			};

			player.trigger("adrewind", context);
		}
		overlay.hide();

		player.currentTime(adData.startTime);
	}
	player.on("postadrewindclicked", handleRewind);

	function handleDismiss()
	{
		overlay.hide();
	}
	player.on("postaddismissclicked", handleDismiss);

	overlay.el_.addEventListener("pointerenter", () =>
	{
		if (bannerTimeout) clearTimeout(bannerTimeout);
	});

	overlay.el_.addEventListener("pointerleave", () =>
	{
		bannerTimeout = setTimeout(() => overlay.hide(), FIVE_SECONDS);
	});
}

// Register the plugin with video.js.
videojs.registerPlugin("postAdRewind", PostAdRewind);
