/**
 * XHR based logger
 *
 * This code was originally copied from HLS.js:
 * https://github.com/video-dev/hls.js/blob/b4f8ceaef6234f8de1d71786083ad44e53d69d8f/src/utils/xhr-loader.js
 *
 * It has been modified slightly:
 *  - Logging now uses our own personal logger
 *  - Some 4xx errors will still retry (403 / 404 may be temporary)
 *  - zero-byte files will be treated as 404 (and will retry)
 */

import videojs from "video.js";

const logger = videojs.createLogger("HLS.js Loader");
const { performance, XMLHttpRequest } = window;

const READY_STATE = {
	UNSENT: 0,
	OPENED: 1,
	HEADERS_RECEIVED: 2,
	LOADING: 3,
	DONE: 4
};

const STATUS = {
	SUCCESS: 200,
	REDIRECTION: 300,
	CLIENT_ERROR: 400,
	FORBIDDEN: 403,
	NOT_FOUND: 404,
	SERVER_ERROR: 500
};

class XhrLoader
{
	constructor(config)
	{
		if (config && config.xhrSetup)
		{
			this.xhrSetup = config.xhrSetup;
		}
	}

	destroy()
	{
		this.abort();
		this.loader = null;
	}

	abort()
	{
		const loader = this.loader;
		if (loader && loader.readyState !== READY_STATE.DONE)
		{
			this.stats.aborted = true;
			loader.abort();
		}

		window.clearTimeout(this.requestTimeout);
		this.requestTimeout = null;
		window.clearTimeout(this.retryTimeout);
		this.retryTimeout = null;
	}

	load(context, config, callbacks)
	{
		this.context = context;
		this.config = config;
		this.callbacks = callbacks;
		this.stats = { trequest: performance.now(), retry: 0 };
		this.retryDelay = config.retryDelay;
		this.loadInternal();
	}

	loadInternal()
	{
		const context = this.context;
		const xhr = this.loader = new XMLHttpRequest();

		const stats = this.stats;
		stats.tfirst = 0;
		stats.loaded = 0;
		const xhrSetup = this.xhrSetup;

		try
		{
			if (xhrSetup)
			{
				try
				{
					xhrSetup(xhr, context.url);
				}
				catch (e)
				{
					// fix xhrSetup: (xhr, url) => {xhr.setRequestHeader("Content-Language", "test");}
					// not working, as xhr.setRequestHeader expects xhr.readyState === OPENED
					xhr.open("GET", context.url, true);
					xhrSetup(xhr, context.url);
				}
			}
			if (!xhr.readyState)
			{
				xhr.open("GET", context.url, true);
			}
		}
		catch (e)
		{
			// IE11 throws an exception on xhr.open if attempting to access an HTTP resource over HTTPS
			this.callbacks.onError({ code: xhr.status, text: e.message }, context, xhr);
			return;
		}

		if (context.rangeEnd)
		{
			xhr.setRequestHeader("Range", "bytes=" + context.rangeStart + "-" + (context.rangeEnd - 1));
		}

		xhr.onreadystatechange = this.readystatechange.bind(this);
		xhr.onprogress = this.loadprogress.bind(this);
		xhr.responseType = context.responseType;

		// setup timeout before we perform request
		this.requestTimeout = window.setTimeout(this.loadtimeout.bind(this), this.config.timeout);
		xhr.send();
	}

	readystatechange(event)
	{
		const xhr = event.currentTarget,
			readyState = xhr.readyState,
			stats = this.stats,
			context = this.context,
			config = this.config;

		// don't proceed if xhr has been aborted
		if (stats.aborted)
		{
			return;
		}

		// >= HEADERS_RECEIVED
		if (readyState >= READY_STATE.HEADERS_RECEIVED)
		{
			// clear xhr timeout and rearm it if readyState less than 4
			window.clearTimeout(this.requestTimeout);
			if (stats.tfirst === 0)
			{
				stats.tfirst = Math.max(performance.now(), stats.trequest);
			}

			if (readyState === READY_STATE.DONE)
			{
				const status = xhr.status;
				// http status between 200 to 299 are all successful
				if (status >= STATUS.SUCCESS && status < STATUS.REDIRECTION)
				{
					stats.tload = Math.max(stats.tfirst, performance.now());
					let data, len;
					if (context.responseType === "arraybuffer")
					{
						data = xhr.response;
						len = data.byteLength;
					}
					else
					{
						data = xhr.responseText;
						len = data.length;
					}
					if (len === 0)
					{
						// zero-byte file. Likely a temporary issue while a file is being updated on the origin
						logger.warn(`Zero bytes downloaded for ${context.url}`);
						this.handleError(event);
					}
					else
					{
						stats.loaded = stats.total = len;
						const response = { url: xhr.responseURL, data: data };
						this.callbacks.onSuccess(response, stats, context, xhr);
					}
				}
				else
				{
					this.handleError(event);
				}
			}
			else
			{
				// readyState >= HEADERS_RECEIVED AND readyState !== DONE rearm timeout as xhr not finished yet
				this.requestTimeout = window.setTimeout(this.loadtimeout.bind(this), config.timeout);
			}
		}
	}

	handleError(event)
	{
		const xhr = event.currentTarget,
			stats = this.stats,
			context = this.context,
			config = this.config,
			status = xhr.status;

		// if max nb of retries reached or if http status between 400 and 499 (such error cannot be recovered,
		// retrying is useless), return error
		if (stats.retry >= config.maxRetry || (
			status >= STATUS.CLIENT_ERROR && status < STATUS.SERVER_ERROR &&
			status !== STATUS.FORBIDDEN && status !== STATUS.NOT_FOUND
		))
		{
			logger.error(`HTTP status ${status} while loading ${context.url}`);
			this.callbacks.onError({ code: status, text: xhr.statusText }, context, xhr);
		}
		else
		{
			// retry
			logger.warn(
				`HTTP status ${status} while loading ${context.url}, retrying in ${this.retryDelay}...`
			);
			// aborts and resets internal state
			this.destroy();
			// schedule retry
			this.retryTimeout = window.setTimeout(this.loadInternal.bind(this), this.retryDelay);
			// set exponential backoff
			this.retryDelay = Math.min(2 * this.retryDelay, config.maxRetryDelay);
			stats.retry++;
		}
	}

	loadtimeout()
	{
		logger.warn(`Timeout while loading ${this.context.url}`);
		this.callbacks.onTimeout(this.stats, this.context, null);
	}

	loadprogress(event)
	{
		const xhr = event.currentTarget,
			stats = this.stats;

		stats.loaded = event.loaded;
		if (event.lengthComputable)
		{
			stats.total = event.total;
		}

		const onProgress = this.callbacks.onProgress;
		if (onProgress)
		{
			// third arg is to provide on progress data
			onProgress(stats, this.context, null, xhr);
		}
	}
}

export default XhrLoader;
