import videojs from "video.js";
import NotifierCloseButton from "./NotifierCloseButton";

const Component = videojs.getComponent("Component");

/**
 * TODO: See if there's a better superclass than "Component". Maybe like
 * TODO: "Overlay" or "Window" or something
 *
 * @param {Player|Object} player
 * @param {Object=} options
 * @extends Component
 * @class NotifierOverlay
 */
class NotifierOverlay extends Component
{
	/**
	 * Creates an instance of this class.
	 *
	 * @param {Player} player
	 *		The `Player` that this class should be attached to.
	 *
	 * @param {Object} [options={}]
	 *		The key/value store of player options.
	 */
	constructor(player, options = {})
	{
		super(player, options);
		this.player = player;

		this.allowClose = false;

		this.el_ = this.createEl("div", {
			className: this.buildCSSClass(),
			title: this.localize(this.controlText_)
		});

		// To position our NotifierOverlay we want position:absolute
		// However to position elements within it, we want position:relative
		this.contentEl_ = videojs.dom.createEl("div", {
			className: "content-wrapper"
		});
		this.el_.appendChild(this.contentEl_);

		this.display = videojs.dom.createEl("p", {
			className: "vjs-notifier-overlay-text"
		});
		this.contentEl_.appendChild(this.display);

		this.closeButton = new NotifierCloseButton(player, options, this);
		this.addChild(this.closeButton);

		this.hide();
	}

	displayMessage(msg)
	{
		if (!msg)
		{
			this.hide();
			return;
		}

		this.display.innerHTML = this.localize(msg);
		this.show();

		if (this.allowClose)
			this.closeButton.show();
		else
			this.closeButton.hide();
	}

	/**
	 * Builds the default DOM `className`.
	 *
	 * @return {string}
	 *		The DOM `className` for this object.
	 */
	buildCSSClass()
	{
		return `vjs-notifier-overlay ${super.buildCSSClass()}`;
	}

	show()
	{
		super.show();

		if (this.allowClose)
		{
			this.addClass("vjs-allow-close");
		}
		else
		{
			this.removeClass("vjs-allow-close");
		}
	}
}

/**
 * The text that should display over the `NotifierOverlay`s controls. Added for localization.
 *
 * @type {string}
 * @private
 */
NotifierOverlay.prototype.controlText_ = "Notification";

Component.registerComponent("notifierOverlay", NotifierOverlay);

export default NotifierOverlay;
