import videojs from "video.js";

const Component = videojs.getComponent("Component");
const MenuItem = videojs.getComponent("MenuItem");

/**
 * The base class for buttons
 *
 * @param {Player|Object} player
 * @param {Object=} options
 * @extends MenuItem
 * @class TroubleshootingMenuItem
 */
class TroubleshootingMenuItem extends MenuItem
{
	handleClick()
	{
		super.handleClick();

		// Open the new window
		window.open(
			"https://support.hudl.com/s/topic/0TOVY0000000A934AE/fan/",
			"_blank",
			"width=640,height=360"
		);
	}

	/**
	 * Builds the default DOM `className`.
	 *
	 * @return {string}
	 *		The DOM `className` for this object.
	 */
	buildCSSClass()
	{
		// ! For some stupid reason, VideoJS doesn't seem to call this method?
		return `vjs-troubleshooting-menu-item ${super.buildCSSClass()}`;
	}
}

/**
 * The text for this MenuItem Added for localization.
 *
 * @type {string}
 * @private
 */
TroubleshootingMenuItem.prototype.options_ = {label: "Troubleshooting"};

Component.registerComponent("troubleshootingMenuItem", TroubleshootingMenuItem);

export default TroubleshootingMenuItem;
