import videojs from "video.js";
import {parseUrl} from "@utilities/Url";

const Component = videojs.getComponent("Component");
const MenuItem = videojs.getComponent("MenuItem");

const defaults = {
	newWindowHeight: 360,
	newWindowWidth: 640
};

/**
 * The base class for buttons
 *
 * @param {Player|Object} player
 * @param {Object=} options
 * @extends MenuItem
 * @class NewWindowMenuItem
 */
class NewWindowMenuItem extends MenuItem
{
	constructor(player, options)
	{
		const mergedOptions = videojs.mergeOptions(defaults, options);
		super(player, mergedOptions);

		this.player = player;
	}

	handleClick()
	{
		super.handleClick();

		// Get the source URL (VMAP)
		const sourceUrl = this.player.src();

		// Convert to a windows player URL. e.g.:
		// https://vcloud.blueframetech.com/broadcast/embed/79999?windowed=true
		// TODO: This should be passed in via the NewWindowPlugin instead of
		// TODO: computed here. That way we can change how it's calculated.
		const urlDetails = parseUrl(sourceUrl);
		const broadcastId = urlDetails.pathname.split("/").pop();
		const newPath = `broadcast/embed/${broadcastId}`;
		const windowedUrl = `${urlDetails.protocol}//${urlDetails.host}/${newPath}?windowed=true`;

		// Open the new window
		this.player.pause();
		window.open(
			windowedUrl,
			"_blank",
			`width=${this.options_.newWindowWidth},height=${this.options_.newWindowHeight}`
		);
	}

	/**
	 * Builds the default DOM `className`.
	 *
	 * @return {string}
	 *		The DOM `className` for this object.
	 */
	buildCSSClass()
	{
		// ! For some stupid reason, VideoJS doesn't seem to call this method?
		return `vjs-new-window-menu-item ${super.buildCSSClass()}`;
	}
}

/**
 * The text for this MenuItem Added for localization.
 *
 * @type {string}
 * @private
 */
NewWindowMenuItem.prototype.options_ = {label: "Launch New Window"};

Component.registerComponent("newWindowMenuItem", NewWindowMenuItem);

export default NewWindowMenuItem;
