/**
 * Some older players may send us OS information formatted differently (e.g.,
 * "tvos" instead of "tvOS" or "aftv" instead of "Amazon Fire TV").
 *
 * Alternatively, if parsing the OS out of a user agent string (e.g. the web
 * player), we may get slightly different spellings, capitalizations, or other
 * variations.
 *
 * To keep our messages consistent and improve our analytics pipeline, one of
 * the many jobs of kintran will be to convert all OS names to a consistent
 * format.
 *
 * This object shall have lowercase keys with properly capitalized values. The
 * intended usage is:
 *
 *     message.os = osMap[message.os.toLowerCase()] ?? "Unknown";
 */

// For a more terse description (convenience for developers) I define the OS
// mappings with arrays here instead of a hash map:

const spec = {
	// Apple devices
	"iOS": ["ios"],
	"tvOS": ["tvos"],
	"Mac OS": ["mac", "macos", "mac os"],

	// Google devices
	"Android": ["android"],
	"Android TV": ["androidtv", "android tv"],
	"Chromecast": ["chromecast"],
	"Chromium OS": ["chromium", "chromium os"],

	// Amazon devices
	"Amazon Fire": ["amazon fire"],
	"Amazon Fire TV": ["aftv", "amazon fire tv"],

	// Microsoft devices
	"Windows": ["win", "windows"],
	"Xbox": ["xbox"],
	"Windows Phone": ["windows phone"],

	// *nix-style OS's
	// Don't hate on me for throwing "unix" into "Linux". I just figured clients wouldn't care about the differences
	"Linux": ["linux", "ubuntu", "debian", "fedora", "mandriva", "redhat", "mint", "arch", "suse", "gentoo", "slackware", "mageia", "vectorlinux", "joli", "pclinuxos", "unix"],
	"BSD": ["bsd", "freebsd", "openbsd", "netbsd", "pc-bsd", "dragonfly"],
	"BeOS": ["beos", "haiku", "plan9", "minix", "os2", "amigaos", "morphos", "openvms", "fuschia"],

	// Other
	"Roku": ["roku"],
	"BlackBerry": ["blackberry"],
	"Tizen": ["tizen", "kaios"],
	"Symbian": ["symbian"],
	"Solaris": ["solaris"],
	"Firefox OS": ["firefox", "gecko", "mozilla", "firefox os"],
	"Nintendo": ["nintendo", "3ds", "dsi", "wii", "wiiu"],
	"PlayStation": ["playstation", "psp"],

	// "Unknown" values
	"Unknown": ["unknown", "unset"]
};

// Convert the above to a hash map for more efficient lookups:

const compiled = {};

Object.keys(spec).forEach((os) =>
{
	spec[os].forEach(str =>
	{
		compiled[str.toLowerCase()] = os;
	});
});

// And return the hash map:

export default compiled;
