import "whatwg-fetch";
import "promise-polyfill/src/polyfill";

// HLS.js uses "Number.isFinite", whereas IE only supports "isFinite"
// Within the HLS.js source code they have a polyfill for Number.isFinite which
// is automatically loaded via a Webpack extension. That's... kind of awful.
// Here I just manually insert their polyfill
Number.isFinite = Number.isFinite || function(value)
{
	return typeof value === "number" && isFinite(value);
};

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/TypedArray/slice#Polyfill
if (!Uint8Array.prototype.slice)
{
	Object.defineProperty(
		Uint8Array.prototype,
		"slice",
		{
			value: function (begin, end)
			{
				return new Uint8Array(Array.prototype.slice.call(this, begin, end));
			}
		}
	);
	Object.defineProperty(
		Uint8Array.prototype,
		"reduce",
		{
			value: function (callback, startingValue)
			{
				// Doesn't work:
				// return new Uint8Array(Array.prototype.reduce.call(this, callback, startingValue));
				let acc = startingValue;
				for (var i = 0; i < this.length; i++)
				{
					acc = callback(acc, this[i], i);
				}
				return acc;
			}
		}
	);
	Object.defineProperty(
		Uint32Array.prototype,
		"slice",
		{
			value: function (begin, end)
			{
				return new Uint32Array(Array.prototype.slice.call(this, begin, end));
			}
		}
	);
	Object.defineProperty(
		Uint32Array.prototype,
		"map",
		{
			value: function (callback)
			{
				return new Uint32Array(Array.prototype.map.call(this, callback));
			}
		}
	);
}

// VideoJS HTTP Streaming uses the DEPRECATED BlobBuilder API for constructing
// media blobs. They should really fix this. However so long as it's what they
// use, we need to fix the fact that iOS 9 doesn't support it.
class _BlobBuilder
{
	constructor()
	{
		this.blob = new Blob();
	}
	append(data, endings = "transparent")
	{
		if (this.blob.size === 0)
			this.blob = new Blob([data], {endings: endings});
		else
			this.blob = new Blob([this.blob, data], {endings: endings});
	}
	getBlob(contentType)
	{
		return new Blob([this.blob], {type: contentType});
	}
	getFile(name, contentType)
	{
		return new File(this.blob, name, {type: contentType});
	}
}

const win = window;
window.BlobBuilder =
	win.BlobBuilder ||
	win.WebKitBlobBuilder ||
	win.MozBlobBuilder ||
	win.MSBlobBuilder ||
	_BlobBuilder;
