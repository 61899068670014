import videojs from "video.js";
import xPath from "@utilities/XPath";
import {getMimetype} from "@utilities/MimeTypes";
import Cache from "@utilities/Cache";

const logger = videojs.createLogger("VMAPTagParserNetworkID");
const Plugin = videojs.getPlugin("plugin");

// Default options for the plugin.
const defaults = {
	enabled: true
};

/**
 * Extract the Network ID from the VolarVMAP and add it to the source list
 */
class NetworkID extends Plugin
{
	/**
	 * Create a NetworkID plugin instance.
	 *
	 * @param  {Player} player
	 *         A Video.js Player instance.
	 *
	 * @param  {Object} [options]
	 *         An optional options object.
	 *
	 *         While not a core part of the Video.js plugin architecture, a
	 *         second argument of options is a convenient way to accept inputs
	 *         from your plugin's caller.
	 */
	constructor(player, options)
	{
		// The parent class will add player under this.player
		super(player);

		this.options = videojs.mergeOptions(defaults, options);

		if (this.options.enabled)
			player.on("vmap-ready", () => { this.handle(player.vmap); });
	}

	handle(vmap)
	{
		this.vmap = vmap;

		const networkIDTag = xPath.ext(vmap, "NetworkID").iterateNext();
		if (!networkIDTag) return;

		// Dont load if the broadcast is upcoming
		const broadcastTag = xPath.ext(vmap, "Broadcast").iterateNext();
		if (broadcastTag.getAttribute("status").toLowerCase() === "upcoming") return;

		const miscTag = xPath.ext(vmap, "MiscInfo").iterateNext();
		// Measured in minutes, convert to milliseconds:
		const timeout = miscTag.getAttribute("prerollTimeout") * 60 * 1000;
		const lastPreroll = Cache.get("lastnetworkid", 0);

		if ((Date.now() - lastPreroll) < timeout)
		{
			logger(
				"Skipping Network ID due to prerollTimeout " +
				`(${Date.now()} - ${lastPreroll} < ${timeout})`
			);
			return;
		}

		this.player.tech_.sourceHandler_.addSource({
			src: networkIDTag.textContent.replace(/https?:/, ""),
			type: getMimetype(networkIDTag.textContent)
		}, 0);

		this.player.on("playing", () =>
		{
			Cache.set("lastnetworkid", Date.now());
		});
	}
}

videojs.registerPlugin("vmapTagParserNetworkID", NetworkID);

export default NetworkID;
