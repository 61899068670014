import videojs from "video.js";
import Hls from "hls.js";

const logger = videojs.createLogger("BehaviorChangeCorrectNegativeInitPTS");

// Default options for the plugin.
const defaults = {
	ptsLimit: 180000
};

/**
 * A basic Video.js plugin.
 */
function CorrectNegativeInitPTS(options)
{
	const opts = videojs.mergeOptions(defaults, options);

	if (videojs.Html5Hlsjs)
	{
		videojs.Html5Hlsjs.addHook("beforeinitialize", (x, hlsjs) =>
		{
			hlsjs.on(Hls.Events.INIT_PTS_FOUND, function(y, data)
			{
				if (data.initPTS < 0)
				{
					if (data.initPTS < -Math.abs(opts.ptsLimit))
					{
						// If PTS is off by 2 whole seconds, log an error
						logger.error(
							"Initial PTS less than negative 2 seconds"
						);
					}

					data.initPTS = 0;
					hlsjs.trigger(Hls.Events.INIT_PTS_FOUND, data);
				}
			});
		});
	}
}

// Register the plugin with video.js.
videojs.registerPlugin("behaviorChangeCorrectNegativeInitPTS", CorrectNegativeInitPTS);

export default CorrectNegativeInitPTS;
