import videojs from "video.js";

const Component = videojs.getComponent("Component");

/**
 * TODO: See if there's a better superclass than "Component". Maybe like
 * TODO: "Overlay" or "Window" or something
 *
 * @param {Player|Object} player
 * @param {Object=} options
 * @extends Component
 * @class GeoblockingOverlay
 */
class GeoblockingOverlay extends Component
{
	/**
	 * Creates an instance of this class.
	 *
	 * @param {Player} player
	 *		The `Player` that this class should be attached to.
	 *
	 * @param {Object} [options={}]
	 *		The key/value store of player options.
	 */
	constructor(player, options = {})
	{
		super(player, options);
		this.player = player;

		this.allowClose = false;

		this.el_ = this.createEl("div", {
			className: this.buildCSSClass(),
			title: this.localize(this.controlText_)
		});

		// To position our NotifierOverlay we want position:absolute
		// However to position elements within it, we want position:relative
		this.contentEl_ = videojs.dom.createEl("div", {
			className: "content-wrapper"
		});
		this.el_.appendChild(this.contentEl_);

		this.display = videojs.dom.createEl("p", {
			className: "vjs-geoblocking-overlay-text"
		});
		this.contentEl_.appendChild(this.display);

		this.display.innerHTML = this.localize(
			"Sorry, but your region has been blocked from being able to access this content"
		);

		this.hide();
	}

	show(msg)
	{
		if (msg)
			this.display.innerHTML = msg;

		super.show();
	}

	/**
	 * Builds the default DOM `className`.
	 *
	 * @return {string}
	 *		The DOM `className` for this object.
	 */
	buildCSSClass()
	{
		return `vjs-geoblocking-overlay ${super.buildCSSClass()}`;
	}
}

/**
 * The text that should display over the `GeoblockingOverlay`s controls. Added for localization.
 *
 * @type {string}
 * @private
 */
GeoblockingOverlay.prototype.controlText_ = "Geoblocked";

Component.registerComponent("geoblockOverlay", GeoblockingOverlay);

export default GeoblockingOverlay;
