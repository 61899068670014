import videojs from "video.js";
import Hls from "hls.js";
import {detectHLS} from "@utilities/DetectHLS";

const Plugin = videojs.getPlugin("plugin");

// Default options for the plugin.
const defaults = {};

function round3(x)
{
	return Math.round(x * 1000) / 1000;
}

/**
 * An advanced Video.js plugin. For more information on the API
 *
 * See: https://blog.videojs.com/feature-spotlight-advanced-plugins/
 */
class MismatchedRenditions extends Plugin
{

	/**
   * Create a MismatchedRenditions plugin instance.
   *
   * @param  {Player} player
   *         A Video.js Player instance.
   *
   * @param  {Object} [options]
   *         An optional options object.
   *
   *         While not a core part of the Video.js plugin architecture, a
   *         second argument of options is a convenient way to accept inputs
   *         from your plugin's caller.
   */
	constructor(player, options)
	{
		// The parent class will add player under this.player
		super(player);
		this.options = videojs.mergeOptions(defaults, options);

		this.hlsjs = null;
		if (videojs.Html5Hlsjs)
		{
			videojs.Html5Hlsjs.addHook("beforeinitialize", (x, hlsjs) =>
			{
				this.hlsjs = hlsjs;
			});
		}

		player.ready(() =>
		{
			if (player.detectErrors)
			{
				player.detectErrors().register(this);
			}
		});
	}

	test()
	{
		return new Promise(resolve =>
		{
			// We only support detecting bad HLS videos
			if (!detectHLS(this.player))
			{
				resolve([]);
				return;
			}

			// For the time being, we only support error detection with HLS.js
			// TODO: Add support for VideoJS HTTP Streaming
			if (!this.hlsjs)
			{
				resolve([]);
				return;
			}

			if (!this.hlsjs.levels || this.hlsjs.levels.length < 2)
			{
				// We can't have a mismatch if there aren't 2 levels
				resolve([]);
				return;
			}

			// We cannot check the fragment durations unless we have updated
			// chunk lists. Let's force HLS.js to update them all now.
			this.currentLevel = 0;
			this.boundLoadLevel = this.loadLevel.bind(this, resolve);
			this.hlsjs.on(Hls.Events.LEVEL_LOADED, this.boundLoadLevel);
			this.loadLevel(resolve);
		});
	}

	finishTest(resolve)
	{
		this.hlsjs.off(Hls.Events.LEVEL_LOADED, this.boundLoadLevel);

		const levels = this.hlsjs.levels;
		const expectedDurations = [];
		const errors = [];

		// Get the best level for the current bandwidth, for use later
		// I feel like this is a LOT of code to solve a simple problem, but
		// whatever. I just wrote what made sense. There's probably an elegant
		// one-line solution to it.
		const bandwidth = this.hlsjs.abrController._bwEstimator.fast_.estimate_;
		let maxBitrate = -1;
		// Default to first rendition (in case logic below fails)
		let bestRendition = levels[0];
		for (let i = 0; i < levels.length; i++)
		{
			const bitrate = levels[i].bitrate;
			if (bitrate > maxBitrate && bitrate < bandwidth)
			{
				maxBitrate = bitrate;
				bestRendition = levels[i];
			}
		}

		// Fill an array with the expected durations from the first level
		const firstLevelFragments = bestRendition.details.fragments;
		for (let i = 0; i < firstLevelFragments.length; i++)
		{
			expectedDurations.push(round3(firstLevelFragments[i].duration));
		}

		for (let i = 0; i < levels.length; i++)
		{
			const level = levels[i];

			if (level === bestRendition)
			{
				// This is our baseline
				continue;
			}

			const details = level.details;
			if (!details)
			{
				// We haven't loaded this chunklist before (shouldn't happen)
				continue;
			}

			const fragments = details.fragments;
			for (let j = 0; j < fragments.length; j++)
			{
				const fragLength = round3(MismatchedRenditions.getFragDuration(fragments[j]));
				if (fragLength !== expectedDurations[j])
				{
					// This is trivially fixed by disabling bitrate switching.
					// However, if we disable bitrate switching then we need
					// to pick a bitrate to stick with
					// Let's take whatever their current bandwidth is to try and
					// pick the best rendition
					errors.push({
						msg: `${level.height}p rendition TS file durations do not match ${bestRendition.height}p. ` +
							 `Example: TS file ${j} duration ${expectedDurations[j]} vs. ${fragLength}`,
						recoverable: true,
						corruptRange: null,
						corruptRendition: i
					});
					break;
				}
			}
		}

		resolve(errors);
	}

	static getFragDuration(fragment)
	{
		// Attempt to pull the duration from the chunklist
		const tagList = fragment.tagList;
		for (let i = 0; i < tagList.length; i += 2)
		{
			if (tagList[i] === "INF")
			{
				return tagList[i + 1];
			}
		}

		// If for any reason we can't (not sure why we couldn't),
		// use whatever HLSJS thinks the duration is
		return fragment.duration;
	}

	loadLevel(resolve)
	{
		if (this.hlsjs.levels.length > this.currentLevel)
		{
			this.hlsjs.levelController.nextLoadLevel = this.currentLevel++;
			this.hlsjs.levelController.loadLevel();
		}
		else
		{
			this.finishTest(resolve);
		}
	}
}

// Register the plugin with video.js.
videojs.registerPlugin("errorDetectionMismatchedRenditions", MismatchedRenditions);

export default MismatchedRenditions;
