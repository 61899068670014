import videojs from "video.js";

const Component = videojs.getComponent("Component");

/**
 * TODO: See if there's a better superclass than "Component". Maybe like
 * TODO: "Overlay" or "Window" or something
 *
 * @param {Player|Object} player
 * @param {Object=} options
 * @extends Component
 * @class PostAdRewind
 */
class PostAdRewindOverlay extends Component
{
	/**
	 * Creates an instance of this class.
	 *
	 * @param {Player} player
	 *		The `Player` that this class should be attached to.
	 *
	 * @param {Object} [options={}]
	 *		The key/value store of player options.
	 */
	constructor(player, options = {})
	{
		super(player, options);
		this.player = player;

		this.el_ = this.createEl("div", {
			className: this.buildCSSClass(),
			title: this.localize(this.controlText_)
		});

		this.inner = this.createEl("div", {
			className: "vjs-post-ad-rewind-inner-container",
			title: this.localize(this.controlText_)
		});
		this.el_.appendChild(this.inner);

		this.text = this.createEl("p", {
			className: "vjs-rewind-text",
			innerText: "You’re watching live, but you can jump back to where you left off before the ad break."
		});
		this.inner.appendChild(this.text);

		this.dismissButton = this.createEl("button", {
			className: "vjs-dismiss-button",
			onclick: () => player.trigger("postaddismissclicked"),
			innerText: "Dismiss"
		});
		this.inner.appendChild(this.dismissButton);

		this.rewindButton = this.createEl("button", {
			className: "vjs-rewind-button",
			onclick: () => player.trigger("postadrewindclicked"),
			innerText: "Rewind"
		});
		this.inner.appendChild(this.rewindButton);

		this.hide();
	}

	/**
	 * Builds the default DOM `className`.
	 *
	 * @return {string}
	 *		The DOM `className` for this object.
	 */
	buildCSSClass()
	{
		return `vjs-post-ad-rewind-outer-container ${super.buildCSSClass()}`;
	}
}

/**
 * The text that should display over the `AdBreakTimeRemaining`s controls. Added for localization.
 *
 * @type {string}
 * @private
 */
PostAdRewindOverlay.prototype.controlText_ = "Rewind after ad break";

videojs.registerComponent("adBreakTimeRemaining", PostAdRewindOverlay);

export default PostAdRewindOverlay;
