import videojs from "video.js";
import xPath from "@utilities/XPath";
const { XMLHttpRequest } = window;
import { isObject } from "@utilities/TypeChecks";
const logger = videojs.createLogger("AdMetadataHandler");

const HTTP_STATUS = {
	OK: 200,
};

const XHR_STATUS = {
	READY: 4
};

const DOMAIN = {
	PROD: "www.hudl.com",
	THOR: "master.thorhudl.com"
};

function AdMetadataHandler(options)
{
	if (!options.enabled) return;

	const broadcastID = options.broadcastID;
	const vmapUrl = options.vmapUrl;
	const player = this;
	let metadataRequest = null;

	player.on("vmap-ready", () => { handleVmap(player.vmap); });
	player.on("statechange", handleStateChange);

	function handleStateChange(evt, state)
	{
		if (state === "UPCOMING" ||
			state === "TEST" ||
			state === "UNSET" ||
			state === "ERROR" ||
			state === "FINISHED"
		)
		{
			return;
		}

		fetchMetadata();

		player.off("statechange", handleStateChange);
	}

	function handleVmap(vmap)
	{
		let broadcastStatus = xPath(vmap, "//Broadcast").iterateNext().getAttribute("status");
		broadcastStatus = broadcastStatus.toLowerCase();
		if (broadcastStatus === "upcoming" ||
			broadcastStatus === "test" ||
			broadcastStatus === "testing")
		{
			player.on("statechange", handleStateChange);
			return;
		}

		fetchMetadata();
	}

	function fetchMetadata()
	{
		if (metadataRequest)
		{
			metadataRequest.abort();
		}
		metadataRequest = new XMLHttpRequest();
		metadataRequest.onreadystatechange = () =>
		{
			if (metadataRequest.readyState === XHR_STATUS.READY)
			{
				if (metadataRequest.status === HTTP_STATUS.OK)
				{
					const responseData = JSON.parse(metadataRequest.responseText);
					if (isObject(responseData))
					{
						player.adMetadata = responseData;
					}
					metadataRequest = null;
				}
				else if (metadataRequest.status === 0)
				{
					logger("Metadata request aborted");
				}
			}
		};
		metadataRequest.open("GET", buildUrl(broadcastID, vmapUrl), true);
		metadataRequest.send();
	}

	function getEnvironment(urlString)
	{
		try
		{
			const url = new URL(urlString);
			const hostname = url.hostname;

			const parts = hostname.split(".");

			// Assume the last two parts are the domain
			// It's expected that all prod vmaps will be hudl.com
			if (parts.length < 2)
			{
				return null;
			}

			// We don't have a way to determine our environment so, we'll make an assumption using the VMAP URL
			// eslint-disable-next-line no-magic-numbers
			const registeredDomain = parts.slice(-2).join(".");

			if (registeredDomain === "hudl.com")
			{
				return DOMAIN.PROD;
			}
			else
			{
				return DOMAIN.THOR;
			}
		}
		catch (error)
		{
			logger.error("Invalid URL:", error);
			return null;
		}
	}

	function buildUrl(id, vmapURL)
	{
		const domain = getEnvironment(vmapURL);
		if (!domain)
		{
			return null;
		}

		return `https://${domain}/api/broadcasts/broadcast/${id}/metadata`;
	}
}

// Register the plugin with video.js.
videojs.registerPlugin("adMetadataHandler", AdMetadataHandler);
