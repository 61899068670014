/**
 * XPath.js by Steven Barnett (@stevendesu)
 * Designed to standardize use of XPath functions between IE and
 * standards-compliant browsers.
 *
 * Contrary to the popular xpath and xpath-js libraries on NPMJS (which,
 * curiously enough, point to the same codebase but were uploaded by different
 * people), this does not attempt to implement XML parsing and searching by
 * hand. Instead, this falls back to native browser functionality - it just
 * wraps a Microsoft ActiveXObject to provide the same API as Chrome and
 * Firefox.
 *
 * I've also added a small check at the beginning to convert strings to XMLDOM
 * objects for ease of use.
 *
 * Usage:
 *     var xml = await fetch(xmlUrl).then(resp => resp.text());
 *     var nodeList = XPathWrapper( xml, '/Path/To/Node' );
 *     var next;
 *     while( next = nodeList.iterateNext() ) {
 *         // Do something
 *     }
 *
 * See: http://www.w3schools.com/XPath/
 */
const UNORDERED_NODE_ITERATOR_TYPE = 4;

function xPathWrapper(xmlInput, xpathInput)
{
	let xml = xmlInput;

	// XML namespaces are usually references to remote documents, stores on some
	// other server, that we only hope will remain valid as long as our player
	// remains valid. This isn't always the case. For instance, the sample VMAP
	// tags provided by Google for the IMA SDK report that the XML namespace
	// definition for the "vmap" namespace can be found here:
	//    http://www.iab.net/videosuite/vmap
	// In fact, this link is even provided by the Interactive Advertising
	// Bureau's official examples:
	//    https://github.com/InteractiveAdvertisingBureau/vmap/blob/master/xsd/vmap.xsd
	// Yet when you try to visit this link, it redirects you to their home page
	// as the namespace definition has moved. This can cause xPath to fail when
	// attempting to read <vmap:VMAP> tags (versus <VMAP> tags)
	//
	// An easy "quick fix" for this is to rip out all namespaces in both the
	// XML and the XPath string. This can, however, lead to a minor bug in the
	// case of similar tag names with different namespaces:
	//    <vmap:AdBreak></vmap:AdBreak><volar:AdBreak></volar:AdBreak>
	//
	// Fortunately we never encounter these
	if (typeof xml === "string")
	{
		// If typeof xml !== "string", we'll assume we already did this once
		xml = xml.replace(/<[^/!> ]+?:/g, "<");
		xml = xml.replace(/<\/[^!> ]+?:/g, "</");
	}
	const xpath = xpathInput.replace(/\/[^/]+?:/g, "/");

	// So in Internet Explorer 11, among the MANY things they did to lie that
	// they were "just like any other browsers" (while still not supporting
	// simple crap like XPath) they caused window.ActiveXObject and typeof
	// window.ActiveXObject to return "undefined", despite still being a
	// callable function. Why? Because so many websites were picking on poor
	// IE by giving them less feature-rich content. Of course since IE can't
	// SUPPORT the features they demand, it falls to developers to make up
	// crap like this:
	try
	{
		var IEsux = new window.ActiveXObject("Microsoft.XMLDOM");
		// If that didn't crash, this is Internet Explorer
		// MUST use typeof here... cannot say if (!xml.setProperty)
		// This is due to a really weird bug in IE 8 where some native
		// methods are called with zero parameters when mentioned
		if (xml._xmlDoc)
		{
			xml = xml._xmlDoc;
		}
		else if (typeof xml.setProperty === "undefined")
		{
			// Because IE 10... Jesus, IE...
			var xmlStr;
			if (typeof xml === "string")
				xmlStr = xml;
			else
				xmlStr = new XMLSerializer().serializeToString(xml);
			// Why re-initialize an ActiveXObject? We have one we made for testing
			xml = IEsux;
			xml.async = false;
			xml.loadXML(xmlStr);
		}
		xml.setProperty("SelectionLanguage", "XPath");
		var nodes = xml.selectNodes(xpath);
		var XPathResult = {
			_index: 0,
			// These two may be necessary to implement parentNode()
			// _xmlDoc: xml,
			// _p: xpath,
			iterateNext: function ()
			{
				if (Object.prototype.hasOwnProperty.call(this, this._index))
				{
					return this[this._index++];
				}
				else
				{
					return null;
				}
			}
		};

		var i;
		for (i = 0; i < nodes.length; i++)
		{
			// MOST DOM members/methods in IE are the same across all browsers
			// However a few (like .text instead of .textContent) necessitate
			// this extra object wrapper.
			//
			// Comments represent features I plan to add
			//
			// For a list of ALL members/methods (beyond what I support here):
			// http://www.w3schools.com/jsref/dom_obj_all.asp
			var xmlDoc = new window.ActiveXObject("Microsoft.XMLDOM");
			xmlDoc.async = false;
			xmlDoc.loadXML(nodes[i].xml);
			XPathResult[i] = {
				_node: nodes[i],
				_xmlDoc: xmlDoc,
				// appendChild
				attributes: {},
				// childNodes
				// firstChild
				getAttribute: function (attrName)
				{
					return this._node.getAttribute(attrName);
				},
				getElementsByTagName: function (tagName)
				{
					return this._node.getElementsByTagName(tagName);
				},
				hasAttribute: function (attrName)
				{
					return attrName in this.attributes;
				},
				hasAttributes: function ()
				{
					return this.attributes.length > 0;
				},
				hasChildNodes: function ()
				{
					// I *think* this is correct
					return this._node.hasChildNodes();
				},
				// insertBefore
				// isEqualNode
				// isSameNode
				// lastChild
				// nextSibling
				ownerDocument: xml,
				// parentNode
				// previousSibling
				// removeAttribute
				// removeChild
				// replaceChild
				selectNodes: function (secondarySearch)
				{
					return this._xmlDoc.selectNodes(secondarySearch);
				},
				// setAttribute
				setProperty: function (propName, value)
				{
					this._xmlDoc.setProperty(propName, value);
				},
				tagName: nodes[i].tagName,
				textContent: nodes[i].text
			};
			// In IE<8, attributes are handled... differently. They are merged
			// with properties, case-insensitive, and they are defined even if
			// they are not explicitly set. We need to rectify this.
			var attrs = XPathResult[i]._node.attributes;
			var j, len;
			for (j = 0, len = 0; j < attrs.length; ++j)
			{
				if (attrs[j].specified === true)
				{
					XPathResult[i].attributes[attrs[j].name] = XPathResult[i].attributes[len] = attrs[j];
					++len;
				}
			}
			// Gotcha: Any attribute named "length" will be overwritten
			XPathResult[i].attributes.length = len;
		}
		XPathResult.length = i;
		return XPathResult;
	}
	catch (e)
	{
		// Convenience function: Convert strings to XML documents
		if (typeof xml === "string")
			xml = new window.DOMParser().parseFromString(xml, "text/xml");

		// In Firefox the return value from xml.evalute does not, itself, have
		// an evaluate method. We need to convert to string and then back to XML
		if (!xml.evaluate)
		{
			xml = new window.DOMParser().parseFromString(new XMLSerializer().serializeToString(xml), "text/xml");
		}
		return xml.evaluate(xpath, xml, null, UNORDERED_NODE_ITERATOR_TYPE, null);
	}
}

xPathWrapper.ext = function(xmlInput, xpathInput)
{
	return xPathWrapper(xmlInput, "//Extensions/Extension[@type='VolarVMAPExtension']/" + xpathInput);
};

module.exports = xPathWrapper;
