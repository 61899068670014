import videojs from "video.js";

const atLiveClass = "vjs-bft-button-highlight";

const Component = videojs.getComponent("Component");
const Button = videojs.getComponent("Button");

// Default options for the plugin.
const defaults = {
	name: "ToLive"
};

/**
 * The base class for buttons
 *
 * @param {Player|Object} player
 * @param {Object=} options
 * @extends Button
 * @class ToLiveButton
 */
class ToLiveButton extends Button
{
	/**
	 * Creates an instance of this class.
	 *
	 * @param {Player} player
	 *		The `Player` that this class should be attached to.
	 *
	 * @param {Object} [options={}]
	 *		The key/value store of player options.
	 */
	constructor(player, options = {})
	{
		const mergedOptions = videojs.mergeOptions(defaults, options);
		super(player, mergedOptions);
		this.options = mergedOptions;
		this.player = player;

		this.hide();
	}

	createEl()
	{
		return super.createEl(
			"button",
			{
				className: "vjs-button vjs-control",
				innerHTML: `<span class="vjs-control-text ${this.buildCSSClass()}">${this.controlText_}</span>LIVE`
			}
		);
	 }

	/**
	 * Builds the default DOM `className`.
	 *
	 * @return {string}
	 *		The DOM `className` for this object.
	 */
	buildCSSClass()
	{
		return `vjs-to-live-button ${super.buildCSSClass()}`;
	}

	/**
	 * This gets called when an `ToLiveButton` is "clicked". See
	 * {@link ClickableComponent} for more detailed information on what a click can be.
	 *
	 * @param {EventTarget~Event} [event]
	 *		The `keydown`, `tap`, or `click` event that caused this function to be
	 *		called.
	 *
	 * @listens tap
	 * @listens click
	 */
	handleClick()
	{
		var duration = this.player.duration();
		// Can't use Infinity to set the current time
		if (isFinite(duration))
		{
			this.player.currentTime(duration);
		}
	}

	atLive(atLive)
	{
		if (atLive)
		{
			this.addClass(atLiveClass);
		}
		else
		{
			this.removeClass(atLiveClass);
		}
	 }
}

/**
 * The text that should display over the `ToLiveButton`s controls. Added for localization.
 *
 * @type {string}
 * @private
 */
ToLiveButton.prototype.controlText_ = "To Live";

Component.registerComponent("toLiveButton", ToLiveButton);

export default ToLiveButton;
