import videojs from "video.js";

function TogglePlayerWaiting()
{
	const player = this;
	// const opts = videojs.mergeOptions(defaults, options);

	// show loading cirle
	player.on("loadstart", () =>
	{
		player.addClass("vjs-waiting");
	});

	// hide loading circle
	player.on("contentblocked", () =>
	{
		player.removeClass("vjs-waiting");
	});
	player.on("sourceadded", () =>
	{
		player.removeClass("vjs-waiting");
	});
	player.on("playerinactive", () =>
	{
		player.removeClass("vjs-waiting");
	});
	// Source doesnt get added when the broadcast is upcoming.
	player.on("statechange", (evt, state) =>
	{
		if (state === "UPCOMING")
			player.removeClass("vjs-waiting");
	});

}

// Register the plugin with video.js.
videojs.registerPlugin("TogglePlayerWaiting", TogglePlayerWaiting);

export default TogglePlayerWaiting;
