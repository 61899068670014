export const isObject = (type) =>
{
	try
	{
		// distinguishes key-value objects from array
		if (type.constructor === Object)
		{
			return true;
		}
		else
		{
			return false;
		}
	}
	catch (error)
	{
		return false;
	}
};
