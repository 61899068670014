import videojs from "video.js";
import "./Plugin.scss";
import PopupWindowButton from "./components/PopupWindowButton";

const Plugin = videojs.getPlugin("plugin");

// Default options for the plugin.
const defaults = {
	force: false
};

/**
 * An advanced Video.js plugin. For more information on the API
 *
 * See: https://blog.videojs.com/feature-spotlight-advanced-plugins/
 */
class PopupWindow extends Plugin
{

	/**
   * Create a PopupWindow plugin instance.
   *
   * @param  {Player} player
   *         A Video.js Player instance.
   *
   * @param  {Object} [options]
   *         An optional options object.
   *
   *         While not a core part of the Video.js plugin architecture, a
   *         second argument of options is a convenient way to accept inputs
   *         from your plugin's caller.
   */
	constructor(player, options)
	{
		// The parent class will add player under this.player
		super(player);

		this.options = videojs.mergeOptions(defaults, options);

		this.popupWindowButton = new PopupWindowButton(player, options);

		player.ready(() =>
		{
			player.controlBar.addChild(this.popupWindowButton, options);

			if (this.options.force || !PopupWindow.canGoFullscreen())
			{
				player.controlBar.fullscreenToggle.hide();
				this.popupWindowButton.show();
			}
		});
	}

	static canGoFullscreen()
	{
		const doc = document;
		return !(
			doc.fullscreenEnabled === false ||
			doc.webkitFullscreenEnabled === false ||
			doc.mozFullScreenEnabled === false ||
			doc.msFullscreenEnabled === false
		);
	}
}

// Register the plugin with video.js.
videojs.registerPlugin("controlBarButtonPopupWindow", PopupWindow);

export default PopupWindow;
