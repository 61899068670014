import videojs from "video.js";
import "./Plugin.scss";

/**
 * A basic Video.js plugin
 */
function CenterPlayButton()
{
	this.addClass("vjs-center-play-button");
}

// Register the plugin with video.js.
videojs.registerPlugin("behaviorChangeCenterPlayButton", CenterPlayButton);

export default CenterPlayButton;
